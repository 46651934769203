import { ArrowBack, CameraAlt, Close, Done, Download, Image, PresentToAll } from '@mui/icons-material';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import IotServiceClient from "clients/IotServiceClient";
import MqttEventHandler from "components/Mqtt/MqttEventHandler";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import GenericChannel from 'helper-components/GenericChannel.js';
import PaperComponent from 'helper-components/PaperComponent';
import utils from 'helper-components/utils';
import { useSnackbar } from 'notistack';
import PubSubChannels from "pubsub/PubSubChannels";
import * as React from 'react';
import "stylesheet/common.css";


export default function TroubleShootDialog(props) {
    let getResponseImageCode =null;
    let imageRequestTimer = null;
    let uniqueKeyForCamera = null;
    let uniqueKeyForLogs = null;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const { enqueueSnackbar } = useSnackbar();
    const [showImageIcon, setShowImageIcon] = React.useState(false);
    const [showImage, setShowImage] = React.useState(false);
    const [imageSource, setImageSource] = React.useState("");
    const [circularProgress, setCircularProgress] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    const [showErrorText, setShowErrorText] = React.useState(false);
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [showDownloadLink, setShowDownloadLink] = React.useState(false);
    const [spinnerTimeoutCount, setSpinnerTimeoutCount] = React.useState(0);
    const [downloadLink, setDownloadLink] = React.useState(null);
    const debugLogTimeout = React.useRef(null);
    const timerIntervalRef = React.useRef(null);
    let {publish, updateStatusToFailed, deleteInstruction} = GenericChannel();

    const captureImage = (deviceData) => {
        if( deviceData.CS === 1 && !circularProgress) {
            setShowErrorText(false);
            setCircularProgress(true);
            startTimer(30*1000);
            uniqueKeyForCamera = utils.getUniqueKey();
            enqueueSnackbar("Sending capture request...");
            getResponseImageCode = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.CAPTURE_IMAGE_RESPONSE.replace("macId", deviceData.MAC) + "/" + uniqueKeyForCamera, "ImageCode", imageResponseCodeHandler);
            let message = publish(deviceData, "camera", null, uniqueKeyForCamera);
            let portalEventsPayload = {
                "MAC" : deviceData.MAC,
                "TI": new Date().getTime(),
                "SE" : [{
                    "nme": "Camera Capture Initiated",
                    "val": JSON.parse(sessionStorage.userDetails).user.email
                }]    
            }
            IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
        }
    }

    const imageResponseCodeHandler = function(message){
        window.cameraResponseCheck = false;
        clearTimeout(imageRequestTimer);
        let imageResponseCode = message.toString();
        if(imageResponseCode === "ERR_CAMERA_CAPTURE" || imageResponseCode === "GATEWAY_REBOOTED"){
            setTimeout (() => {
                enqueueSnackbar("Error occurred while capturing...");
                setCircularProgress(false);
                setErrorText(imageResponseCode == "ERR_CAMERA_CAPTURE" ? "Error occurred while capturing the image." : "Gateway went to reboot.");
                setShowErrorText(true);
            },1000)
        }
        else if(imageResponseCode !== ""){
            setImageSource(imageResponseCode);
            setTimeout (() => {
                enqueueSnackbar("Image capture success...")
                setShowImageIcon(true);
            },1000)
        }else{
            setTimeout (() => {
                enqueueSnackbar("Capture request failed...");
                setCircularProgress(false);
                setErrorText("Invalid Gateway response.");
                setShowErrorText(true);
            },1000)
        }
        if(getResponseImageCode) {
            getResponseImageCode.unsubscribe();
        }

    }

    const startTimer = (value) => {
        imageRequestTimer = setTimeout (() => {
              getResponseImageCode.unsubscribe();
              enqueueSnackbar("Request timed out...")
              setErrorText("Request timed out.");
              setCircularProgress(false);
              setShowErrorText(true);
              if(uniqueKeyForCamera !== null){
                  window.cameraResponseCheck = false;
                  updateStatusToFailed(props.troubleShootData, "Camera Capture failed", uniqueKeyForCamera);
              }
              if(getResponseImageCode) {
                  getResponseImageCode.unsubscribe();
              }
        }, value)
    }

    const viewImage = () => {
        setShowErrorText(false);
        setShowImage(true);
    }

    const backToTroubleShoot = () => {
        setShowImageIcon(false);
        setShowImage(false);
        setCircularProgress(false);
    }

    const closePullLogs = () => {
        unsubscribeChannel();
        setSpinnerTimeoutCount(0);
        startTimeoutProcess(null);
    }

    const unsubscribeChannel = () => {
        setShowSpinner(false);
        if (window.debugLogResponseChannel){
          window.debugLogResponseChannel.unsubscribe();
        }
        if(window.debugLogAwsUrlChannel){
          window.debugLogAwsUrlChannel.unsubscribe();
        }
      }

    const startTimeoutProcess = (initial) => {
        if (!initial){
          clearInterval(timerIntervalRef.current);
          clearTimeout(debugLogTimeout.current);
          return;
        }
        let setTimeoutTime = 1000 * 60;

        const timeout = setTimeout(()=>{
          unsubscribeChannel();
          clearInterval(timerIntervalRef.current);
          enqueueSnackbar("Requesting device logs timed out");
          setErrorText("Request timed out.");
          setShowErrorText(true);
          if(uniqueKeyForLogs !== null){
              window.pullLogsResponseCheck = false;
              updateStatusToFailed(props.troubleShootData, "Gateway Logs failed", uniqueKeyForLogs);
          }
        },setTimeoutTime);
        debugLogTimeout.current = timeout;
      }

    const timeoutCounter = () => {
        setSpinnerTimeoutCount(prevTimer => prevTimer + 1);
      }

    const pullDeviceLogs = (deviceData) => {
        if( deviceData.CS === 1 && !showSpinner) {
            uniqueKeyForLogs = utils.getUniqueKey();
            setShowErrorText(false);
            setShowSpinner(true);
            setShowDownloadLink(false);
            setSpinnerTimeoutCount(0);
            const timerInterval = setInterval(timeoutCounter, 1000);
            timerIntervalRef.current = timerInterval;
            startTimeoutProcess(true);
            enqueueSnackbar("Requesting device ...")
            let portalEventsPayload = {
                "MAC" : deviceData.MAC,
                "TI": new Date().getTime(),
                "SE" : [{
                    "nme": "Gateway Logs Requested",
                    "val": JSON.parse(sessionStorage.userDetails).user.email
                }]    
            }
            IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
            //window.debugLogResponseChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.PULL_DEVICE_LOGS_RESPONSE.replace("macId",deviceData.MAC) + "/" + uniqueKey, "info", handleDeviceLogResponse);
            window.debugLogAwsUrlChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GET_DEBUG_LOG_AWS_URL.replace("macId",deviceData.MAC) + "/" + uniqueKeyForLogs, "info", handleDebugLogUrl);
            publish(deviceData, "debuglogs", null, uniqueKeyForLogs)
        }
      }


    const handleDebugLogUrl = (message) => {
        window.pullLogsResponseCheck = false;
        let deviceResponse = message.toString().trim();
        startTimeoutProcess(null);
        if (deviceResponse  === 'ERR_ZIPPING_LOG_FILES' || deviceResponse  === 'ERR_SENDING_FILE' || deviceResponse === "GATEWAY_REBOOTED") {
            setErrorText(deviceResponse === "GATEWAY_REBOOTED" ? "Gateway went to reboot." : deviceResponse  == 'ERR_ZIPPING_LOG_FILES' ? "Zipping of log files failed" : "Error occurred while sending zip file.");
            setShowErrorText(true);
            setShowSpinner(false);
            return;
        }
        setShowSpinner(false);
        if (deviceResponse === ""){
            setErrorText("Failed to get logs url.");
            setShowErrorText(true);
            enqueueSnackbar("Failed to fetch logs URL");
        }else {
            setShowErrorText(false);
            setShowDownloadLink(true);
            enqueueSnackbar("Download link is ready, please click on icon");
            setDownloadLink(deviceResponse);
        }
      }


    React.useEffect(() => {
        window.pullLogsResponseCheck = true;
        window.cameraResponseCheck = true;
        setTimeout(() => {
          setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
          setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
        }, 1000);
        return () => {
            if(getResponseImageCode) {
                getResponseImageCode.unsubscribe();
            }
            if(imageRequestTimer) {
                clearTimeout(imageRequestTimer);
            }

            if(window.cameraResponseCheck && uniqueKeyForCamera !== null){
                deleteInstruction(uniqueKeyForCamera);
            }
            if(window.pullLogsResponseCheck && uniqueKeyForLogs !== null){
                deleteInstruction(uniqueKeyForLogs);
            }
        }
    },[])

    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.openDialog}
                onClose={() => props.closeDialog()}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    background: primaryThemeColor,
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'white',
                }}>
                    <Box sx={{flex: '95'}}>Trouble Shoot</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{padding:"5px !important"}}>
                    {
                        showErrorText ?
                            <Box sx={{display: "flex", justifyContent: "center", width:"100%", margin:"10px 0px"}}>
                                <Box sx={{color: "red"}}>{errorText}&nbsp;</Box>
                                <Box> Try again...</Box>
                            </Box>
                        :
                            null
                    }
                    {
                        showImage ?
                            <Box sx={{width:"100%"}}>
                                {
                                    getWindowDimensions().width < 600 ?
                                        <Box sx={{height:"40px"}}>
                                            <Tooltip title="Back to Troubleshoot" placement="top" arrow>
                                                <ArrowBack onClick={() => backToTroubleShoot(props.troubleShootData)}
                                                           sx={{color: "#4190c7", cursor: "pointer",padding:"10px 0px 10px -5px"}}/>
                                            </Tooltip>
                                        </Box>
                                    :
                                        null
                                }
                                <img src={"data:image/jpeg;base64,"+ imageSource} alt="" style={{height:"430px", width:"100%"}} />
                            </Box>
                        :
                            null
                    }
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:"center", position:"relative", height:"40px"}}>
                    <>
                        {
                            !showImage ?
                                <Box>
                                    {
                                        props.troubleShootData.CMS === 1 ?
                                            !showImageIcon ?
                                                <Box sx={{position:"relative"}}>
                                                    <Tooltip title="Capture Image" placement="top" arrow>
                                                        <CameraAlt onClick={() => captureImage(props.troubleShootData)}
                                                                sx={{fontSize: 33, cursor: "pointer", height: "26px"}}/>
                                                    </Tooltip>
                                                    <CircularProgress thickness="6.4" size="12px" sx={{ position: "absolute", visibility: circularProgress ? "visible" : "hidden" }}> </CircularProgress>
                                                </Box>
                                            :
                                                <Box>
                                                    <Tooltip title="View Image" placement="top" arrow>
                                                        <Image onClick={() => viewImage()}
                                                            sx={{fontSize: 33, cursor: "pointer"}}/>
                                                    </Tooltip>
                                                    <Done color="success" sx={{position:"absolute", visibility: circularProgress ? "visible" : "hidden", fontSize:"16px", marginLeft:"-3px" }}> </Done>
                                                </Box>
                                        :
                                            <Box>
                                                <Tooltip title="No Camera Module Installed" placement="top" arrow>
                                                    <NoPhotographyIcon sx={{fontSize: 33, cursor: "pointer", height:"26px"}}/>
                                                </Tooltip>
                                            </Box>

                                    }
                                </Box>
                            :
                                <Box sx={{width:"100%"}}>
                                    {
                                        getWindowDimensions().width >= 600 ?
                                            <Tooltip title="Back to Troubleshoot" placement="top" arrow>
                                                <ArrowBack onClick={() => backToTroubleShoot()}
                                                           sx={{color: "#4190c7", cursor: "pointer"}}/>
                                            </Tooltip>
                                        :
                                            null
                                    }
                                </Box>
                        }
                        {
                          (JSON.parse(sessionStorage.userDetails).user.email.indexOf("wesko-elocks.com") !== -1 || JSON.parse(sessionStorage.userDetails).user.email.indexOf("cariboutech.com")) ?
                            <Box sx={{marginLeft:'16px !important',display:"flex",flexDirection:"row"}}>
                              {
                               !showDownloadLink ?
                               <Box>
                                   <Tooltip placement='top' title="Request for Device logs" arrow>
                                     <PresentToAll sx={{cursor: 'pointer'}} onClick={()=> pullDeviceLogs(props.troubleShootData)}/>
                                   </Tooltip>
                               </Box>
                               :
                                null
                              }
                              {
                                showSpinner && !showDownloadLink ?
                                  <div style={{position: 'relative',marginLeft:"17px"}}>
                                    <CircularProgress thickness="4" sx={{height:'26px !important',width: '26px !important'}}/>
                                    <span style={{position: 'absolute',top: "8px",marginLeft:'-29px',width: "100%",fontSize:' 7px',textAlign: "center"}}>{spinnerTimeoutCount}s</span>
                                    <Close  sx={{ color:"red", position: 'relative',top: '-19px',fontSize: '14px',width: '9px',zIndex: '10',left: '-8px',height: '11px',background: '#fff',cursor:"pointer"}} onClick={()=>closePullLogs()} />
                                  </div>
                                :
                                  showDownloadLink ?
                                    <Tooltip placement='top' title="Download Device Logs" arrow>
                                      <Box sx={{marginLeft:"5px"}}>
                                          <a href={downloadLink} download>
                                            <Download sx={{cursor: 'pointer', color: '#000000de'}} />
                                          </a>
                                      </Box>
                                    </Tooltip>
                                  :
                                    null
                              }
                            </Box>
                          :
                            null
                        }
                    </>
                </DialogActions>
            </Dialog>
        </>
    )
}
