import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import PaperComponent from 'helper-components/PaperComponent';
import { useSnackbar } from 'notistack';
import * as React from 'react';

export default function ConfigDataUpdateAlert(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const { enqueueSnackbar } = useSnackbar();
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")

   React.useEffect(() => {
       setTimeout(() => {
         setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
         setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
       }, 1000);

   },[])

    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.openDialog}
                onClose={() => props.closeDialog()}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    background: primaryThemeColor,
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'white',
                }}>
                    <Box sx={{flex: '95'}}> {props.updateCase === "update" ? "Update" : "Reset"} Config Data Alert</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{padding:"5px !important", display:"flex", justifyContent:"center", marginTop:"15px"}}>
                    <Box>
                        Are you sure you want to {props.updateCase === "update" ? "Update" : "Reset"} Agent config data.
                    </Box>
                </DialogContent>
                <DialogActions >
                    <>
                        <Button color="error" onClick={() => props.closeDialog()}>Cancel</Button>
                        <Button sx={{color:primaryThemeColor}} onClick={() => props.updateCase === "update" ? props.update() : props.resetToDefault() }>Ok</Button>
                    </>
                </DialogActions>
            </Dialog>
        </>
    )
}