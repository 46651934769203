import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router';
import AuthClient from 'clients/AuthClient';

const AutoLogout = () => {
  const location = useLocation();
  const handleOnIdle = () => {    
    if ((window.location.hash !== '#/logout' || window.location.hash !== '/' || window.location.hash !== '' || window.location.hash !== '#/login') && sessionStorage.length > 0 && sessionStorage.hasOwnProperty("userDetails")) {
        logoutUser();
    }
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const logoutUser = () => {
    let payload = {
      "scopeId": JSON.parse(sessionStorage.userDetails).user.scopeId,
      "email": JSON.parse(sessionStorage.userDetails).user.email,
      "logout": {"time" : [new Date().getTime()]}
    };
    AuthClient.saveLoginOrLogoutActivity(payload,sessionStorage.activityKey,"logout").then((response) => {});
    sessionStorage.clear();
    window.location.hash = "/logout";
    location.hash = "#/logout";    
  };

  return (
    <></>
  );
};

export default AutoLogout;
