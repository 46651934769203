import { ArrowBack } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import AuthClient from 'clients/AuthClient.js';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import _ from "lodash";
import { ColorPicker } from 'material-ui-color';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import 'stylesheet/UpdateFirmware.css';


export default function ThemeConfig(){
  const [themeColor, setThemeColor] = React.useState(["#4190c7"]);
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(["#B0BF5D"]);
  const [selectedPrimaryThemeColor, setSelectedPrimaryThemeColor] = React.useState(null);
  const [selectedSecondaryThemeColor, setSelectedSecondaryThemeColor] = React.useState(null);
  const [customer, setCustomer] = React.useState('');
  const [allCustomers, setAllCustomers] = React.useState([]);
  const [isValidToSubmit, setIsValidToSubmit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isChecked, setIsChecked] = React.useState(false);
  const [isCheckedSecondaryColor, setIsCheckedSecondaryColor] = React.useState(false);
  const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")

  React.useEffect(() => {
    fetchData();
  }, [])

  const location = useLocation();

  const fetchData = () => {
    let customersData = [];
    AuthClient.getHirarchy().then((data) => {
      customersData.push({"name": data["scope"].name , "scopeId" : data["scope"].id})
      data["children"].forEach((ele) => {
        customersData.push({"name": ele["scope"].name , "scopeId" : ele["scope"].id})
      });
      setAllCustomers(customersData);
      window.selectedCustomer=sessionStorage.tenantId;
      setCustomer(sessionStorage.tenantId);
    })
  }

  const backToCustomersPage = () => {
     if(sessionStorage.isRootUser === "true"){
       window.location.hash= "/admin/tenants";
       location.hash = "#/admin/tenants";
     }else {
       window.location.hash= "/cli/dashboard";
       location.hash = "#/cli/dashboard";
     }
  }

  const checkIsValidToSubmitOrNot = () => {
     if(checkDataExistsOrNot(window.selectedCustomer) && checkDataExistsOrNot(window.selectedPrimaryThemeColor) && checkDataExistsOrNot(window.selectedSecondaryThemeColor)){
       setIsValidToSubmit(true);
     }
  }

  const handleManualEditOfColor = (updatedColor, oldColor) => {
    if (updatedColor === oldColor || !checkDataExistsOrNot(updatedColor)){
        if (updatedColor === oldColor){
            setSelectedPrimaryThemeColor(updatedColor.hex);
            window.selectedPrimaryThemeColor = updatedColor.hex
        }else {
            setSelectedPrimaryThemeColor(null);
            window.selectedPrimaryThemeColor = null
        }
    }else {
       let updatedColors =  [];
       _.forEach(themeColor , (value) => {
            if (value === oldColor) {
                value = "#"+updatedColor.hex;
                setSelectedPrimaryThemeColor(updatedColor.hex);
                window.selectedPrimaryThemeColor = updatedColor.hex;
            }
            updatedColors.push(value);
       })
       setThemeColor(updatedColors);
    }
  }

  const handleSecondaryManualEditOfColor = (updatedColor, oldColor) => {
    if (updatedColor === oldColor || !checkDataExistsOrNot(updatedColor)){
        if (updatedColor === oldColor){
            setSelectedSecondaryThemeColor(updatedColor.hex);
            window.selectedSecondaryThemeColor = updatedColor.hex
        }else {
            setSelectedSecondaryThemeColor(null);
            window.selectedSecondaryThemeColor = null
        }
    }else {
       let updatedColors =  [];
       _.forEach(secondaryThemeColor , (value) => {
            if (value === oldColor) {
                value = "#"+updatedColor.hex;
                setSelectedSecondaryThemeColor(updatedColor.hex);
                window.selectedSecondaryThemeColor = updatedColor.hex;
            }
            updatedColors.push(value);
       })
       setSecondaryThemeColor(updatedColors);
    }
  }

  const handleClick = () => {
    let payload = {
       "id" : customer
    }

    if (isChecked){
        payload["primaryThemeColor"] = selectedPrimaryThemeColor;
    }

    if (isCheckedSecondaryColor){
        payload["secondaryThemeColor"] = selectedSecondaryThemeColor;
    }

    AuthClient.updateTenant(payload).then((response) => {
        if (response.hasOwnProperty("id")){
          enqueueSnackbar("Successfully Updated customer");
          sessionStorage.tenantPrimaryThemeColor = response.primaryThemeColor.indexOf("#") !== -1 ? response.primaryThemeColor : "#"+response.primaryThemeColor;
          sessionStorage.tenantSecondaryThemeColor = response.secondaryThemeColor.indexOf("#") !== -1 ? response.secondaryThemeColor : "#"+response.secondaryThemeColor;
        }else {
          enqueueSnackbar("Failed to  update customer");
        }
    })
    setSelectedPrimaryThemeColor();
    setSelectedSecondaryThemeColor();
    setThemeColor(["#4190c7"]);
    setSecondaryThemeColor(["#B0BF5D"]);
    setIsValidToSubmit(false);
  }

  return(
    <div>
      <div style={{height: '56px'}}>
        <ArrowBack
          sx={{ color:sessionStorage.isRootUser === "true" ? "#4190c7" : primaryThemeColor }}
          onClick={() => {
            if(sessionStorage.isRootUser === "true"){
              window.location.hash= "/admin/tenants";
              location.hash= "#/admin/tenants";
            }else {
              delete sessionStorage.sidenavAlreadySelectedItem;
              window.location.hash= "/cli/dashboard";
              location.hash= "#/cli/dashboard";
            }
          }}
          className="backArrow"
        />
      </div>
      <div className="container" style={{maxHeight: '95%'}}>
        <div className="subContainer"
          style={{
            minHeight: '100%',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <div
            style={{
              flex: '20',
              padding: '0 12px',
              borderBottom: '1px solid rgba(0,0,0,0.12)',
            }}
          >
            <h5 style={{fontSize:'16px'}}>Theme Configuration</h5>
          </div>
          <div>
            <div className='radioGroup'>
              <div className='colorBlock'>
                <div style={{display:'flex',flex:'30%'}}>
                  <FormControl
                    variant="standard"
                     sx={{minWidth:'200px',margin: '15px', marginTop: '19px'}}
                  >
                    <InputLabel id="selectCustomer" sx={{margin: '0px'}}>
                      Select customer
                    </InputLabel>
                      <Select
                         id="selectCustomer"
                         MenuProps={{PaperProps: {style: {maxHeight: '150px'}}}}
                         value={customer}
                         onChange={(event) => {
                           setCustomer(event.target.value);
                           window.selectedCustomer = event.target.value;
                           checkIsValidToSubmitOrNot();
                         }}
                         style={{
                           marginTop:'16px',
                           textAlign:'left'
                         }}
                      >
                        {
                          JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_SERVICE_DATABASE_RESET") ?
                            Object.keys(allCustomers).length > 0 ?
                               (Object.values(allCustomers).sort((first, next) =>
                                 first.name.localeCompare(next.name)
                               )).map((element, index) => {
                                   return <MenuItem value={element.scopeId}>{element.name}</MenuItem>;
                               })
                            :
                              null
                          :
                            <MenuItem value={sessionStorage.tenantId}>{sessionStorage.tenantName}</MenuItem>
                        }
                      </Select>
                  </FormControl>
                </div>
                <div className='colorSelectBlock nameTextField'>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel id="demo-radio-buttons-group-labels">Select Primary Theme Color</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-labels"
                      defaultValue={themeColor[0]}
                      name="radio-buttons-group"
                      sx={{marginTop:"12px"}}
                      value={selectedPrimaryThemeColor}
                      onChange={(event) => {
                         setSelectedPrimaryThemeColor(event.target.value);
                         window.selectedPrimaryThemeColor = event.target.value;
                         checkIsValidToSubmitOrNot();
                      }}
                    >
                       {
                          themeColor.map((element,index) => {
                              return (
                                  <FormControlLabel value={element} control={<Radio checked={isChecked} onClick={(event) => {setIsChecked(!isChecked)}} />} label={<ColorPicker value={element} disableTextfield={true} onChange={(value) => handleManualEditOfColor(value, element)}/>} />
                              )
                          })
                       }
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className='colorSelectBlock nameTextField'>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel id="demo-radio-buttons-group-labels">Select Secondary Theme Color</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      sx={{marginTop:"12px"}}
                      value={selectedSecondaryThemeColor}
                      onChange={(event) => {
                         setSelectedSecondaryThemeColor(event.target.value);
                         window.selectedSecondaryThemeColor = event.target.value;
                         checkIsValidToSubmitOrNot();
                      }}
                    >
                      {
                         secondaryThemeColor.map((element,index) => {
                           return(
                             <FormControlLabel value={element} control={<Radio checked={isCheckedSecondaryColor} onClick={(event) => {setIsCheckedSecondaryColor(!isCheckedSecondaryColor)}} />} label={<ColorPicker value={element} disableTextfield={true} onChange={(value) => handleSecondaryManualEditOfColor(value, element)} style={{width:'200px'}}/>} />
                           )
                         })
                      }
                    </RadioGroup>
                  </FormControl>
                </div>
                <div style={{display: 'flex',flex: '10%',justifyContent:'center'}}>
                    <Button variant="contained" sx={{margin:'20px 18px 18px -10px',fontWeight:'bold'}} disabled={!isValidToSubmit || !isChecked || !isCheckedSecondaryColor} onClick={() => handleClick()}>Apply</Button>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomDiv">
            <Button style={{color: '#4190c7', margin: '2px',fontWeight:'bold'}} onClick={() => backToCustomersPage()}>
              BACK TO CUSTOMERS
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}