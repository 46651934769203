import Cookies from 'js-cookie';


function getMqttAuthToken(){
    return Cookies.get('mqtt-auth-token');
}

function getAuthToken() {
    return Cookies.get('auth-token');
}

function getXsrfToken () {
    return Cookies.get('XSRF-TOKEN');
}

function getMqttUserName(){
    const mqttPassword = "1234";
    return mqttPassword;
}


const UserDetailService = { getMqttAuthToken, getMqttUserName, getAuthToken, getXsrfToken};
export default UserDetailService;