import React from 'react';
import { Box,Dialog,Button,DialogActions,DialogContent,DialogTitle,DialogContentText,Paper,useMediaQuery,useTheme, CircularProgress, Snackbar, TextField } from '@mui/material';
import { Close,Add, Apple,Android,CheckCircle,Cancel } from '@mui/icons-material';
import OtpInput from 'react18-input-otp';
import Draggable from 'react-draggable';
import AuthClient from 'clients/AuthClient';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import 'stylesheet/UpdateFirmware.css';
import PaperComponent from 'helper-components/PaperComponent';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';


export default function TwoFactorAuthentication(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
   const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
   const [submitted,setSubmitted] = React.useState(false);
   const [verificationCode,setVerificationCode] = React.useState("");
   const [qrCodeUrl, setQrCodeUrl] = React.useState("");
   const [otp, setOtp] = React.useState("");
   const [disableOtpField, setDisableOtpField] = React.useState(false);
   const [showErrorMessage, setShowErrorMessage] = React.useState(false);
   const [isLoading, setIsLoading] = React.useState(false);
   const [networkCallResStatus, setNetworkCallResStatus] = React.useState(false);
   const [showSpinner, setShowSpinner] = React.useState(false);
   const { enqueueSnackbar } = useSnackbar();
   const inputStyle = {
             width: '40px',
             height: '45px',
             margin: '20px 5px',
             fontSize: '20px',
             borderRadius: '5px',
             border: disableOtpField ? '2px solid #ccc' : '2px solid #808080',
   };

   const verifyCode = () => {
      if (!submitted) {
         setSubmitted(true);
         setShowSpinner(true);
         setDisableOtpField(true);
         AuthClient.enableTwoFactorAuthentication(props.userDetails ? props.userDetails.email : JSON.parse(sessionStorage.userDetails).user.email,otp).then((response) => {
           if(response.status >= 200 && response.status< 300){
              setShowSpinner(false);
              setShowErrorMessage(false);
              document.querySelector(".Error_Message").style.visibility = 'visible';
              enqueueSnackbar("Successfully Enabled 2 Factor-Authentication");
              setNetworkCallResStatus(true);
              setTimeout(()=> {
                props.closeDialog();
              },5000)
           } else {
              setDisableOtpField(false);
              setShowSpinner(false);
              setShowErrorMessage(true);
              document.querySelector(".Error_Message").style.visibility = 'visible';
              setTimeout(() => {
                document.querySelector(".Error_Message").style.visibility = 'hidden';
              },3000)
              setSubmitted(false);
              enqueueSnackbar("Failed to Enable 2 Factor-Authentication");
              setNetworkCallResStatus(false);
           }
         });
      }
   }

   const getQrCodeUrl = () => {
       setIsLoading(true);
       AuthClient.getQrCodeUrl(JSON.parse(sessionStorage.userDetails).user.email).then((res) => {
           if (checkDataExistsOrNot(res.qrCodeUrl)) {
              setQrCodeUrl(res.qrCodeUrl);
              setIsLoading(false);
           }
       });
   }

   React.useEffect(() => {
     getQrCodeUrl();
     return() => {
     }
   },[])


return (
   <>
     <Dialog
        fullWidth={true}
        fullScreen={fullScreen}
        open={props.openDialog}
        onClose={() => {props.closeDialog();props.setToggleToPreviousState(networkCallResStatus)}}
        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
        aria-labelledby="draggable-dialog-title"
     >
       <DialogTitle style={{color: 'white', display:"flex", flexDirection:"row", background:"#4190c7"}}>
           <Box sx={{flex:"95"}}>Setup Two-Factor Authentication</Box>
           <Box sx={{flex: '5', marginTop: '6px'}}>
               <Close onClick={()=> {props.closeDialog();props.setToggleToPreviousState(networkCallResStatus)}} sx={{cursor:"pointer"}} />
           </Box>
       </DialogTitle>
       <DialogContent style={{padding:"20px"}}>
         {
              <DialogContentText style={{color: 'black'}}>
                 <ul>
                   <li style={{marginBottom: '10px'}}>Get the Authenticator App from <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en-IN" target="_blank">
                      <Android sx={{position: 'relative',top: '8px',padding: '2px',marginRight: '3px'}} />Play Store</a> or <a
                          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                            <Apple size="small" sx={{position: 'relative',top: '4px'}} />App Store</a>.
                   </li>
                   <li>In the App tap <Add size="small" sx={{position: 'relative',top: '6px'}} /> and Scan the below QR code.</li>
                 </ul>
                 <Box className="qrCodeField">
                     <Box sx={{flex:"40", alignItems: 'center', justifyContent: 'center',display: 'flex'}}>
                        { isLoading ?
                           <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: '#4190c7 !important',position:'relative',top: '-40px'}} />
                        :
                           <img src={qrCodeUrl} className="qr-img" style={{position: 'relative',top: '-18px',left: '7px'}} />
                        }
                     </Box>
                     <Box sx={{flex: '60', alignItems: 'center', justifyContent: 'center',display: 'flex',flexDirection: 'column'}}>
                         <Box sx={{marginLeft: '17px'}}>Enter the 6-digit code you see on your authenticator app.</Box>
                         <Box sx={{marginLeft: '8px'}}>
                             <OtpInput
                               className="OTP_field"
                               isDisabled={disableOtpField}
                               value={otp}
                               onChange={(val) => setOtp(val)}
                               numInputs={6}
                               inputStyle={inputStyle}
                               onKeyDown={(evt) => {}}
                             />
                             { showSpinner ?
                                 <CircularProgress thickness="4" sx={{position: 'relative', bottom: '58px',left: '140px',height:'32px !important',width: '32px !important',color: '#4190c7 !important'}} />
                             :
                                  <CircularProgress thickness="4" sx={{position: 'relative', bottom: '58px',left: '140px',height:'32px !important',width: '32px !important',color: '#4190c7 !important',visibility: 'hidden'}} />
                             }
                         </Box>
                         <Box className="Error_Message" sx={{marginTop: '10px',visibility: 'hidden',marginLeft: '-1px',position: 'relative',top: '-28px'}}>
                            { !showErrorMessage ?
                                <> <CheckCircle sx={{marginRight: '2px',color: '#109618',position: 'relative',top: '7px'}} /> Code has been Verified Successfully. </>
                            :
                               <> <Cancel sx={{marginRight: '2px',color: 'red',position: 'relative',top: '7px'}} /> You have entered an Incorrect code. </>
                            }
                         </Box>
                     </Box>
                 </Box>
              </DialogContentText>
         }
       </DialogContent>
       <DialogActions>
           <>
               <Button style={{color: 'red'}} onClick={() => {props.closeDialog();props.setToggleToPreviousState(networkCallResStatus)}}>
                 Cancel
               </Button>
               <Button disabled={otp.length !== 6} onClick={() => verifyCode()}>
                 Verify
               </Button>
           </>
       </DialogActions>
     </Dialog>
   </>
);
}