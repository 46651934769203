import { Close } from '@mui/icons-material';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import AuthClient from "clients/AuthClient";
import IotServiceClient from "clients/IotServiceClient";
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import checkDataExistsOrNot from './checkDataExistsOrNot';
import PaperComponent from './PaperComponent';


const DownloadCSVDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff");

    React.useEffect(() => {
      if(props.dialog === "downloadCustomersCSVDialog"){
        fetchAllCustomersDataForCsv();
      }
      if(props.dialog === "downloadDevicesCSVDialog"){
        fetchDevicesDataForCsv();
      }
      setTimeout(() => {
        setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
        setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
      }, 1000);
    }, []);

    const fetchAllCustomersDataForCsv = () => {
      let customerData = [];
      let allCsvData = [];
      let propertiesForCsv = ["name", "customerId", "gatewayFirmware", "gatewayOS", "partialOS"];
      AuthClient.getHirarchy("downloadCsv").then((data) => {
        _.forEach(data.children, function(value){
          customerData.push(value['scope']);
        });
        _.forEach(customerData, function(val) {
          let dataArray = {};
          _.forEach(propertiesForCsv, function(property) {
             if(val[property]){
               dataArray[property] = val[property];
             }else {
               dataArray[property] = "-";
             }
          });
          allCsvData.push(dataArray);
        });
        props.closeCsvDialog(allCsvData);
      });
    }

    const fetchDevicesDataForCsv = () => {
      let deviceData = [];
      let reachableDevice = [];
      let propertiesForCsv = [
         "name", "macId", "manufacturedDate", "connectionType", "rcuCount", "enabledRcus", "firmware", "iccid", "imei", "imsi" ,"OSPV" , "OSversion",
         "power_status", "hotspotStatus" ,"hotspotPassword", "binVersion", "createdAt", "updatedAt", "connStatus", "lastOnlineTime", "lastOfflineTime",
         "productId", "dataResetTime", "nwType", "connectionType", "signalStrength", "serialCheck", "dataUsed", "powerFailureStatus", "manufacturedDate",
         "serialNumber", "registerMe", "address", "latitude", "longitude", "city", "state", "country", "zipCode", "addressId"
      ];
      IotServiceClient.getReachableIotDevices(sessionStorage.tenantId,"downloadCsv").then((data) => {
        if (!data.hasOwnProperty("message")){
          _.forEach(data, function(val) {
            val.manufacturedDate = moment(val.manufacturedDate).format("DD MMM YYYY");
            deviceData.push(val);
          });
          _.forEach(deviceData, function(value) {
            if(value.location){
              value.address = value.location.address;
              value.latitude = value.location.latitude;
              value.longitude = value.location.longitude;
              value.city = value.location.city;
              value.state = value.location.state;
              value.country = value.location.country;
              value.zipCode = value.location.zipCode;
            }
            let deviceArray={};
            _.forEach(propertiesForCsv, function(property) {
              if(value[property]){
                deviceArray[property] = value[property];
              }else {
                deviceArray[property] = "-";
              }
            });
            reachableDevice.push(deviceArray);
          });
        }
        props.closeCsvDialog(reachableDevice);
      });
    }

    return(
      <>
        {props.dialog === "downloadCustomersCSVDialog" || props.dialog === "downloadDevicesCSVDialog" ? (
          <Dialog
              fullWidth={true}
              fullScreen={fullScreen}
              open={props.showDialog}
              onClose={() => props.closeProcess()}
              PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
              aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{
              background: primaryThemeColor,
              display: 'flex',
              flexDirection: 'row',
              color: 'white',
            }}>
              <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                Preparing the CSV ...
              </Box>
              <Close sx={{marginLeft:"auto",marginRight:"10px",cursor:"pointer"}} onClick={()=>props.closeProcess()} />
            </DialogTitle>
            <DialogContent>
              <Box sx={{ display:"flex",width: '100%',justifyContent: 'center',alignItems: 'center',height: '200px'}}>
                <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
              </Box>
            </DialogContent>
          </Dialog>
        ) : null}
      </>
    )
}

export default DownloadCSVDialog;