import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import AuthClient from 'clients/AuthClient';
import PaperComponent from 'helper-components/PaperComponent';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import UserDetailService from 'services/UserDetailService';


const ApplyFirmwareDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = React.useState(false);
    const [showSuccessText, setShowSuccessText] = React.useState(false);
    const [isFailed, setIsFailed] = React.useState(false);
    const [customers, setCustomers] = React.useState("");
    const [firmwares, setFirmwares]= React.useState("");
    const [tenants,setTenants] = React.useState([]);
    const [selectedTenant, setSelectedTenant] = React.useState([]);
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [performedUpload, setPerformedUpload] = React.useState(false);
    const [performedApply, setPerformedApply] = React.useState(false);
    const [performedDelete, setPerformedDelete] = React.useState(false);

    const handleCloseApplyDialog =() => {
        props.closeDialog();
    }

    React.useEffect(() => {
      let customers = "";
      let firmwares = "";
      if (props.dialog === 'applyFirmwareDialog') {
          if (props.selectedTenants) {
             _.forEach(props.selectedTenants, (tenant) => {
                  customers = customers === "" ? tenant : customers + ", " + tenant;
             });
             customers = props.selectedTenants.length > 1 ? customers + " Customers" : customers + " Customer";
             setCustomers(customers);
          }
      }
      if (props.dialog === 'deleteFirmwareDialog') {
           if (props.firmwareSelected) {
               _.forEach(props.firmwareSelected, (firmware) => {
                    firmwares = firmwares === "" ? firmware : firmwares + ", " + firmware;
               });
               setFirmwares(firmwares);
           }
      }
       setTenants(props.allTenants);
       setSelectedTenant(props.selectedTenants);
    },[]);

    const applyFirmware = () => {
      validateAppliedVersionsOnTenants(...props.firmwareSelected,"gatewayVersion","gatewayVersionId",props.allVersions);
    }

     const validateAppliedVersionsOnTenants = (selectedVersion, appliedName, appliedVersionId, allVersionsData) => {
        if (!performedApply) {
            let selectedTenantHasNewVersion = false;
            let selectedTenantsId = [];
            let selectedFileStoreId = "";
            _.forEach(allVersionsData,(version)=>{
                if (version.version === selectedVersion){
                  selectedFileStoreId = version.id;
                }
            })
            _.forEach(tenants,(tenant) => {
               _.forEach(selectedTenant,(selectedTenant) => {
                 if (tenant.name === selectedTenant){
                     selectedTenantsId.push(tenant.id);
                     _.forEach(tenant.versions, (version) => {
                       if (selectedVersion <= version.version){
                          selectedTenantHasNewVersion = true;
                       }
                     });
                 }
               })
            })
            if (selectedTenantHasNewVersion){
              setPerformedApply(true);
              props.closeDialog();
              enqueueSnackbar(appliedName === "osVersion" ? "You cannot downgrade or apply the same OS version as the latest." : "You cannot downgrade or apply the same firmware version as the latest");
            }else {
              setPerformedApply(true);
              let firmwareAppliedCount = 0;
              let networkCallsResponseReceived = 0;
              let firmwareFailedCount = 0;
              let selectedTenants = Object.assign([],selectedTenant);
              let selectedTenantIndex = 0;
              _.forEach(selectedTenantsId,(id)=>{
                 setTimeout(() => {
                     let json = {
                       "scopes": [{
                         [id] : []
                       }],
                       "fileStoreId": selectedFileStoreId,
                       "type": "Firmware",
                     }
                     AuthClient.applyFirmware(json,selectedFileStoreId,id).then((response) => {
                       props.closeDialog();
                       networkCallsResponseReceived+=1;
                       if (response.status === 200){
                         firmwareAppliedCount+=1;
                       }else {
                         firmwareFailedCount +=1;
                       }
                     }).catch((error)=>{
                       networkCallsResponseReceived+=1
                       firmwareFailedCount+=1;
                     })
                 }, 1000 + selectedTenantIndex);
                 selectedTenantIndex += 500;
                 let toastMessageInterval = setInterval(() => {
                 if (networkCallsResponseReceived === selectedTenants.length && selectedTenants.length > 1){
                   if (firmwareFailedCount === 0 && firmwareAppliedCount > 0){
                     let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                     enqueueSnackbar("Firmware applied for "+firmwareAppliedCount + tenantorTenants);
                     clearInterval(toastMessageInterval);
                   }else {
                     let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                     let tenantsOrTenants = firmwareFailedCount > 0 ? " tenants" : " tenant" ;
                     enqueueSnackbar("Firmware applied for "+firmwareAppliedCount + tenantorTenants + " and failed to apply for "+ firmwareFailedCount + tenantsOrTenants);
                     clearInterval(toastMessageInterval);
                   }
                   window.uploadOsResponseSuccess = true;
                 }else if (networkCallsResponseReceived === selectedTenants.length && selectedTenants.length  === 1){
                   if (firmwareFailedCount === 0 && firmwareAppliedCount > 0){
                     let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                     enqueueSnackbar("Successfully applied firmware for "+ selectedTenants[0]);
                     clearInterval(toastMessageInterval);
                   }else {
                     let tenantorTenants = firmwareAppliedCount > 0 ? " tenants" : " tenant" ;
                     let tenantsOrTenants = firmwareFailedCount > 0 ? " tenants" : " tenant" ;
                     enqueueSnackbar("Failed to apply firmware for "+ selectedTenants[0]);
                     clearInterval(toastMessageInterval);
                   }
                   window.uploadOsResponseSuccess = true;
                 }

               }, 1000);
              })
            }
        }
     }

    const uploadFirmware = () => {
      if (!performedUpload) {
        let type = "Firmware";
        let name = "Generic";
        let version = props.fileName;
        let alreadyHasVersion = false;

        _.forEach(tenants,(tenant) => {
            _.forEach(selectedTenant, (tenantName) => {
               if (tenant.name === tenantName) {
                 _.forEach(tenant.versions, (versionn) => {
                   if (version <= versionn.version) {
                     alreadyHasVersion = true;
                   }
                 });
               }
           });
        });

        if (alreadyHasVersion) {
           props.closeDialog();
           enqueueSnackbar("You cannot downgrade or apply the same firmware version");
           setPerformedUpload(true);
        } else {
            setPerformedUpload(true);
            let scopeIds = [];
             _.forEach(props.selectedTenants,(tenantName) => {
                 for (let index =0; index < tenants.length ; index++){
                    if (tenants[index].name === tenantName){
                      let tenantScopeId = tenants[index].id;
                      scopeIds.push( {[tenantScopeId] : []} );
                    }
                 }
             })
              scopeIds = _.uniq(scopeIds);

              let info = {
                "name": name,
                "type": type,
                "version": version,
                "scopes": scopeIds,
                "description": props.description
              };
             const formData = new FormData();
             formData.append('upload', props.file);
             formData.append('info', JSON.stringify(info));
             let url = window.location.origin +"/v1/auth-service/file-storage";
             AuthClient.uploadFirmware(url, formData, UserDetailService.getAuthToken).then((res) => {
                 props.closeDialog();
                 if (!res.hasOwnProperty("status")){
                      props.uploadedStatus(true);
                      if (scopeIds.length > 0) {
                          let responseScopes = res.scopes ? res.scopes : [];
                          let successfullyUpdatedScopes = 0;
                          _.forEach(scopeIds,(id)=>{
                            let scopeFound = responseScopes.indexOf(id);
                            if (scopeFound > -1){
                              successfullyUpdatedScopes +=1;
                            }
                          })

                          if (scopeIds.length === 1){
                            enqueueSnackbar("Firmware updated successfully for " + selectedTenant[0]);
                          }else if (scopeIds > 1) {
                            enqueueSnackbar("Firmware updated successfully for " + successfullyUpdatedScopes + " tenants");
                          }
                      } else {
                         enqueueSnackbar("Firmware Uploaded Successfully");
                      }
                 } else {
                      props.uploadedStatus(false);
                      scopeIds = scopeIds ? scopeIds : [];
                      if (scopeIds.length > 0) {
                          if (scopeIds.length === 1) {
                            enqueueSnackbar("Failed to apply firmware for " + selectedTenant[0]);
                          }else if (scopeIds.length > 1) {
                           enqueueSnackbar("Failed to apply firmware for " + scopeIds.length + " tenants");
                          }
                      } else {
                          enqueueSnackbar("Failed to Upload Firmware");
                      }
                 }
             });
        }
      }
    }

    const deleteFirmware = () => {
      if (!performedDelete) {
        setPerformedDelete(true);
        let json = {};
        json.version = props.firmwareSelected;

        AuthClient.deleteFirmwareVersions(json).then((response) => {
           props.closeDialog();
           if (response.status >= 200 && response.status < 300){
               props.deletedStatus(true);
               if (props.firmwareSelected.length === 1) {
                   enqueueSnackbar("Successfully Deleted Firmware");
               } else {
                   enqueueSnackbar("Successfully Deleted Firmwares");
               }
           } else {
               props.deletedStatus(false);
               if (props.firmwareSelected.length === 1) {
                    enqueueSnackbar("Failed to Delete Firmware");
               } else {
                   enqueueSnackbar("Failed to Delete Firmwares");
               }
           }
        });
      }
    }

    return (
      <>
         {
            showSnackBar ?
              <Snackbar
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                open={showSnackBar}
                message={snackBarMessage}
              />
            :
             null
         }
        <div>
          {props.dialog === 'applyFirmwareDialog' || 'deleteFirmwareDialog' || 'uploadFirmwareDialog' ? (
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.dialog === 'applyFirmwareDialog' ? props.show_apply_firmware_dialog : props.dialog === 'uploadFirmwareDialog' ? props.show_upload_firmware_dialog : props.show_delete_firmware_dialog }
                onClose={() => handleCloseApplyDialog()}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
               <DialogTitle style={{color: 'white', display:"flex", flexDirection:"row", background:"#4190c7"}}>
                   <Box sx={{flex:"95"}}>{props.dialog === 'applyFirmwareDialog' ? 'Apply Firmware' : props.dialog === 'uploadFirmwareDialog' ? 'Upload Firmware' : 'Delete Firmware' }</Box>
                   <Box sx={{flex: '5', marginTop: '6px'}}>
                       <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
                   </Box>
               </DialogTitle>
               <DialogContent style={{padding:"20px"}}>
                  {
                        isLoading ?
                           <Box sx={{height:"40px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                               <CircularProgress thickness="3.8" size="30px" > </CircularProgress>
                           </Box>
                        :
                            props.dialog === 'applyFirmwareDialog' ?
                                 <DialogContentText style={{color: 'black'}}>
                                      Are you sure, you want to Apply <b>{props.firmwareSelected}</b> to {customers} ?
                                 </DialogContentText>
                            :
                              props.dialog === 'uploadFirmwareDialog' ?
                                    <DialogContentText style={{color: 'black'}}>
                                        Are you sure, want to Upload <b>{props.fileName}</b> ?
                                    </DialogContentText>
                              :
                                   <DialogContentText style={{color: 'black'}}>
                                       You will not be able to undo this operation. Do you still want to delete <b> {firmwares} </b> { props.firmwareSelected.length > 1 ? " Firmwares" : " Firmware"}
                                   </DialogContentText>
                  }
               </DialogContent>
               <DialogActions>
                  {
                    !isLoading ?
                       <>
                           <Button style={{color: 'red'}} onClick={() => props.closeDialog()}>
                             Cancel
                           </Button>
                           { props.dialog === 'applyFirmwareDialog' ?
                                <Button onClick={() => {applyFirmware()}}>Yes</Button>
                             :
                               props.dialog === 'uploadFirmwareDialog' ?
                                   <Button onClick={() => {uploadFirmware()}}>Yes</Button>
                               :
                                   <Button onClick={() => {deleteFirmware()}}>Yes</Button>
                           }
                       </>
                    :
                           null
                  }
               </DialogActions>
            </Dialog>
          ) : null }
        </div>
      </>
    );
};

export default ApplyFirmwareDialog;
