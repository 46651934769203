/* eslint-disable no-undef */
import ProgressbarStatus from 'helper-components/ProgressBarStatus';
import NProgress from 'nprogress';

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({})
};

let allAbortControlllers = {};

function addAbortController (page) {
    let abortController = new AbortController();
    if (!allAbortControlllers.hasOwnProperty(page)){
        allAbortControlllers[page] = [abortController];
    }else {
        allAbortControlllers[page].push(abortController);
    }
    return abortController;
}

function getIotDevices(scopeId) {
    return fetch('/iotdevices?scopeId=' + scopeId)
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}


function getReachableIotDevices(scopeId) {
    return fetch('/api/iot-service/reachable?scopeId=' + scopeId)
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function getAlerts(scopeId, page) {
    let abortController = addAbortController(page);
    NProgress.start();
    return fetch('/api/alerts/all?scopeId=' + scopeId, {signal: abortController.signal})
    .then((res) => {
        ProgressbarStatus();
        return res.json()})
    .then((json) => {
       console.info(json);
       return json;
    })
    .catch((err) => {
       console.error(err);
       ProgressbarStatus();
       return err;
    });
}

function deleteAlert(alertId) {
    NProgress.start();
    return fetch('/api/alert/delete?alertId=' + alertId,{
        method:"DELETE"
    })
    .then((json) => {
        console.info(json);
        ProgressbarStatus();
        return json;
    })
    .catch((err) => {
        console.error(err);
        ProgressbarStatus();
        return err;
    });
}

function deleteRfidAlert(scopeId) {
    return fetch('/api/rfid-delete-alert/delete?scopeId=' + scopeId,{
        method:"DELETE"
    })
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function updateAlert(alert) {
    NProgress.start();
    return fetch('/api/alert/update?alertId=' + alert.id,{
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(alert)
    }).then((res)=>{
        ProgressbarStatus();
        return res;
    }).catch((err)=>{
        ProgressbarStatus();
        return err;
    })
}

function updateRfidAlert(alert) {    
    return fetch("/api/rfid-alert/update",{
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(alert)
    }).then((res)=>{
        return res;
    }).catch((err)=>{
        return err;
    })
}

function createAlert(user_details) {
    NProgress.start();
    return fetch('/api/alert/createAlert', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then((res)=>{
        ProgressbarStatus();
        return res;
    }).catch((err)=>{
        ProgressbarStatus();
        return err;
    })
}


function abortSignal(page) {
    let abortSignals = allAbortControlllers.hasOwnProperty(page) ? allAbortControlllers[page] : [];
    _.forEach(abortSignals, (abortController)=> {
        abortController.abort()
    })
}

const JobSchedulerClient = { getIotDevices, updateAlert, updateRfidAlert, deleteRfidAlert,getReachableIotDevices, getAlerts ,deleteAlert,createAlert, abortSignal};
export default JobSchedulerClient;
