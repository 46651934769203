import React from 'react';
import { useBeforeUnload } from 'react-use';
import AuthClient from 'clients/AuthClient';

const DetectBrowserOrTabClose = () => {
    useBeforeUnload(() => {
        let payload = {
          "scopeId": JSON.parse(sessionStorage.userDetails).user.scopeId,
          "email": JSON.parse(sessionStorage.userDetails).user.email,
          "logout" : {"ti" : [new Date().getTime()]}
        };
        AuthClient.saveLoginOrLogoutActivity(payload,sessionStorage.activityKey,"logout").then((response) => {});
    });

    return (
        <></>
    );
};

export default DetectBrowserOrTabClose;
