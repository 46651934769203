import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import AuthClient from 'clients/AuthClient';
import IotServiceClient from 'clients/IotServiceClient';
import PaperComponent from 'helper-components/PaperComponent.js';
import { useSnackbar } from 'notistack';
import React from 'react';


const DeleteCustomer = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = React.useState(false);
    const [showSuccessText, setShowSuccessText] = React.useState(false);
    const [isFailed, setIsFailed] = React.useState(false);

    const handleCloseDeleteDialog =() => {
        props.closeDialog(false);
    }

    const deleteTenant = () => {
       setIsLoading(true);
       AuthClient.deleteTenantUsersByScope(props.deleteData.id).then((response)=> {
          if(response.status >= 200 && response.status< 300){
              callBackFunction(true, "users delete");
              IotServiceClient.deleteStatesAndMoveGatewaysToRoot(props.deleteData.id, props.deleteData.ancestors[0]).then((response) => {
                  if(response.status >= 200 && response.status < 300){
                      callBackFunction(true, "gateways migrate to root");
                      AuthClient.deleteTenantScope(props.deleteData.id).then((response)=> {
                          if (response.status >= 200 && response.status < 300){
                              callBackFunction(true, "customer delete");
                          }else {
                              callBackFunction(false, "")
                          }
                      })
                  }else{
                       callBackFunction(false, "gateways");
                  }
              })
          }else{
            callBackFunction(false, "users");
          }
      })
    }

    const updateCustomerStatusToActive = () => {
        AuthClient.updateCustomerStatus(props.deleteData.id).then((response) => {
             if(response.status >= 200 && response.status< 300) {
                enqueueSnackbar("Reverted customer status to Active");
             }else{
                enqueueSnackbar("Failed to Change customer status to Active");
             }
        })
    }

    const callBackFunction = (completedOrNot, toastMessage) => {
        if(completedOrNot){
            if(toastMessage === "customer delete"){
                setTimeout (() => {
                    setIsFailed(false);
                    setShowSuccessText(true);
                }, 1000)
            }
            enqueueSnackbar("Successfully created " + toastMessage + " instruction for "+ props.deleteData.name);
        }else{
            updateCustomerStatusToActive();
            setTimeout (() => {
                  setIsFailed(true);
                  setShowSuccessText(true);
                  enqueueSnackbar("Failed to delete " + props.deleteData.name + " customer " + toastMessage);
            },1000)
        }
    }

    return (
        <div>
          {props.dialog === 'deleteCustomerDialog' ? (
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.show_delete_dialog}
                onClose={handleCloseDeleteDialog}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
               <DialogTitle style={{color: 'white', display:"flex", flexDirection:"row", background:"#4190c7"}}>
                   <Box sx={{flex:"95"}}>Delete Customer</Box>
                   <Box sx={{flex: '5', marginTop: '6px'}}>
                       <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
                   </Box>
               </DialogTitle>
               <DialogContent style={{padding:"20px"}}>
                  {
                    !showSuccessText ?
                        isLoading ?
                           <Box sx={{height:"40px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                               <CircularProgress thickness="3.8" size="30px" > </CircularProgress>
                           </Box>
                        :
                          <DialogContentText style={{color: 'black'}}>
                             You will not be able to undo this operation. Do you still want to delete <b>{props.deleteData.name}</b> customer?
                          </DialogContentText>
                    :
                        isFailed ?
                            <Box sx={{display: "flex", justifyContent: "center", width:"100%", margin:"10px 0px"}}>
                                <Box> Failed to delete <b>{props.deleteData.name}</b> customer. Try again... </Box>
                            </Box>
                        :
                            <Box sx={{display: "flex", justifyContent: "center", width:"100%", margin:"10px 0px"}}>
                                <Box> Customer <b>{props.deleteData.name}</b> deleted successfully.</Box>
                            </Box>
                  }
               </DialogContent>
               <DialogActions>
                  {
                    !isLoading ?
                       <>
                           <Button style={{color: 'red'}} onClick={() => {handleCloseDeleteDialog()}}>
                             Cancel
                           </Button>
                           <Button onClick={() => deleteTenant()}>Yes</Button>
                       </>
                    :
                           null
                  }
               </DialogActions>
            </Dialog>
          ) : null }
        </div>
    );
};

export default DeleteCustomer;
