import React from 'react';
import { Autocomplete,Box,Dialog,Button,TextField,DialogActions,DialogContent,DialogTitle,DialogContentText,Checkbox,useMediaQuery,useTheme,Popover,Select,MenuItem,FormControl,FormControlLabel,InputLabel } from '@mui/material';
import { Close,CheckCircle,ArrowDropDown } from '@mui/icons-material';
import _ from 'lodash';
import JobSchedulerClient from "clients/JobSchedulerClient";
import { useSnackbar } from 'notistack';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import PaperComponent from 'helper-components/PaperComponent.js';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import utils from 'helper-components/utils';


const ConfigureAlert = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const getDraggableWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
  }
  const { enqueueSnackbar } = useSnackbar();
  const [Dimensions, setDimensions] = React.useState(getDraggableWindowDimensions());
  const [portalUserData, setPortalUserData] = React.useState(props.portalUserData);
  const [alertType, setAlertType] = React.useState(props.data.alertType ? props.data.alertType : '');
  const [waitingTime, setWaitingTime] = React.useState();
  const [waitingTimeTextValue, setWaitingTimeTextValue] = React.useState();
  const [customWaitingTime, setCustomWaitingTime] = React.useState(0);
  const [alertTimeInterval, setAlertTimeInterval] = React.useState();
  const [alertText, setAlertText] = React.useState();
  const [customTimeInterval, setCustomTimeInterval] = React.useState(1);
  const [selectedUsers, setSelectedUsers] = React.useState(props.selectedUsers);
  const [selectedTimeInterval, setSelectedTimeInterval] = React.useState(false);
  const [thresholdValue, setThresholdValue] = React.useState(props.title === "Update Alert" || props.title === "Delete Alert" ? props.data.threshold : 0);
  const [alertThreshold, setAlertThreshold] = React.useState();
  const [showThreshold, setShowThreshold] = React.useState(false);
  const [sendEmailAlert, setSendEmailAlert] = React.useState(false);
  const [sendSmsAlert, setSendSmsAlert] = React.useState(false);
  const [showCpuAlertExistsMessage, setShowCpuAlertExistsMessage] = React.useState(false);
  const [showMemoryAlertExistsMessage, setShowMemoryAlertExistsMessage] = React.useState(false);
  const [showCameraAlertExistsMessage, setShowCameraAlertExistsMessage] = React.useState(false);
  const [thresholdType, setThresholdType] = React.useState(props.title === "Update Alert" || props.title === "Delete Alert" ? props.data.thresholdType : "");
  const [performedCreation, setPerformedCreation] = React.useState(false);
  const [performedUpdate, setPerformedUpdate] = React.useState(false);
  const [performedDeletion, setPerformedDeletion] = React.useState(false);

  React.useEffect(() => {
    if(props.title === "Update Alert") {
      if(props.data.alertType === "HIGH_CPU" || props.data.alertType === "HIGH_MEMORY_USAGE"){
        setShowThreshold(true);
        setThresholdType(props.data.thresholdType);
        setThresholdValue(props.data.threshold);
      }
      setWaitingTime(props.data.waitingTime);
      setWaitingTimeTextValue(props.data.waitingTimeTextValue);
      setSendEmailAlert(props.data.emailAlert);
      setSendSmsAlert(props.data.smsAlert);
    }
    if (props.title === "Create New Alert" && props.generalUser) {
      setWaitingTimeTextValue("10 seconds");
      setSendEmailAlert(true);
      setWaitingTime(10);
    }
  }, []);

  const handleEditUnlockAlert = () => {
    props.close_edit_alert_dialog(false);
  }

  const createAlertText = (value) => {
    let dayOrDays = value > 1 ? " days" : " day";
    let text = value + dayOrDays;
    let alertValue = 86400000 * value;
    setAlertText(text);
    setAlertTimeInterval(alertValue);
    setCustomTimeInterval(value);
  }

  const createWaitingTimeText = (value) => {
    let text = value + " seconds";
    setWaitingTimeTextValue(text);
    setWaitingTime(value);
    setCustomWaitingTime(value);
  }

  const handleCreateOrUpdateAlert = () => {
    if (props.title === "Create New Alert") {
      if (!performedCreation) {
        setPerformedCreation(true);
        let allReceivers = [];
        _.forEach(selectedUsers,(user) => {
           let recipient = {};
           recipient.email = user.email;
           recipient.phoneNumber = checkDataExistsOrNot(user.phoneNumber) ? user.phoneNumber : null;
           allReceivers.push(recipient);
        });

        let payload = {
          alertType : alertType,
          waitingTime : waitingTime,
          thresholdType : showThreshold ? thresholdType : null,
          threshold : showThreshold ? thresholdValue : null,
          receivers : allReceivers,
          emailAlert : sendEmailAlert,
          smsAlert : sendSmsAlert,
          scopeId: sessionStorage.tenantId
        }
        let json = createPublishData();
        JobSchedulerClient.createAlert(payload).then((response) => {
          if (response.status === 201 || response.status === 204) {
            enqueueSnackbar("Alert successfully created");
            MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
          } else {
            enqueueSnackbar("Failed to create alert")
          }
          handleEditUnlockAlert();
        });
      }
    } else {
      if (!performedUpdate) {
        setPerformedUpdate(true);
        let allReceivers = [];
        _.forEach(selectedUsers,(user) => {
           let recipient = {};
           recipient.email = user.email;
           recipient.phoneNumber = checkDataExistsOrNot(user.phoneNumber) ? user.phoneNumber : null;
           allReceivers.push(recipient);
        });

        let payload = {
          id : props.data.id,
          alertType : alertType,
          waitingTime : waitingTime,
          thresholdType : showThreshold ? thresholdType : null,
          threshold : showThreshold ? thresholdValue : null,
          receivers : allReceivers,
          emailAlert : sendEmailAlert,
          smsAlert : sendSmsAlert,
          scopeId: sessionStorage.tenantId
        }
        let json = createPublishData();
        JobSchedulerClient.updateAlert(payload).then((res) => {
          if(res.status === 200 || res.status === 204 || res.status === 415){
            enqueueSnackbar("Alert successfully updated");
            MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
          }else {
            enqueueSnackbar("Failed to update alert");
          }
          handleEditUnlockAlert();
        });
      }
    }
  }

  const createPublishData = () => {
    let alertText = alertType === "HIGH_CPU" ? "cpu-alert" : alertType === "HIGH_MEMORY_USAGE" ? "memory-alert" : "camera-alert";
    let json = {
      type : props.title !== 'Delete Alert' ? alertText : alertText + "-deletion",
      uid : utils.getUniqueKey(),
    }
    if ( props.title !== 'Delete Alert' ? alertType === "HIGH_CPU" || alertType === "HIGH_MEMORY_USAGE" : false) {
      json["value"] = thresholdValue;
      json["filter"] = thresholdType === "greater_than" ? "high" : "low";
    }
    return json;
  }

  const handleDeleteAlert = () => {
    if (!performedDeletion) {
      setPerformedDeletion(true);
      let json = createPublishData();
      JobSchedulerClient.deleteAlert(props.data.id).then((data) => {
        if(data.status === 204 || data.status === 200){
          enqueueSnackbar("Alert successfully deleted");
          MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
        }else {
          enqueueSnackbar("Error: Please try again");
        }
        handleEditUnlockAlert();
      });
    }
  }

  const checkIsValidToSubmit = () => {
    if(checkDataExistsOrNot(alertType) && checkDataExistsOrNot(waitingTime) && checkDataExistsOrNot(selectedUsers)){
      if((sendEmailAlert || sendSmsAlert) && (showThreshold ? checkDataExistsOrNot(thresholdType) && checkDataExistsOrNot(thresholdValue) ? thresholdValue > 0 && thresholdValue <= 100 : false : true)){
        return false;
      } else{
        return true;
      }
    } else{
      return true;
    }
  }

  const checkSpace = (value, flag) => {
    if(value === " " || value.indexOf(" ") === 0){
        if(value.length <= 1)
            return "";
        return value ? value.trim() : "";
    }
    return value;
  }

  return (
    <div>
       <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={props.show_edit_alert_dialog}
          onClose={handleEditUnlockAlert}
          PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
          aria-labelledby="draggable-dialog-title"
       >
         <DialogTitle
             style={{
                color: props.title !== 'Delete Alert' ? 'white' : 'black',
                fontSize: '20px',
                backgroundColor: props.title === 'Delete Alert' ? '#fff' : props.themeColor,
                display: 'flex',
                flexDirection: 'row',
             }}
         >
           <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
              {props.title}
           </Box>
           <span style={{marginTop: '6px'}}>
              <Close onClick={() => handleEditUnlockAlert()} />
           </span>
         </DialogTitle>
         { props.title !== "Delete Alert" ? (
             <DialogContent
                sx={{
                   padding: '0px',
                   height: '100%',
                   overflow: Dimensions.width > 600 ? 'hidden' : 'auto',
                   overflowX: 'hidden',
                }}
             >
                <Box style={{margin: '20px', marginLeft: '0px', cursor: 'pointer'}}>
                   <Box style={{ padding: '5px 10px 10px 10px' }}>
                      <div style={{ fontSize:'18px', fontWeight: '500', padding: '5px 10px'}}>Alert</div>
                      <FormControl
                          variant="standard"
                          sx={{width: '100%', margin: '10px', marginTop: '0px'}}
                      >
                         <InputLabel sx={{margin: '0px'}}>
                            Select Alert
                         </InputLabel>
                         <Select
                            value={alertType}
                            onChange={(event) => {
                              let value=event.target.value;
                              if(value === "HIGH_CPU") {
                                let allData = props.allData ? props.allData : [];
                                let cpuAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      cpuAlertAlreadyExists = true;
                                    }
                                })
                                if (!cpuAlertAlreadyExists) {
                                  setShowCpuAlertExistsMessage(false);
                                } else {
                                  setShowCpuAlertExistsMessage(true);
                                }
                              } else if (value === "HIGH_MEMORY_USAGE") {
                                let allData = props.allData ? props.allData : [];
                                let memoryAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      memoryAlertAlreadyExists = true;
                                    }
                                })
                                if (!memoryAlertAlreadyExists) {
                                  setShowMemoryAlertExistsMessage(false);
                                } else {
                                  setShowMemoryAlertExistsMessage(true);
                                }
                              } else if(value === "CAMERA_MODULE_ALERT") {
                                let allData = props.allData ? props.allData : [];
                                let cameraAlertAlreadyExists = false;
                                _.forEach((allData), (user) => {
                                    if(user.alertType === value) {
                                      cameraAlertAlreadyExists = true;
                                    }
                                })
                                if (!cameraAlertAlreadyExists) {
                                  setShowCameraAlertExistsMessage(false);
                                } else {
                                  setShowCameraAlertExistsMessage(true);
                                }
                              }
                              setAlertType(value);
                              if(value === "HIGH_CPU" || value === "HIGH_MEMORY_USAGE") {
                                setShowThreshold(true);
                                if (props.generalUser) {
                                   setThresholdType("greater_than");
                                   setThresholdValue(60);
                                }
                              } else {
                                setShowThreshold(false);
                                setThresholdType("");
                                setThresholdValue("");
                              }
                            }}
                            style={{
                              width:'100%',
                              marginTop:'16px',
                              textAlign:'left'
                            }}
                         >
                           {props.data.alertType === "HIGH_CPU" || props.title === "Create New Alert" ? (
                             <MenuItem value="HIGH_CPU">CPU Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "HIGH_MEMORY_USAGE" || props.title === "Create New Alert" ? (
                             <MenuItem value="HIGH_MEMORY_USAGE">Memory Usage Alert</MenuItem>
                           ) : null}
                           {props.data.alertType === "CAMERA_MODULE_ALERT" || props.title === "Create New Alert" ? (
                             <MenuItem value="CAMERA_MODULE_ALERT">Camera Module Alert</MenuItem>
                           ) : null}
                         </Select>
                         { showCpuAlertExistsMessage && alertType === "HIGH_CPU" ? (
                           <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                             <DialogContentText>
                               <br/>
                               <br/>
                                 Cannot create duplicate CPU alert. Please edit the existing one.
                               </DialogContentText>
                           </DialogContent>
                         ) :
                           null
                         }
                         { showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE" ? (
                           <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                             <DialogContentText>
                               <br/>
                               <br/>
                                 Cannot create duplicate Memory Usage alert. Please edit the existing one.
                               </DialogContentText>
                           </DialogContent>
                         ) :
                           null
                         }
                         { showCameraAlertExistsMessage && alertType === "CAMERA_MODULE_ALERT" ? (
                           <DialogContent sx={{textAlign:"center",padding:"0px"}}>
                             <DialogContentText>
                               <br/>
                               <br/>
                                 Cannot create duplicate Camera Module alert. Please edit the existing one.
                               </DialogContentText>
                           </DialogContent>
                         ) :
                           null
                         }
                      </FormControl>
                   </Box>
                   {
                     (showCpuAlertExistsMessage && alertType === "HIGH_CPU")|| (showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE") || (showCameraAlertExistsMessage && alertType === "CAMERA_MODULE_ALERT")? (
                       null
                     ) : (
                       <Box sx={{display:'flex',padding: '10px',flexDirection: 'column'}}>
                         <div style={{ fontSize:'18px', fontWeight: '500', padding: '5px 10px'}}>Threshold</div>
                         <Box sx={{display:"flex", marginTop:"7px"}}>
                           <FormControlLabel
                             sx={{margin:'0px 2px'}}
                             control={
                               <Checkbox
                                 disabled={props.generalUser ? true : !showThreshold}
                                 checked={showThreshold}
                               />
                             }
                           />
                           <span style={{marginTop:"9px",marginRight:"12px"}}>Threshold is</span>
                           <Box>
                             <FormControl
                               variant="standard"
                               sx={{minWidth:'120px',marginTop:'4px'}}
                             >
                               <Select disabled={props.generalUser ? true : !showThreshold} value={thresholdType} onChange={(event) => setThresholdType(event.target.value)}>
                                 <MenuItem value="greater_than">greater than</MenuItem>
                                 <MenuItem value="less_than">less than</MenuItem>
                               </Select>
                             </FormControl>
                           </Box>
                           <Box sx={{marginLeft:'10px',marginTop: '4px'}}>
                             <TextField
                               disabled={props.generalUser ? true : !showThreshold}
                               type="number"
                               variant="standard"
                               value={thresholdValue}
                               InputProps={{
                                 inputProps: {
                                   min: 0, max: 100,
                                 }
                               }}
                               onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                               onChange={(event) => {
                                 let value = event.target.value;
                                 value = checkSpace(value);
                                 setThresholdValue(value);
                               }}
                             />
                           </Box>
                         </Box>
                       </Box>
                     )
                   }
                   <Box sx={{display:'flex',padding: '10px',flexDirection: 'column'}}>
                     {
                       (showCpuAlertExistsMessage && alertType === "HIGH_CPU")|| (showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE")|| (showCameraAlertExistsMessage && alertType === "CAMERA_MODULE_ALERT") ? (
                         null
                       ) : (
                           <>
                              <div style={{ fontSize:'18px', fontWeight: '500', padding: '5px 10px' }}>Waiting Time</div>
                              <PopupState variant="popover" popupId="demo-popup">
                                {(popupState) => (
                                  <Box sx={{display:'flex', width:'100%'}}>
                                    <Box onClick={(e) => {!props.generalUser && popupState.open(e)}} sx={{display:"flex", flexDirection:"row", borderBottom:"1px solid #0000006e", width:"100%", marginLeft:"12px",opacity: props.generalUser ? 0.5 : null}}>
                                      <Box sx={{display:"flex", flex:"20%"}}>{waitingTimeTextValue}</Box>
                                      <Box sx={{display:'flex', marginLeft:'28px',justifyContent:'flex-end'}}>
                                        <ArrowDropDown
                                          sx={{float: 'left', cursor:"pointer", color:"#0000008e"}}
                                        />
                                      </Box>
                                    </Box>
                                    <Popover
                                      {...bindPopover(popupState)}
                                      anchorReference={popupState.anchorEl}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      sx={{top:"4px !important"}}
                                    >
                                      <Box sx={{display:'flex',flexDirection:'column'}}>
                                        <Box sx={{width:'550px'}}>
                                          <FormControl variant="standard" sx={{width:"100%"}}>
                                            <MenuItem value={10} onClick={() => {
                                              setWaitingTimeTextValue("10 seconds");
                                              popupState.close();
                                              setWaitingTime(10);
                                            }}>10 seconds</MenuItem>
                                            <MenuItem value={15} onClick={() => {
                                              setWaitingTimeTextValue("15 seconds");
                                              popupState.close();
                                              setWaitingTime(15);
                                            }}>15 seconds</MenuItem>
                                            <MenuItem value={20} onClick={() => {
                                              setWaitingTimeTextValue("20 seconds");
                                              popupState.close();
                                              setWaitingTime(20);
                                            }}>20 seconds</MenuItem>
                                            <MenuItem > <input type="number" value={customWaitingTime} min={0} max={2678400} defaultValue={0} style={{width:"60px"}}
                                               onChange={(event)=> {
                                                 setCustomWaitingTime(event.target.value);
                                               }}
                                              />seconds
                                              <CheckCircle sx={{marginLeft:"6px",color:"#1976d2"}}
                                                onClick={() => {
                                                   if(customWaitingTime>=0) {
                                                     createWaitingTimeText(customWaitingTime);
                                                     popupState.close();
                                                   }
                                                }}
                                              />
                                            </MenuItem>
                                          </FormControl>
                                        </Box>
                                      </Box>
                                    </Popover>
                                  </Box>
                                )}
                              </PopupState>
                           </>
                       )
                     }
                   </Box>
                   {
                     (showCpuAlertExistsMessage && alertType === "HIGH_CPU")|| (showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE")|| (showCameraAlertExistsMessage && alertType === "CAMERA_MODULE_ALERT") ? (
                       null
                     ) : (
                       <Box style={{padding:'18px 22px'}}>
                         <FormControlLabel
                             control={
                               <Checkbox
                                 disabled={props.generalUser}
                                 checked={sendEmailAlert}
                                 onChange={(event) => {
                                   if(event.target.checked){
                                     setSendEmailAlert(true);
                                   }else{
                                     setSendEmailAlert(false);
                                   }
                                 }}
                               />
                             }
                             label="Email"
                         />
                         <FormControlLabel
                             control={
                               <Checkbox
                                 checked={sendSmsAlert}
                                 onChange={(event) => {
                                   if(event.target.checked){
                                     setSendSmsAlert(true);
                                   }else{
                                     setSendSmsAlert(false);
                                   }
                                 }}
                               />
                             }
                             label="SMS"
                         />
                       </Box>
                     )
                   }
                   {
                     (showCpuAlertExistsMessage && alertType === "HIGH_CPU")|| (showMemoryAlertExistsMessage && alertType === "HIGH_MEMORY_USAGE")|| (showCameraAlertExistsMessage && alertType === "CAMERA_MODULE_ALERT") ? (
                        null
                     ) : (
                        <Box style={{ padding: '10px'}}>
                           <div style={{ fontSize:'18px', fontWeight: '500', padding: '5px 10px' }}>Select Users</div>
                           <Autocomplete
                              disabled={props.generalUser}
                              multiple
                              id="tags-standard"
                              options={portalUserData}
                              getOptionLabel={(option) => option.name}
                              defaultValue={selectedUsers}
                              style={{ width: 'auto', marginLeft: '10px' }}
                              onChange={(event,value) => {
                                setSelectedUsers(value);
                              }}
                              ListboxProps={{style:{ maxHeight: '150px' }}}
                              renderInput={(params) => (
                                 <TextField {...params} label="Type user name" variant="standard" />
                              )}
                           />
                        </Box>
                     )
                   }
                </Box>
             </DialogContent>
         ) : (
            <DialogContent>
              Are you sure you want to delete this alert?
            </DialogContent>
         )}
         <DialogActions>
            <div style={{ margin: 'auto 20px'}}>
              <Button
                 onClick={() => handleEditUnlockAlert()}
                 style={{color: 'red'}}
              >
                 Cancel
              </Button>
              <Button
                sx={{color:props.themeColor}}
                disabled={props.title !== "Delete Alert" ? checkIsValidToSubmit() : false }
                onClick={() => {props.title !== "Delete Alert" ? handleCreateOrUpdateAlert() : handleDeleteAlert()}}
              >
                {props.button_name}
              </Button>
            </div>
         </DialogActions>
       </Dialog>
    </div>
  );
};

export default ConfigureAlert;
