/* eslint-disable no-undef */

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({})
};

let allAbortControlllers = {};

function addAbortController (page) {
    let abortController = new AbortController();
    if (!allAbortControlllers.hasOwnProperty(page)){
        allAbortControlllers[page] = [abortController];
    }else {
        allAbortControlllers[page].push(abortController);
    }
    return abortController;
}

function getGateways(scopeId, page) {
    let abortController = addAbortController(page);
    return fetch('/api/gateway/get-gateways?scopeId=' + scopeId, {signal: abortController.signal})
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}


function getPendingInstruction(macId) {
    return fetch('/api/gateway-pending-instruction/pending-instructions?macId=' + macId)
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function getGatewayActivityLogs(fromTime, toTime, macId){
    return fetch('/api/gateway-activity-logs/logs?macId=' + macId + "&fromTime=" + fromTime + "&toTime=" + toTime)
        .then(res => res.json())
        .then((json) => {
            return json;
        })
        .catch((err) => {
            return err;
        });
}

function getAllTenantsWithGateways(page){
    let abortController = addAbortController(page);
    return fetch("/api/get-tenants-with-devices", {signal: abortController.signal})
          .then(res => res.json())
          .then((json) => {
              console.info(json);
              return json;
          })
          .catch((err) => {
              console.error(err);
              return err;
          });
}

function createInstruction(callback, instruction_payload) {
    return fetch("/api/instruction", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instruction_payload)
    }).then(callback);

}

function createInstruction1 (instruction_payload) {
    return fetch("/api/instruction", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instruction_payload)
    }).then((res)=>{
        if(res.status === 201 || res.status === 200){
            return res.json();
        }else {
            return res;
        }
    });
}

function createLockModeInstruction1 (instruction_payload, lockType) {
    return fetch("/api/lock-mode-instruction/instruction/lockType/"+lockType, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instruction_payload)
    }).then((res)=>{
        if(res.status === 201 || res.status === 200){
            return res.json();
        }else {
            return res;
        }
    });
}

function updateInstruction(callback ,instruction_payload) {
    return fetch("/api/update-instruction", {
        method: 'PUT',              
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instruction_payload)
    }).then(callback);
}

function createScheduleInstruction(instruction_payload){
    return fetch("/api/instruction", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instruction_payload)
    }).then((res)=>{
        if(res.status === 201 || res.status === 200){
            return res.json();
        }else {
            return res;
        }
    });
}


function updateGateway(callback, payload) {
    return fetch("/api/update-gateway/" + payload.MAC, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then(callback);

}

function updateThresholdData(payload) {
    return fetch("/api/update-gateway/" + payload.MAC, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then((response) => {
        return response;
    }).catch((err)=>{
        return err;
    })

}

function deleteInstruction(instructionId) {
    return fetch("/api/instruction/delete/id/"+instructionId,{
        method:"DELETE",
    }).then((response) => {
        return response;
    }).catch((err)=>{
        return err;
    })
} 


function getGatewayAppDetails() {
    return fetch("/api/gateway-app-details").then((response)=>{
        return response.json();
    }).catch((err)=>{
        return err;
    })
}

function getAllGateways(page) {
    let abortController = addAbortController(page);
    return fetch('/api/gateway/get-all-gateways', {signal: abortController.signal}).then((res) => {
        return res.json();
    }).catch((err) => {
        return err;
    })
}

function assignScope(callback, payload) {
    return fetch("api/gateway/" + payload.id + "/assign-scope/" + payload.scopeId, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then(callback)
}

function registerGateway(callback, payload) {
    return fetch("api/gateway/register-gateway", {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then(callback)
}

function abortInstruction( payload) {
    return fetch("api/abort/pending-instructions", {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then((response)=>{
        return response;
    }).catch((error)=>{
        return error;
    })
}

function deleteGateway(macId) {
    return fetch("api/unregister-gateway/macId/"+macId,{
        method: "DELETE"
    }).then((response) => {
        return response;
    }).catch((err)=>{
        return err;
    })
}

function getIotScheduledUsers(macId, scopeId) {
    return fetch("/api/iot-device/scheduled-users/all?macId="+macId+"&scopeId="+scopeId+"&statuses=SCHEDULED").then((response)=>{
        if (response.status === 200){
            return response.json();
        }else {
            return response;
        }
    }).catch((err)=>{
        return err;
    })
}

function createLockUnlockEntry(data) {
    return fetch("/api/gateway-service/activity-feed/unlock-lock",{
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }).then((response) => {
    }).catch((err) => {
        return err;
    })
}



function abortSignal(page) {
    let abortSignals = allAbortControlllers.hasOwnProperty(page) ? allAbortControlllers[page] : [];
    _.forEach(abortSignals, (abortController)=> {
        abortController.abort()
    })
}

const GatewayServiceClient = {  getGatewayAppDetails, getIotScheduledUsers, getGateways, deleteGateway, getPendingInstruction,
                                getGatewayActivityLogs, getAllTenantsWithGateways, createInstruction, updateInstruction,
                                deleteInstruction, createScheduleInstruction, updateThresholdData , updateGateway, assignScope,
                                registerGateway, abortInstruction, getAllGateways, createLockUnlockEntry, createInstruction1, createLockModeInstruction1, abortSignal};
export default GatewayServiceClient;
