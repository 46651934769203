import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IotServiceClient from 'clients/IotServiceClient';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import GenericChannel from 'helper-components/GenericChannel';
import utils from 'helper-components/utils';
import { useSnackbar } from "notistack";
import * as React from 'react';



export default function UnRegisterDialog(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff");
    let {publish, deleteInstruction} = GenericChannel();

    React.useEffect(() => {
        setTimeout(() => {
          setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
          setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
        }, 1000);
    }, []);
    const unRegisterDevice = () => {
        let deviceData = Object.assign({},props.unRegistereDeviceData)        
        deviceData.registerMe = 1;
        //MqttEventHandler.publish("iot-service/devices/" + deviceData.MAC + "/registration","de-register" , {qos: 2, retain: false}, function() {});
        publish(deviceData, "register", 0, utils.getUniqueKey());
        let portalEventsPayload = {
            "MAC" : deviceData.MAC,
            "TI": new Date().getTime(),
            "SE" : [{
                "nme": "Gateway Unregister Initiated",
                "val": JSON.parse(sessionStorage.userDetails).user.email
            }]    
        }
        IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
        // IotServiceClient.unRegisterDevice(deviceData,"devicePage").then((response)=>{
        //     if (response.hasOwnProperty("id")){
        //         enqueueSnackbar("Successfully un-registered "+ deviceData.macId + " device");
        //     }else {
        //         enqueueSnackbar("Failed to un-registered "+ deviceData.macId + " device");
        //     }
        // })
        props.closeDialog();
    }

  return (
    <Dialog
        open={props.openDialog}
        onClose={() => props.closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>
            Gateway un-registration
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to Unregister Gateway from Cloud Server and Delete Gateway Stored Data ?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.closeDialog()}>cancel</Button>
            <Button sx={{color:primaryThemeColor}} onClick={() => unRegisterDevice()} autoFocus>
            OK
            </Button>
        </DialogActions>
    </Dialog>
  );
}
