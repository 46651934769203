import Slide from '@mui/material/Slide';
import Appheader from 'components/header/Appheader';
import LogoHeader from 'components/header/LogoHeader';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css'; // Optional: If you have a global CSS file
import AutoLogout from 'components/idletimer/AutoLogout';
import DetectBrowserOrTabClose from 'components/DetectBrowserOrTabClose';


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}
const headerContainer = document.getElementById('rootHeader');
const rootHeader = createRoot(headerContainer);
const container = document.getElementById('root');
const root = createRoot(container);

const RenderHeader = () => {
    const [hasUserDetails, setHasUserDetails] = React.useState(sessionStorage.hasOwnProperty("userDetails"));
    const [locationHash, setLocationHash] = React.useState(window.location.hash);


    React.useEffect(() => {
        const checkForUserDetails = () => {
            setHasUserDetails(sessionStorage.hasOwnProperty("userDetails"));
            setLocationHash(window.location.hash);
        }
        window.addEventListener('hashchange', checkForUserDetails);

        return () => {
          window.removeEventListener('hashchange', checkForUserDetails);
        };
    })

    return hasUserDetails ?
        locationHash.startsWith("#/cli/") ?
            <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
               <DetectBrowserOrTabClose />
               <AutoLogout />
            </SnackbarProvider>
        :
            <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
               <DetectBrowserOrTabClose />
               <AutoLogout />
               <Appheader />
            </SnackbarProvider>
    :
        <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
           <LogoHeader />
        </SnackbarProvider>;
}

rootHeader.render(
    <BrowserRouter>
        <RenderHeader />
    </BrowserRouter>
)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


