import { ArrowBack, HelpOutline } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import IotServiceClient from "clients/IotServiceClient";
import MqttEventHandler from "components/Mqtt/MqttEventHandler";
import { useSnackbar } from "notistack";
import PubSubChannels from "pubsub/PubSubChannels";
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import 'stylesheet/UpdateFirmware.css';


export default function PortalConfig(){
  const [selectedRadioAction, setSelectedRadioAction] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  React.useEffect(() => {
    subscribeMqttChannel();
    fetchConfigurationData();
    return () => {
      if(window.changeConfiguration){
        window.changeConfiguration.unsubscribe();
      }
    }
  }, []);

  const fetchConfigurationData = () => {
    IotServiceClient.getConfigurations().then((response) => {
      if(response.graphGreyShadedOption === "On"){
        setSelectedRadioAction("On");
      } else {
        setSelectedRadioAction("Off");
      }
    });
  }

  const subscribeMqttChannel = () => {
    window.changeConfiguration = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.PORTAL_CONFIGURATION.replace("scopeId",JSON.parse(sessionStorage.userDetails).user.scopeId), "graphConfiguration", handleChangeConfiguration);
  }

  const handleChangeConfiguration = () => {
    fetchConfigurationData();
  }

  const backToCustomersPage = () => {
     if(sessionStorage.isRootUser === "true"){
       window.location.hash= "/admin/tenants";
       location.hash= "#/admin/tenants";
     }else {
       window.location.hash= "/cli/dashboard";
       location.hash= "#/cli/dashboard";
     }
  }

  const handleActionRadioChange = (event) => {
    setSelectedRadioAction(event.target.value);
  }

  const handleClick = () => {
    let payload = {
       "graphOption" : selectedRadioAction,
       "scopeId" : JSON.parse(sessionStorage.userDetails).user.scopeId
    }
    IotServiceClient.saveConfigurations(payload).then((response) => {
        if(response.hasOwnProperty("id")){
          enqueueSnackbar("Configuration Applied Successfully");
        } else {
          enqueueSnackbar("Failed to Apply Configuration");
        }
    });
  }

  return(
    <div>
      <div style={{height: '56px'}}>
        <ArrowBack
          sx={{ color:"#4190c7"}}
          onClick={() => {
            if(sessionStorage.isRootUser === "true"){
              window.location.hash= "/admin/tenants";
              location.hash= "#/admin/tenants";
            }else {
              window.location.hash= "/cli/dashboard";
              location.hash= "#/cli/dashboard";
            }
          }}
          className="backArrow"
        />
      </div>
      <div className="container" style={{maxHeight: '95%'}}>
        <div className="subContainer"
          style={{
            minHeight: '100%',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <div
            style={{
              flex: '20',
              padding: '0 12px',
              borderBottom: '1px solid rgba(0,0,0,0.12)',
            }}
          >
            <h5 style={{fontSize:'16px'}}>Manage Graph Online-Offline Feature</h5>
          </div>
          <div>
            <div className="radioGroup">
              <FormControl component="fieldset" variant="standard">
                <RadioGroup
                  row
                  aria-label="emailType"
                  name="row-radio-buttons-group"
                  onChange={handleActionRadioChange}
                >
                  <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                    <Tooltip title="Show Grey Area on Graph to represent Gateway Offline Period" placement="top">
                      <div style={{flex:'45%'}}>
                        <FormControlLabel
                          value="On"
                          control={
                            <Radio
                              sx={{
                                color: 'rgba(0,0,0,0.54)',
                                '&.Mui-checked': {
                                  color: '#4190c7',
                                },
                              }}
                            />
                          }
                          checked={selectedRadioAction === "On" ? true : false}
                          label="ON"
                        />
                        <HelpOutline sx={{fontSize:'18px',color:'#0000008a',position:'relative',marginBottom:'-4px',marginLeft:'-12px'}}/>
                      </div>
                    </Tooltip>
                    <Tooltip title="Don't Show Grey Area on Graph to represent Gateway Offline Period" placement="top">
                      <div style={{flex:'45%'}}>
                        <FormControlLabel
                          value="Off"
                          control={
                            <Radio
                              sx={{
                                color: 'rgba(0,0,0,0.54)',
                                '&.Mui-checked': {
                                  color: '#4190c7',
                                },
                              }}
                            />
                          }
                          checked={selectedRadioAction === "Off" ? true : false}
                          label="OFF"
                        />
                        <HelpOutline sx={{fontSize:'18px',color:'#0000008a',position:'relative',marginBottom:'-4px',marginLeft:'-12px'}}/>
                      </div>
                    </Tooltip>
                    <div style={{flex:'10%'}}>
                      <Button variant="contained" sx={{margin:'15px',fontWeight:'bold'}} onClick={() => handleClick()}>Apply</Button>
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="bottomDiv">
            <Button style={{color: '#4190c7', margin: '2px',fontWeight:'bold'}} onClick={()=>backToCustomersPage()}>
              BACK TO CUSTOMERS
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}