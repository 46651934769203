import { Box, CircularProgress, Grid, Paper, Snackbar, Typography } from '@mui/material';
import AuthClient from "clients/AuthClient";
import IotServiceClient from "clients/IotServiceClient";
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import LocationMap from 'helper-components/LocationMap';
import _ from 'lodash';
import { useSnackbar } from "notistack";
import PubSubChannels from 'pubsub/PubSubChannels';
import * as React from 'react';
import Chart from 'react-google-charts';
import 'stylesheet/Dashboard.css';

 export default function GlobalDashboard(props) {

  const [TenantName, setTenantName] = React.useState(null);
  const [clickEventCount, setClickEventCount] = React.useState(0);
  const [globalDashboardGatewaysEventHandlerChannel, setGlobalDashboardGatewaysEventHandlerChannel] = React.useState(null);
  const [dashboardLockStatusEventHandlerChannel, setDashboardLockStatusEventHandlerChannel] = React.useState(null);
  const [onlineDeviceCount, setOnlineDeviceCount] = React.useState(0);
  const [offlineDeviceCount, setOfflineDeviceCount] = React.useState(0);
  const [registeredGatewayCount, setRegisteredGatewayCount] = React.useState(0);
  const [unregisteredGatewayCount, setUnregisteredGatewayCount] = React.useState(0);
  
  const [deviceDistributionData, setDeviceDistributionData] = React.useState(["name","Devices"],["",0]);
  const [globalDashboardScopeId, setGlobalDashboardScopeId] = React.useState(sessionStorage.tenantId);
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  window.globalDashboardIotGateways = [];
  const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
  const [deviceLocations, setDeviceLocations] = React.useState([]);
  const [gatewayDistributionsData, setGatewayDistributionsData] = React.useState([["Tenant Name", "count"],["",0]]);
  const [connectivityData, setConnectivityData] = React.useState([["Device status","count"],["",0]])
  const [dataFetched, setDataFetched] = React.useState(false);
  const [enrollmentStatusData, setEnrollmentStatusData] = React.useState([["enrollmentStatus", "count"],["",0]]);
  const [deviceOsVersionData, setDeviceOsVersionData] = React.useState([["OSVersion","OS"],["",0]]);
  const [deviceFirmwareChartData, setDeviceFirmwareChartData] = React.useState([["Version","FM"],["",0]]);
  const { enqueueSnackbar } = useSnackbar() ;

  const options = {
      legend: {
          position: 'top',
          maxLines: 3,
          textStyle: {fontSize: 9},
      },
      chart: {
        title: '',
        subtitle: '',
      },
  };

  const getDevicesDataByFirmwareVersions = (devices) => {
        _.forEach(devices,(device) => {
            if(device.FW !== null && device.FW !== undefined) {
                let firmwareVersion = device.FW;
                device.versionNum = firmwareVersion.substring(0, 10);
            }
        });
        var data = _.groupBy(devices, function(device) {
            return device.versionNum;
        });
        return data;
  };

   const getDevicesDataByOsVersions = (devices) => {
        var data = _.groupBy(devices, function(device) {
            if(device.OS !== null && device.OS !== undefined) {
                  return device.OS;
            }
        });
        return data;
   }

   const getDevicesDataByScopeId = (devices) => {
          var data = _.groupBy(devices, function(device) {
              if(device.SID !== null && device.SID !== undefined) {
                        return device.SID;
              }
          });
          return data;
   };

   const fetchGlobalDashboardData = () => {

    let allLockTypeExist = [];
    let devicesLocation = [];
    let allOSVersions = {};
    let allFirmwares = {};
    let filteredFirmwares = [["Version","FM"]];
    let filteredOsVersions = [["Version","OS"]]
    let locationData = [];
    IotServiceClient.getReachableIotDevices("all", "globalDashboardPage").then((data) => {
      setDataFetched(true);
      let devicesWithscopeId = {};
      let [onlineCounter, offlineCounter,lteCounter, ethernetCounter, wifiCounter, registerCount, unRegisterCount] = [0, 0, 0, 0, 0, 0,0];
          if (!data.hasOwnProperty("message")){                               
            _.forEach(data,function(iotDevice, index){
              if (iotDevice.type){
                  allLockTypeExist.push(iotDevice.type);
              }

              if (checkDataExistsOrNot(iotDevice.LCTN) ? checkDataExistsOrNot(iotDevice.LCTN.LAT) && checkDataExistsOrNot(iotDevice.LCTN.LNG) : false){
                if(devicesLocation.hasOwnProperty(iotDevice.LCTN.LAT)){
                  devicesLocation[iotDevice.LCTN.LAT].MAC += ", " + iotDevice.MAC;
                  devicesLocation[iotDevice.LCTN.LAT].count += 1;
                }else{
                  devicesLocation[iotDevice.LCTN.LAT] = {
                    count: 1,
                    NAME: iotDevice.NAME,
                    LAT: iotDevice.LCTN.LAT,
                    LNG: iotDevice.LCTN.LNG,
                    IPADDR: iotDevice.LCTN.IPADDR,
                    MAC: iotDevice.MAC,
                    CS: iotDevice.CS
                  };
                }
              }
              if (iotDevice.CS === 1) {
                onlineCounter += 1;                
              } else {
                offlineCounter += 1;
              }           

              if (iotDevice.REG === 1){
                registerCount++;
              }else{
                unRegisterCount++;
              }

              if(checkDataExistsOrNot(iotDevice.NW)){
                if(iotDevice.NW === 0){
                    lteCounter+=1;
                }else if(iotDevice.NW === 2){
                  ethernetCounter+=1;
                }else{
                  wifiCounter+=1;
                }
              }
              if (checkDataExistsOrNot(iotDevice.FW)){
                if (_.has(allFirmwares, iotDevice.FW)){
                  allFirmwares[iotDevice.FW]+=1;
                }else {
                  allFirmwares[iotDevice.FW] = 1;
                }
              }

              if (checkDataExistsOrNot(iotDevice.OS)){
                if (_.has(allOSVersions, iotDevice.OS)){
                  allOSVersions[iotDevice.OS]+=1;
                }else {
                  allOSVersions[iotDevice.OS] = 1;
                }
              }

              if (!_.has(devicesWithscopeId, iotDevice.SID)){
                devicesWithscopeId[iotDevice.SID] = 1;
              }else {
                devicesWithscopeId[iotDevice.SID] += 1;
              }
            });
              
            if (_.size(allFirmwares) > 0){
              _.forOwn(allFirmwares, (value, key) => {
                filteredFirmwares.push([key, value]);
              }); 
            }else {
              filteredFirmwares.push(["",0]);
            }
            
            if (_.size(allOSVersions) > 0){
              _.forOwn(allOSVersions, (value, key) => {
                filteredOsVersions.push([key, value]);
              }); 
            }else {
              filteredOsVersions.push(["",0]);
            }

            Object.keys(devicesLocation).map((key) => {
              if(devicesLocation[key].hasOwnProperty('LAT') && devicesLocation[key].hasOwnProperty('LNG')){
                locationData.push({"lat":devicesLocation[key].LAT,"lng":devicesLocation[key].LNG,"macId":devicesLocation[key].MAC,"connStatus":devicesLocation[key].CS,"count":devicesLocation[key].count})
              }
            })
            AuthClient.getHirarchy().then((response)=>{
              let deviceNameWithDevicesData = [["Tenant Name", "count"]];
              if (response.hasOwnProperty("scope")){
                let allScopeIdWithNames = {};
                _.forEach(response.children, (res)=>{
                  allScopeIdWithNames[res.scope.id] = res.scope.name;
                })
                allScopeIdWithNames[response.scope.id] = response.scope.name;
                _.forOwn(devicesWithscopeId, (value, key) => {
                  deviceNameWithDevicesData.push([allScopeIdWithNames[key], value]);
                })
              }
              setGatewayDistributionsData(deviceNameWithDevicesData);
            })
            
            setConnectivityData([["Device status","count"],["Online", onlineCounter], ["Offline", offlineCounter]]);
            setEnrollmentStatusData([["Enrollment Status", "count"],["Registered", registerCount], ["Un-Registered", unRegisterCount]]);
            setDeviceLocations(locationData);
            setDeviceFirmwareChartData(filteredFirmwares);
            setDeviceOsVersionData(filteredOsVersions);
        }else {
          setConnectivityData([["Device status","count"],["Online", onlineCounter], ["Offline", offlineCounter]]);
          setEnrollmentStatusData([["Enrollment Status", "count"],["Registered", registerCount], ["Un-Registered", unRegisterCount]]);
          setDeviceLocations(locationData);
          setDeviceFirmwareChartData(filteredFirmwares);
          setDeviceOsVersionData(filteredOsVersions);
        }
    });
   }

   const handleGatewaysEventReceived = () => {
           enqueueSnackbar("Refreshing global dashboard page...")
           fetchGlobalDashboardData();
   }

   const handleDeviceLockEventReceived = () => {
         setShowSnackBar(true);
         setSnackBarMessage("Refreshing Gateways Status ...");

           setTimeout(() => {
                   setShowSnackBar(false);
                   setSnackBarMessage("");
           }, 3000);
           fetchGlobalDashboardData();
   }

    const handleDeviceOnlineStatusEventReceived = () => {
          setShowSnackBar(true);
          setSnackBarMessage("Refreshing Online Status ...");

          setTimeout(() => {
                    setShowSnackBar(false);
                    setSnackBarMessage("");
          }, 3000);
          fetchGlobalDashboardData();
    }

   const subscribeToChannels = () => {
                const fetchOnlineStatus = () => {
                    handleDeviceOnlineStatusEventReceived();
                }
                window.globalDashboardGatewaysEventHandlerChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DEVICES.replace("scopeId", sessionStorage.tenantId), "dashboardlockController", handleGatewaysEventReceived);
   }

   React.useEffect(()=>{
        fetchGlobalDashboardData();
        subscribeToChannels();
        setTimeout(() => {
          setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
          setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
        }, 1000);
        return () => {
          if (window.globalDashboardGatewaysEventHandlerChannel){
                 window.globalDashboardGatewaysEventHandlerChannel.unsubscribe();
          }
          if (window.dashboardLockStatusEventHandlerChannel){
               window.dashboardLockStatusEventHandlerChannel.unsubscribe();
          }
          if (window.lockOnlineStatusEventHandlerChannel){
                    window.lockOnlineStatusEventHandlerChannel.unsubscribe();
          }
        }
   },[]);

  return (

    <Box sx={{flexGrow: 1, margin: '8px', padding: '8px'}}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={6}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: primaryThemeColor, color: secondaryThemeColor, padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Connectivity
              </Typography>
            </Box>
            <Box  sx={{margin: 'auto', height: '240px', width: '320px'}}>
              <Chart
                width={'320px'}
                height={'240px'}
                chartType="PieChart"
                loader={
                   <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                       <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                   </Box>
                }
                data={connectivityData}
                options={{
                  title: '',
                  slices: [{color: '#109618'}, {color: '#DC3912'}],
                  pieSliceText: 'value',
                  chartArea: {'width': '90%', 'height': '90%'},
                  margin: 'auto',
                }}                
                rootProps={{'data-testid': '1'}}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: primaryThemeColor, color: secondaryThemeColor, padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Enrollment Status
              </Typography>
            </Box>
            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
              <Chart
                width={'320px'}
                height={'240px'}
                chartType="PieChart"
                loader={
                    <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                        <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                    </Box>
                }
                data={enrollmentStatusData}
                options={{
                   title: '',
                   slices: [{color: '#109618'}, {color: '#DC3912'}],
                   pieSliceText: 'value',
                   margin: 'auto',
                   chartArea: {'width': '90%', 'height': '90%'}
                }}
                rootProps={{'data-testid': '1'}}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid sx={{marginTop: '25px'}} container spacing={4}>
        <Grid item xs={12} md={12} lg={6}>
                  <Paper elevation={1}>
                    <Box sx={{backgroundColor: primaryThemeColor, color: secondaryThemeColor, padding: '10px'}}>
                      <Typography
                        mt={2}
                        style={{
                          fontWeight: '350',
                          fontSize: '1em',
                          margin: '10px',
                        }}
                      >
                        Gateways Distribution
                      </Typography>
                    </Box>
                    <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
                      <Chart
                        width={ '320px'}
                        height={'240px'}
                        chartType="PieChart"
                        loader={
                            <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                                <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                            </Box>
                        }
                        data={gatewayDistributionsData}
                        options={{
                              title: '',
                              slices: [{color: '#109618'}, {color: '#DC3912'}],
                              pieSliceText: 'value',
                              chartArea: {'width': '90%', 'height': '90%'},
                               margin: 'auto',
                        }}
                        rootProps={{'data-testid': '1'}}
                      />
                    </Box>
                  </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: primaryThemeColor, color: secondaryThemeColor, padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Gateway OS
              </Typography>
            </Box>
            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
              <Chart
                width={'320px'}
                height={'240px'}
                chartType="ColumnChart"
                loader={
                    <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                         <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                    </Box>
                }
                data={deviceOsVersionData}
                options={{
                    legend: {
                               position: 'top',
                               maxLines: 3,
                               textStyle: {fontSize: 9},
                    },
                    chart: {
                              title: '',
                              subtitle: '',
                    },
                    series: {
                             0: { color: '#ba55d3' }
                    },
                    chartArea: {'width': '70%', 'height': '70%'}
                }}
                //rootProps={{'data-testid': '1'}}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid sx={{marginTop: '25px'}} container spacing={4}>
          <Grid item xs={12} md={12} lg={6}>
                <Paper elevation={1}>
                  <Box sx={{backgroundColor: primaryThemeColor, color: secondaryThemeColor, padding: '10px'}}>
                    <Typography
                      mt={2}
                      style={{
                        fontWeight: '350',
                        fontSize: '1em',
                        margin: '10px',
                      }}
                    >
                      Locations
                    </Typography>
                  </Box>
                  <Box className="mapClass" sx={{ margin: 'auto', height: '240px', width: '100%'}}>
                     {
                      !dataFetched ?
                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box>
                      :
                        <LocationMap divHeight="240px" markers={deviceLocations} />
                     }
                  </Box>
                </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
               <Paper elevation={1}>
                 <Box sx={{backgroundColor: primaryThemeColor, color: secondaryThemeColor, padding: '10px'}}>
                   <Typography
                     mt={2}
                     style={{
                       fontWeight: '350',
                       fontSize: '1em',
                       margin: '10px',
                     }}
                   >
                     Gateway Firmware
                   </Typography>
                 </Box>
                 <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
                   <Chart
                     width={ '320px'}
                     height={'240px'}
                     chartType="ColumnChart"
                     loader={
                         <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                             <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                         </Box>
                     }
                     data={deviceFirmwareChartData}
                     options={{
                           legend: {
                                    position: 'top',
                                    maxLines: 3,
                                    textStyle: {fontSize: 9},
                           },
                           chart: {
                                    title: '',
                                    subtitle: '',
                           },
                           series: {
                                      0: { color: '#ba55d3' }
                           },
                           chartArea: {'width': '70%', 'height': '70%'}
                     }}
                     // rootProps={{'data-testid': '1'}}
                   />
                 </Box>
               </Paper>
          </Grid>
      </Grid>
      <Grid sx={{marginTop: '25px'}} container spacing={4}>
      </Grid>
      {
          showSnackBar ?
                 <Snackbar
                     anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                     open={showSnackBar}
                     message={snackBarMessage}
                 />
                 :
          null
      }
    </Box>
  );
}
