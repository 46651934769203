import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import React from 'react';

export default function MigrateDialogAlert(props) {
    
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    
    React.useEffect(() => {
        setTimeout(() => {
            setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
            setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
        }, 1000);
    }, []);

    return (
        <Dialog
            open={true}
            onClose={() =>  props.close()}
        >
            <DialogTitle>
                Gateway Migration
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        !props.unRegisterFlag ?
                            <>
                                The migration of the Gateway takes around 1 min to complete. During this time the Gateway will go offline.Please wait it to come back online in the tenant.
                            </>
                       :
                            <>
                                The migration of the Gateway & registration of Gateway takes around 1 min to complete. During this time the Gateway will go offline.Please wait it to come back online in the tenant.
                            </>

                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.close()}>cancel</Button>
                <Button sx={{color:primaryThemeColor}} onClick={() => {props.migrateGateway();props.closeDialog()}} autoFocus>
                    ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}
