import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import { Close } from '@mui/icons-material';
import AuthClient from "clients/AuthClient";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import PaperComponent from 'helper-components/PaperComponent';
import { useSnackbar } from "notistack";
import { useLocation } from 'react-router-dom';
import React from 'react';

const DeletePortalUserDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [showSnackBarMessage, setShowSnackBarMessage] = React.useState("");
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [showStatusText, setShowStatusText] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    React.useEffect(() => {
      setTimeout(() => {
        setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
        setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
      }, 1000);
    }, []);
    const handleDeletePortalUserDialog = () => {
      props.close_delete_permission_dialog(false);
    }
    const deletePortalUser = () => {
      AuthClient.deletePortalUser(props.data.id,sessionStorage.tenantId).then((response)=>{
        if (response.status === 200 || response.status ===201 || response.status === 204){
          enqueueSnackbar("User successfully removed");
          props.close_delete_permission_dialog(false);
        }else{
            enqueueSnackbar("Failed to remove your account.")
        }
      });
    }

    const removePortalUser = () => {
      setIsLoading(true);
      AuthClient.removeYourAccount(props.data.id,sessionStorage.tenantId).then((response)=>{
        if (response.status === 200 || response.status ===201 || response.status === 204){
          let payload = {
            "email": JSON.parse(sessionStorage.userDetails).user.email,
            "accountDeleted": {"ti" : [new Date().getTime()]}
          };
          AuthClient.saveUserActivities(payload,"accountDeleted").then((response) => {});
          let logoutPayload = {
            "scopeId": JSON.parse(sessionStorage.userDetails).user.scopeId,
            "email": JSON.parse(sessionStorage.userDetails).user.email,
            "logout" : {"ti" : [new Date().getTime()]}
          };
          AuthClient.saveLoginOrLogoutActivity(logoutPayload,sessionStorage.activityKey,"logout").then((response) => {});
          sessionStorage.clear();
          setTimeout(() => {
              setShowStatusText(true);
              setIsSuccess(true);
              setIsLoading(false);
              enqueueSnackbar("User successfully removed");
          },1000)
          setTimeout(() => {
              window.location.hash = "/logout";
              location.hash = "#/logout";
          },3000)
          //props.close_delete_permission_dialog(false);
        }else{
            setTimeout(() => {
                setShowStatusText(true);
                setIsSuccess(false);
                setIsLoading(false);
                enqueueSnackbar("Failed to remove your account.")
            },1000)
        }
      });
    }
    return (
      <div>
        {props.dialog === 'deletePermissionDialog' ? (
           <Dialog
              fullWidth={true}
              fullScreen={fullScreen}
              open={props.show_delete_permission_dialog}
              onClose={handleDeletePortalUserDialog}
              PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
              aria-labelledby="draggable-dialog-title"
           >
             <DialogTitle style={{backgroundColor: primaryThemeColor, display: 'flex', flexDirection: 'row',}}>
               <Box id="draggable-dialog-title" style={{marginRight:'auto', flex: '95'}}>
                 {props.title}
               </Box>
               <Box sx={{flex: '5'}}>
                 <Close onClick={()=>props.close_delete_permission_dialog(false)} sx={{cursor:"pointer"}}/>
               </Box>
             </DialogTitle>
             <DialogContent sx={{marginTop: '10px', paddingBottom: '10px', display:'flex', alignItems:'center', justifyContent: showStatusText || isLoading ? 'center' : 'unset' }}>
               <DialogContentText style={{color:'black'}}>
               {   !showStatusText ?
                       !isLoading ?
                           <Box> Are you sure, you want to {props.flag ? "remove your account" : "delete this portal user" }? </Box>
                       :
                           <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: primaryThemeColor + ' !important'}}/>
                   :
                       null
               }
               {   showStatusText ?
                       isSuccess ?
                           <Box sx={{color: 'green'}}> Removed your account successfully. </Box>
                       :
                           <Box> <span style={{color:'red'}}>Error:</span> Failed to remove you account, try again. </Box>
                   :
                       null
               }
               </DialogContentText>
             </DialogContent>
             <DialogActions sx={{visibility: showStatusText || isLoading ? 'hidden' : 'visible'}}>
               <Button onClick={() => handleDeletePortalUserDialog()}>Cancel</Button>
               <Button style={{color:primaryThemeColor}} onClick={() => props.flag ? removePortalUser() : deletePortalUser()}>Yes</Button>
             </DialogActions>
           </Dialog>
        ) : null}
        {
          showSnackBar ?
          <Snackbar
            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            open={showSnackBar}
            autoHideDuration={3000}
            message={showSnackBarMessage}
          />
        :
          null
        }
      </div>
    );
};
export default DeletePortalUserDialog;
