import CanvasJSReact from "@canvasjs/react-charts";
import { ArrowBack, CheckCircle, Close, Download, FilterList, NotificationImportantOutlined, NotificationsActive } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grow, InputLabel, List, ListSubheader, Menu, MenuItem, Paper, Popper, Select, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import IotServiceClient from "clients/IotServiceClient";
import MqttEventHandler from "components/Mqtt/MqttEventHandler";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import PaperComponent from 'helper-components/PaperComponent';
import _ from "lodash";
import moment from 'moment';
import PubSubChannels from 'pubsub/PubSubChannels';
import React from 'react';
import CsvDownload from 'react-json-to-csv';
import Moment from 'react-moment';
import { AutoSizer } from 'react-virtualized';
import { List as VirtualList } from "react-virtualized/dist/es/List";
import styles from "stylesheet/gatewayStatusDialog.module.css";
import "./devices.css";


export default function ShowGraphData(props) {
    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;    
    const memoryChartRef = React.useRef(null);
    const cpuChartRef = React.useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const filterZoomedAlertsAndEventsData = (max, min) => {
      let allAlertsAndEvents = {};
      let allSearchOptions = [];
      let allDates = [];
      let alertsAndEventsCount = 0;
      _.forEach(window.crbStates, (val) => {
          if (val.TI <= max && val.TI >= min){                
            if (checkDataExistsOrNot(val.SE)){
              _.forEach(val.SE, (events) => {
                let date = moment(events.TI).format("YYYY-MM-DD");                    
                let evnt = {"name": events.nme, "time": val.TI, "value": events.val, type:"event", color: "#808080"};
                
                if (!_.includes(allSearchOptions, events.nme)){
                  allSearchOptions.push(events.nme);
                }
                
                if (_.has(allAlertsAndEvents, date)){
                  allAlertsAndEvents[date].push(evnt);
                }else {
                  allAlertsAndEvents[date] = [evnt];  
                }
                if (!_.includes(allDates, date)){
                    allDates.push(date);
                }
                alertsAndEventsCount+=1;
              })
            }

            if (checkDataExistsOrNot(val.SA)){
              _.forEach(val.SA, (alerts) => {
                let date = moment(alerts.TI).format("YYYY-MM-DD");
                let alrt = {"name": alerts.nme, "time": val.TI, "value": alerts.val, type:"alert", color: typeof alerts.val === "number" ? alerts.val <= 50 ? "green" : "red" : "red"};
                
                if (!_.includes(allSearchOptions, alerts.nme)){
                  allSearchOptions.push(alerts.nme);
                }

                if (_.has(allAlertsAndEvents,date)){
                  allAlertsAndEvents[date].push(alrt);
                }else {
                  allAlertsAndEvents[date] = [alrt];  
                }
                if (!_.includes(allDates, date)){
                  allDates.push(date);
                }
                alertsAndEventsCount+=1;
              })
            }
          }
      })      
      setAllActivityLogDates(allDates);
      setAlertsCount(alertsAndEventsCount)
      setLogs(allAlertsAndEvents);
      setSearchOptions(allSearchOptions);
      setIsLoading(false);
    };
    const syncHandler = (e) => {
      if (checkDataExistsOrNot(cpuChartRef.current) && checkDataExistsOrNot(memoryChartRef.current)){
        setIsLoading(true);
        let allCharts = [cpuChartRef.current.chart , memoryChartRef.current.chart]
        _.forEach(allCharts, (value, index) => {
            let chart = allCharts[index];
      
            if (!chart.options.axisX) 
              chart.options.axisX = {};
            
            if (!chart.options.axisY) 
                chart.options.axisY = {};
      
            if (e.trigger === "reset") {
              chart.options.axisX.viewportMinimum = chart.options.axisX.viewportMaximum = null;
              chart.options.axisY.viewportMinimum = chart.options.axisY.viewportMaximum = null;
              chart.render();
              setAllActivityLogDates(window.graphActivityLogDates);
              setAlertsCount(window.allAlertsAndEventCount)
              setLogs(window.allAlertsAndEvents);
              setSearchOptions(window.graphSearchOptions);
              setIsLoading(false);                      
            } else if (chart !== e.chart) {    
              chart.options.axisX.viewportMinimum = e.axisX[0].viewportMinimum;
              chart.options.axisX.viewportMaximum = e.axisX[0].viewportMaximum;                
              chart.options.axisY.viewportMinimum = e.axisY[0].viewportMinimum;
              chart.options.axisY.viewportMaximum = e.axisY[0].viewportMaximum;      
              chart.render();
              filterZoomedAlertsAndEventsData(e.axisX[0].viewportMaximum, e.axisX[0].viewportMinimum)
            }
        });
      }
    }

    const getCpuOptions = () => {
      return {
          axisX: {
            // Set interval to one day in milliseconds
            minimum: window.setMinRangeOfGraph,
            maximum: window.setMaxRangeOfGraph,
            tickLength: 0,
            labelFontSize: 16,
            labelAngle: 180,
            labelTextAlign: "center",
            gridColor: "transparent", // Hide both front and back vertical grid lines
            labelFormatter: function (enrollmentStatus) {
                return CanvasJS.formatDate(enrollmentStatus.value, "MMM DD, YYYY hh:mm TT");
            },
          },
          zoomEnabled: true,
          animationEnabled: true,
          axisY: {
            labelWrap: true,
            labelFontSize: 14,
            gridColor: "transparent", // Hide both front and back horizontal grid lines
            tickLength: 0,
            maximum: window.maxCpuValue > 100 ? window.maxCpuValue : 100,
          },
          height:204,
          data : [],          
          rangeChanged: syncHandler,
      }
    }

    const getMemoryOptions = () => {
      return {
        axisX: {
          // Set interval to one day in milliseconds
          minimum: window.setMinRangeOfGraph,
          maximum: window.setMaxRangeOfGraph,
          tickLength: 0,
          labelFontSize: 16,
          labelAngle: 180,
          labelTextAlign: "center",
          gridColor: "transparent", // Hide both front and back vertical grid lines
          labelFormatter: function (enrollmentStatus) {
            return CanvasJS.formatDate(enrollmentStatus.value, "MMM DD, YYYY hh:mm TT");
          },
        },
        zoomEnabled: true,
        axisY: {
          labelWrap: true,
          labelFontSize: 14,
          gridColor: "transparent", // Hide both front and back horizontal grid lines
          tickLength: 0,
          maximum: window.maxMemoryValue > 100 ? window.maxMemoryValue : 100,
        },
        height:204,
        animationEnabled: true,
        data : [],
        rangeChanged: syncHandler
      }
    }
    const [Dimensions, setDimensions] = React.useState({width:window.innerWidth,height: window.innerHeight});
    const [cpuUsageData, setCpuUsageData] = React.useState([{"dataPoints" : []}]);
    const [memoryUsageData, setMemoryUsageData] = React.useState([{"dataPoints" : []}])
    const [cpuUsageOptions, setCpuUsageOptions] = React.useState(getCpuOptions());
    const [memoryOptions, setMemoryOptions] = React.useState(getMemoryOptions());
    const [searchOptions, setSearchOptions] = React.useState([]);
    const [allActivityLogDates, setAllActivityLogDates] = React.useState([]);
    const [activityLogsLoading, setActivityLogsLoading] = React.useState(false);
    const [logs, setLogs] = React.useState([]);
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const [intervalChangeConfirmation, setIntervalChangeConfirmation] = React.useState(false);
    const [prevSelectedValue, setPrevSelectedValue] = React.useState("1 hour");
    const [selectedBefore, setSelectedBefore] = React.useState("1 hour");
    const [showCircularCheckIcon, setShowCircularCheckIcon] = React.useState(true);
    const [showFilterListPopper, setShowFilterListPopper] = React.useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = React.useState(null);
    const [showCircularCheck, setShowCircularCheck] = React.useState(true);
    const [intervalChangeConfirm, setIntervalChangeConfirm] = React.useState(false);
    let currentDate = new Date().getDate();
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;
    currentMonth = currentMonth < 10 ? "0"+ currentMonth : currentMonth;
    const [startInterval, setStartInterval] = React.useState(currentYear + "-" + currentMonth + "-" + currentDate);
    const [endInterval, setEndInterval] = React.useState(currentYear + "-" + currentMonth + "-" + currentDate);
    const [fromDate, setFromDate] = React.useState(currentYear + "-" + currentMonth + "-" + currentDate);
    const [toDate, setToDate] = React.useState(currentYear + "-" + currentMonth + "-" + currentDate);
    const [showAlertsPage, setShowAlertsPage] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selectedInterval, setSelectedInterval] = React.useState("1 hour");
    const anchorRefCustom = React.useRef(null);
    const anchorReffCustom = React.useRef(null);
    const anchorRef = React.useRef(null);
    const [openMenu, setOpenMenu] = React.useState(Boolean(anchorEl));
    const [showPopper, setShowPopper] = React.useState(false);
    const [dataRendered, setDataRendered] = React.useState(false);
    const [alertsCount, setAlertsCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);

    const handleMenuClose = (event) => {
        window.selectedMenuItem = event.target.value;
        setSelectedMenuItem(event.target.value);
    }

    const handleOnClck = (event) => {
      if (checkDataExistsOrNot(event.target.innerText)) {
        if(event.target.innerText !== "Custom Range") {
           IotServiceClient.abortSignal("graphPage");
           if (window.networkcallInterval) {
             clearInterval(window.networkcallInterval);
           }
        }
        window.setSelectedInterval = event.target.innerText;
        if (window.setSelectedInterval === "Custom Range" && window.setSelectedBefore !== "Custom Range") {
           setOpenMenu(true);
        } else {
            handleNonCustomRange();
        }
      } else {
        setOpenMenu(false);
        setShowFilterListPopper(false);
      }
    }

    const handleNonCustomRange = () => {
        calculateData(null, null);
        setOpenMenu(!openMenu);
        setShowFilterListPopper(false);
    }

    const handleStartIntervalChange = (event) => {
       let startDate;
       if (checkDataExistsOrNot(event.target.value)) {
          startDate = event.target.value;
          let endDate = new Date(endInterval + " 23:59:00").getTime();          
          setStartInterval(startDate);
          startDate = new Date(startDate + " 00:00:00").getTime();
          window.startIntervalForApply = startDate;
          window.setMinRangeOfGraph = startDate;
          if( startDate > endDate) {
            window.endIntervalForApply = event.target.value;
            setEndInterval(event.target.value);
          }
       }
    }

    const handleEndIntervalChange = (event) => {
       if (checkDataExistsOrNot(event.target.value)) {
            let endDate = event.target.value;
            let startDate = new Date(startInterval + " 00:00:00").getTime();
            setEndInterval(endDate);
            endDate = new Date(endDate + " 23:59:00").getTime();
            window.endIntervalForApply = endDate;
            window.setMaxRangeOfGraph = endDate;
       }
    }

  // TODO : Need to add network call for fetching data in specified interval
    const handleClickCircularIcon = () => {
      IotServiceClient.abortSignal("graphPage");
      if (window.networkcallInterval) {
        clearInterval(window.networkcallInterval);
      }
      setIntervalChangeConfirm(true);
      setTimeout(() => {
          setOpen(false);
          setShowPopper(false);
          setIntervalChangeConfirm(false);
      },1000);
      calculateData(window.startIntervalForApply, window.endIntervalForApply);
    }

   const handleClickFilterIcon = (event) => {
       setAnchorEl(event.currentTarget);
       setOpenMenu(true);
   }

    const handleFromDateChange = (event) => {
      if (checkDataExistsOrNot(event.target.value)) {
        let startDate = event.target.value;
        let endDate = new Date(toDate + " 23:59:00").getTime();
        setFromDate(startDate);
        startDate = new Date(startDate + " 00:00:00").getTime();
        if( startDate > endDate) {
          setToDate(event.target.value);
        }
      }
    }

    const handleToDateChange = (event) => {
      if (checkDataExistsOrNot(event.target.value)){
        let endDate = event.target.value;
        let startDate = new Date(fromDate + " 00:00:00").getTime();
        setToDate(endDate);
        endDate = new Date(endDate + " 23:59:00").getTime();
      }
    }

    // TODO : Need to add network call for fetching data in specified interval
    const handleClickCircularCheckIcon = () => {
      IotServiceClient.abortSignal("graphPage");
        if (window.networkcallInterval) {
          clearInterval(window.networkcallInterval);
        }
      setIntervalChangeConfirmation(true);
      setTimeout(() => {
        setOpenMenu(false);
        setShowFilterListPopper(false);
        setIntervalChangeConfirmation(false);
      },1000);
      calculateData(window.startIntervalForApply, window.endIntervalForApply);
    }

    const handleCustomRange = () => {
       setOpen(!open);
       setShowPopper(false);
    }

    const handleClickCustomOption = () => {
      setShowPopper(true);
      window.setSelectedInterval = "Custom Range";
    }

   const handleCustomSelection = (menuItem) => {
      if (menuItem === "Custom Range") {
        setShowFilterListPopper(true);
        setSelectedInterval("Custom Range");
        window.setSelectedInterval = "Custom Range";
      } else {
//          window.setOpenMenu = false;
//           wsetShowFilterListPopper(false);
//                       window.setSelectedBefore = "Custom Range";
          //setSelectedMenuItem("Custom Range");

      }

   }

  const handleOnClose = () => {
    if (showFilterListPopper){
      window.setSelectedInterval = "1 hour";
      setSelectedInterval("1 hour");
      setShowFilterListPopper(false);
      setOpenMenu(false);
    }
  }

  const handleShowPopperOnClose = () => {
    if (showPopper){
      window.setSelectedInterval = prevSelectedValue;
      setSelectedInterval(prevSelectedValue);
      handleCustomRange();
    }
  }

  const handleOnChangeEvent = (event) => {
    if(event.target.value !== "Custom Range") {
      IotServiceClient.abortSignal("graphPage");
      if (window.networkcallInterval) {
        clearInterval(window.networkcallInterval);
      }
    }
    window.setSelectedInterval = event.target.value;
    setSelectedInterval(event.target.value);
    if (event.target.value !== "Custom Range") {
      setPrevSelectedValue(event.target.value);
      calculateData(null, null);
    }
  }

  const makeNetworkcalls = (fromTime, toTime, mac) => {
    let response = [];
    let makeNextCall = true;
    let allNetworkCallesAreDone = false
    let fTime = fromTime;
    let tTime = fTime + getFromTime["2 weeks"] <= toTime ? fTime + getFromTime["2 weeks"] : toTime;
    window.networkcallInterval = setInterval(() => {
      if (makeNextCall && !allNetworkCallesAreDone){
        makeNextCall = false;        
        IotServiceClient.getCaribouDataStates(fTime,tTime, mac, "graphPage").then((res) => {
          if (!res.hasOwnProperty("status")){          
            response = _.concat(response, res);
          }
          if (tTime === toTime){
            clearInterval(window.networkcallInterval);
            makeNextCall = false;
            allNetworkCallesAreDone = true;
            window.statesData = response;
            setGraphDataAndStatesData(response);
          }else {
            fTime = tTime;
            tTime = fTime + getFromTime["1 week"] <= toTime ? fTime + getFromTime["1 week"] : toTime;          
            makeNextCall = true;
          }
        });
      }
    }, 1000);
  }

  const setGraphDataAndStatesData = (res) => {
    res = _.reverse(res);
    window.crbStates = res;
    let allAlertsAndEvents = {};
    let allAlerts = {};
    let allEvents = {};
    let allSearchOptions = [];
    let cpuData = [];
    let memoryData = [];        
    let allDates = [];
    let alertsAndEventsCount = 0;
    let maxCpuValue = 0;
    let maxMemoryValue = 0;
    _.forEach(res, (val) => {
        if (val.fromPortal !== "yes"){
          if (val.CP > maxCpuValue) {
            maxCpuValue = val.CP;
          }
          cpuData.push({x: val.TI,y : val.CP, markerSize: 10, color: "rgb(255,165,0)", formattedDataX: new Date(val.TI), formattedDataY: null, OFF: val.OFF})
          if (val.MM > maxMemoryValue) {
            maxMemoryValue = val.MM;
          }
          memoryData.push({x: val.TI,y : val.MM, markerSize: 10, color: "rgb(255,165,0)", formattedDataX: new Date(val.TI),formattedDataY: null, OFF: val.OFF})
        }
        
        if (checkDataExistsOrNot(val.SE)){
          _.forEach(val.SE, (events) => {
            let date = moment(new Date(val.TI)).format("YYYY-MM-DD");   
            let evnt = {"name": events.nme, "time": val.TI, "value": events.val, "value1": events.val2, type:"event", color: events.alrt === 1 ? "red" : "#808080" };
            
            if (!_.includes(allSearchOptions, events.nme)){
              allSearchOptions.push(events.nme);
            }
            
            if (_.has(allAlertsAndEvents, date)){
              allAlertsAndEvents[date].push(evnt);
            }else {
              allAlertsAndEvents[date] = [evnt];  
            }

            if (!_.includes(allDates, date)){
              allDates.push(date);
            }
            alertsAndEventsCount+=1;
          })
        }

        if (checkDataExistsOrNot(val.SA)){
          _.forEach(val.SA, (alerts) => {
            let date = moment(val.TI).format("YYYY-MM-DD");
            let alrt = {"name": alerts.nme, "time": val.TI, "value": alerts.val, type:"alert", color: alerts.alrt === 1 || alerts.val ===  "ERR_HOTSPOT TRIGGER" || alerts.val ===  "Timed-out" || (alerts.nme !== null && typeof alerts.nme === "string" && alerts.nme.indexOf("failed") !== -1) ? "red" : "green"};

            if (!_.includes(allSearchOptions, alerts.nme)){
              allSearchOptions.push(alerts.nme);
            }

            if (_.has(allAlertsAndEvents,date)){
              allAlertsAndEvents[date].push(alrt);
            }else {
              allAlertsAndEvents[date] = [alrt];  
            }
            
            if (!_.includes(allDates, date)){
              allDates.push(date);
            }

            alertsAndEventsCount+=1;
          })
        }
        
      });
    window.maxCpuValue = maxCpuValue;
    window.maxMemoryValue = maxMemoryValue;
    window.maxValue = window.maxCpuValue > window.maxMemoryValue ? window.maxCpuValue : window.maxMemoryValue;
    window.allGraphData = res;
    window.allAlertsAndEvents = allAlertsAndEvents;  
    window.graphSearchOptions = allSearchOptions;
    window.graphActivityLogDates = allDates;
    window.allAlertsAndEventCount = alertsAndEventsCount;
    setAllActivityLogDates(allDates);
    setAlertsCount(alertsAndEventsCount)
    setLogs(allAlertsAndEvents);
    setSearchOptions(allSearchOptions);
    setIsLoading(false);
    let updatedCpuOptions = getCpuOptions();
    updatedCpuOptions["height"] = 205;

    let deviceData = _.reverse(cpuData);
    let greyShadedAreaData = [];
    let searchOffline = true;
    let offlineData = [];
    let index = 0;
    let dataPoints = [];
    _.forEach(deviceData, (data) => {
      if (searchOffline) {
          if (data.OFF === 1) {
              if (!offlineData[index] || !offlineData[index].hasOwnProperty("start")) {
                  offlineData[index] = {};
              }
              offlineData[index].start = data.x;
              searchOffline = !searchOffline;
          }
      } else {
          if (data.OFF === 0) {
              let endTime = moment(data.x).subtract(1, 'seconds').valueOf();
              if (endTime < offlineData[index].start){
                  offlineData[index].end = offlineData[index].start;
              }else{
                  offlineData[index].end = endTime;
              }
              searchOffline = !searchOffline;
              index++;
          }
      }
    })
    if (offlineData[index] && !offlineData[index].hasOwnProperty("end")) {
        let last = deviceData[deviceData.length - 1];
        offlineData[index].end = last.x;
    }

    _.forEach(offlineData, (val) => {
       dataPoints.push({x:val.start,y:[0,window.maxValue > 100 ? window.maxValue : 100]});
       dataPoints.push({x:val.end,y:[0,window.maxValue > 100 ? window.maxValue : 100]});
       greyShadedAreaData.push(dataPoints);
       dataPoints = [];
    });

    updatedCpuOptions["data"] =  [
      {
          visible: true,
          type: "line",
          lineColor: "rgb(255,165,0)",
          lineThickness: 1,
          dataPoints: cpuData,
          toolTipContent: "<div style='height:40px, width:100px'><b>{formattedDataX}</b><br/><b> CPU Usage: </b>{y} %</div>"
      },
      ...greyShadedAreaData.map((data, index) => ({
          type: "rangeArea",
          markerType: "none",
          lineThickness: 0,
          dataPoints: data,
          toolTipContent: null,
          color: "#C0C0C4",
      }))
    ];
     
    let updatedMemoryOptions = getMemoryOptions();
    updatedCpuOptions["height"] = 205;
    updatedMemoryOptions["data"] =  [
      {
        visible: true,
        type: "line",
        lineColor: "rgb(255,165,0)",
        lineThickness: 1,
        dataPoints: memoryData,
        toolTipContent: "<div style='height:40px, width:100px'><b>{formattedDataX}</b><br/><b> Used Memory: </b>{y} MB</div>"
      },
      ...greyShadedAreaData.map((data, index) => ({
        type: "rangeArea",
        markerType: "none",
        lineThickness: 0,
        dataPoints: data,
        toolTipContent: null,
        color: "#C0C0C4",
      }))
    ];
    setMemoryOptions(updatedMemoryOptions);                
    setMemoryUsageData(memoryData);
    setCpuUsageData(cpuData);
    setCpuUsageOptions(updatedCpuOptions); 
    setDataRendered(true);
  }

  const getFromTime = {
    "1 hour"    :   3600000 ,
    "6 hours"   :   21600000,
    "12 hours"  :   43200000,
    "24 hours"  :   86400000,
    "1 week"    :   604800000,
    "2 weeks"   :   1209600000,
    "1 month"   :   2592000000,
    "3 months"   :   7776000000
  }

  const calculateData = (startTime, endTime) => {
    setIsLoading(true);
    setMemoryOptions(getMemoryOptions()); 
    setCpuUsageOptions(getCpuOptions());      
    setDataRendered(false);
    let toTime = endTime ? endTime : new Date().getTime();
    window.toTime = moment(toTime).format("YYYY-MM-DD");
    window.setMaxRangeOfGraph = toTime;
    let fromTime;
    let value = window.setSelectedInterval;
    let mac = props.selectedDevice.MAC;
    if (!checkDataExistsOrNot(startTime)){
      fromTime = toTime - getFromTime[value];
    }else {
      fromTime = startTime;
    }
    window.setMinRangeOfGraph = fromTime;
    if (toTime - fromTime <= getFromTime["2 weeks"]){
      IotServiceClient.getCaribouDataStates(fromTime,toTime, mac, "graphPage").then((res) => {
        if (!res.hasOwnProperty("status")){
          window.statesData = res;
          setGraphDataAndStatesData(res);
        }
      });
    }else{
      makeNetworkcalls(fromTime, toTime, mac);      
    }    
  }

  const filterSystemAlertsData = (text) => {
    try {
      let dates = window.graphActivityLogDates;
      let filteredSystemAlertsData = {};
      let alertsAndEventsCount = 0;
      let allDates = [];
      _.forEach(dates, (date)=>{
        _.forEach(window.allAlertsAndEvents[date], (val) => {
          if (checkDataExistsOrNot(val.name) && val.name.indexOf(text) !== -1){
            if (_.has(filteredSystemAlertsData, date)){
              filteredSystemAlertsData[date].push(val);
              alertsAndEventsCount+=1;
            }else {
              filteredSystemAlertsData[date] = [val];  
            }
            if (!_.includes(allDates, date)){
              allDates.push(date);
              alertsAndEventsCount+=1;
            }
          }          
        })
      });
      setLogs(filteredSystemAlertsData);
      setAllActivityLogDates(allDates);
      setAlertsCount(alertsAndEventsCount);
    } catch (error) {
      console.log("Error is ",error);
    }    
  }


  const handleActivityFeedSearch = (searchedText) => {
    if (checkDataExistsOrNot(searchedText)){
      // Search Filter
      filterSystemAlertsData(searchedText)
    }else {
      setAllActivityLogDates(window.graphActivityLogDates);
      setAlertsCount(window.allAlertsAndEventCount);
      setLogs(window.allAlertsAndEvents);
      setSearchOptions(window.graphSearchOptions);
    }
  }  

  const handleStatesData = (mqttGraphData) => {
    mqttGraphData = JSON.parse(mqttGraphData.toString());
    mqttGraphData = _.reverse(mqttGraphData);
    let currentDate = new Date();
    window.currentDate = moment(currentDate).format("YYYY-MM-DD");
    if (window.toTime === window.currentDate) {
        window.statesData = _.reverse(_.concat(mqttGraphData,window.statesData));
        setGraphDataAndStatesData(window.statesData);
    }
  }

  React.useEffect(() => {
    let windowResolutions = {
      "width": window.innerWidth,
      "height": window.innerHeight
    }
    setDimensions(windowResolutions);
  }, [window.innerWidth, window.innerHeight])

  React.useEffect(() => {
    window.setSelectedInterval = "1 hour";             
    window.startIntervalForApply =  new Date(currentYear + "-" + currentMonth + "-" + currentDate+" 00:00:00").getTime();
    window.endIntervalForApply = new Date().getTime();
    calculateData(null, null);
    setTimeout(() => {
      setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
      setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
    }, 1000);
    window.graphChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GRAPH_CHANNEL.replace("macId",props.selectedDevice.MAC).replace("scopeId",sessionStorage.tenantId),"GraphData",handleStatesData);
    return () => {
        delete window.cpuOptions;
        delete window.graphStartTime;
        delete window.graphEndTime;
        delete window.statesData;
        delete window.toTime;
        delete window.currentDate;
        if (window.networkcallInterval){
          clearInterval(window.networkcallInterval);
        }
        if (window.graphChannel) {
          window.graphChannel.unsubscribe();
        }
    }
  },[])    

  return ( 
    <Dialog
        fullWidth={true}
        fullScreen={true}
        open={true}    
        onClose={()=> props.closeDialog()}                            
        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
        aria-labelledby="draggable-dialog-title"
    >
        <DialogTitle style={{
            background: primaryThemeColor,
            display: 'flex',
            flexDirection: 'row',
            color: 'white',
        }}>
            <Box>{props.selectedDevice.NAME}</Box>
            <Box sx={{marginLeft:"auto",display:"flex",justifyContent:"end",alignItems:"center"}}>
                <CsvDownload
                    data={[]}
                    filename={"Temperature-History.csv"}
                    style={{
                        border: 'transparent',
                        background: 'transparent',
                        visibility: 'hidden',
                        position:'absolute',
                        margin: '0px 0px 0px 4px'
                    }}
                    id="lockSerialNumbersNameCsvData"
                >
                    <Tooltip title="Download CSV" placement="top" arrow>
                        <Download/>
                    </Tooltip>
                </CsvDownload>
                <Close sx={{cursor:"pointer"}} onClick={() => props.closeDialog()}/>
            </Box>
        </DialogTitle>
        <DialogContent sx={{padding:"0px",overflow:"hidden"}}>
            <Box className={styles.gatewayStatusDialogActivityLogs} sx={{display: 'flex',flexDirection: 'row'}}>
                <Box sx={{flex: Dimensions.width <= 1024 ? "100" : "70", display: showAlertsPage ? 'none':'block',height:"92dvh",flexDirection:"column"}}>
                     <Box sx={{display: "flex", flex: "40",flexDirection: 'row',justifyContent:"flex-end",padding:'0px 20px'}}>
                        <Box sx={{display: "flex",flexDirection: 'row',alignItems:"center"}}>
                           {
                              Dimensions.width > 1024 ?
                               <FormControl variant="standard" onClick={() => {}} sx={{width: "150px",background:"#fff",margin: '4px 12px 10px 35px'}}>
                                       <InputLabel id="demo-simple-select-standard-label">Interval:</InputLabel>
                                       <Select open={open} onClose={()=>{handleShowPopperOnClose()}} onClick={()=>{window.setSelectedInterval !== "Custom Range" ? handleCustomRange(): setOpen(true)}} value={selectedInterval} onChange={(event)=>{handleOnChangeEvent(event)}} labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" sx={{width: '100%'}}>
                                          <MenuItem value="1 hour">1 hour</MenuItem>
                                          <MenuItem value="6 hours">6 hours</MenuItem>
                                          <MenuItem value="24 hours">24 hours</MenuItem>
                                          <MenuItem value="1 week">1 week</MenuItem>
                                          <MenuItem value="2 weeks">2 weeks</MenuItem>
                                          <MenuItem value="1 month">1 month</MenuItem>
                                          <MenuItem value="3 months">3 months</MenuItem>
                                          <MenuItem ref={anchorRefCustom} value="Custom Range" onClick={() => {handleClickCustomOption()}}>Custom Range</MenuItem>
                                       </Select>
                               </FormControl>
                           :
                             <Tooltip title="Filter" placement='top' arrow>
                                  <FilterList onClick={(event)=> handleClickFilterIcon(event)} sx={{fontSize:"22px", cursor:"pointer", margin: '18px 10px 10px 4px'}}/>
                             </Tooltip>
                           }
                           <Popper
                              open={showPopper}
                              onClose={() => setShowPopper(false)}
                              anchorEl={anchorRefCustom.current}
                              role={undefined}
                              placement="bottom"
                              sx={{zIndex:"1500",top:"10px !important"}}
                              transition
                           >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                          transformOrigin: 'left bottom',
                                        }}
                                    >
                                      <Paper>
                                              <div
                                                  autoFocusItem={open}
                                                  id="composition-menu"
                                                  aria-labelledby="composition-button"
                                                  style={{width: '200px'}}
                                                  sx={{ padding: "8px"}}
                                              >
                                                 <div className="menuItems">
                                                    <Box sx={{display: 'flex', flexDirection:'column'}}>
                                                       <Box sx={{fontSize:"12px"}}>From:</Box>
                                                       <Box>
                                                            <TextField
                                                                type="date"
                                                                variant="standard"
                                                                value={startInterval}
                                                                sx={{ width: 150 }}
                                                                InputLabelProps={{
                                                                  shrink: true,
                                                                }}
                                                                inputProps={{
                                                                  min: "2024-01-01",
                                                                  max: endInterval
                                                                }}
                                                                onChange={(event)=> handleStartIntervalChange(event)}
                                                            />
                                                       </Box>
                                                    </Box>
                                                 </div>
                                                 <div className="menuItems">
                                                     <Box sx={{display: 'flex', flexDirection:'column'}}>
                                                          <Box sx={{fontSize:"12px"}}>To:</Box>
                                                          <Box>
                                                               <TextField
                                                                     type="date"
                                                                     variant="standard"
                                                                     value={endInterval}
                                                                     sx={{ width: 150 }}
                                                                     InputLabelProps={{
                                                                       shrink: true,
                                                                     }}
                                                                     inputProps={{
                                                                       min: startInterval,
                                                                       max: currentYear + "-" + currentMonth + "-" + currentDate
                                                                     }}
                                                                     onChange={(event)=> handleEndIntervalChange(event)}
                                                               />
                                                          </Box>
                                                     </Box>
                                                 </div>
                                                 <div style={{display:"flex",justifyContent:"center",width:'auto'}}>
                                                    <Button disabled={!showCircularCheck} sx={{marginTop:"8px"}} onClick={() => {handleClickCircularIcon()}}>
                                                        <Tooltip title="Apply Interval" placement="top" arrow>
                                                             <CheckCircle sx={{color: intervalChangeConfirm ? '#109618': ""}}/>
                                                        </Tooltip>
                                                    </Button>
                                                 </div>
                                              </div>
                                      </Paper>
                                    </Grow>
                                )}
                           </Popper>
                           <Menu
                               className="account_popover"
                               anchorEl={anchorEl}
                               open={openMenu}
                               onClose={() => {handleOnClose()}}
                               onChange={(event)=> {handleMenuClose(event)}}
                               onClick={(event)=> {handleOnClck(event)}}
                               PaperProps={{
                                 elevation: 0,
                                 sx: {
                                   overflow: 'visible',
                                   filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                   mt: 1.5,
                                   '& .MuiAvatar-root': {
                                     width: 32,
                                     height: 32,
                                     ml: -0.5,
                                     mr: 1,
                                   },
                                   '&:before': {
                                     content: '""',
                                     display: 'block',
                                     position: 'absolute',
                                     top: 0,
                                     right: 14,
                                     width: 10,
                                     height: 10,
                                     bgcolor: 'background.paper',
                                     transform: 'translateY(-50%) rotate(45deg)',
                                     zIndex: 0,
                                   },
                                 },
                               }}
                               transformOrigin={{horizontal: 'right', vertical: 'top'}}
                               anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                           >
                             <MenuItem value="1 hour">1 hour</MenuItem>
                             <MenuItem value="6 hours">6 hours</MenuItem>
                             <MenuItem value="24 hours">24 hours</MenuItem>
                             <MenuItem value="1 week">1 week</MenuItem>
                             <MenuItem value="2 weeks">2 weeks</MenuItem>
                             <MenuItem value="1 month">1 month</MenuItem>
                             <MenuItem value="3 months">3 months</MenuItem>
                             <MenuItem ref={anchorReffCustom} value="Custom Range" onClick={()=> {handleCustomSelection("Custom Range")}}>Custom Range</MenuItem>
                           </Menu>
                           <Popper
                                 open={showFilterListPopper}
                                 onClose={() => setShowFilterListPopper(false)}
                                 anchorEl={anchorReffCustom.current}
                                 role={undefined}
                                 placement="bottom"
                                 top="10px"
                                 sx={{zIndex:"1500",top:'10px !important'}}
                                 transition
                           >
                               {({TransitionProps, placement}) => (
                                   <Grow
                                       {...TransitionProps}
                                       style={{
                                         transformOrigin: 'left bottom',
                                       }}
                                   >
                                     <Paper>
                                             <div
                                                 autoFocusItem={open}
                                                 id="composition-menu"
                                                 aria-labelledby="composition-button"
                                                 style={{width: '200px',padding: '8px'}}
                                             >
                                                <div className="menuItems">
                                                   <Box sx={{display: 'flex', flexDirection:'column'}}>
                                                      <Box sx={{fontSize:"12px"}}>From:</Box>
                                                      <Box>
                                                           <TextField
                                                               type="date"
                                                               variant="standard"
                                                               value={startInterval}
                                                               sx={{ width: 150 }}
                                                               InputLabelProps={{
                                                                 shrink: true,
                                                               }}
                                                               inputProps={{
                                                                 min: "2024-01-01",
                                                                 max: endInterval
                                                               }}
                                                               onChange={(event)=> handleStartIntervalChange(event)}
                                                           />
                                                      </Box>
                                                   </Box>
                                                </div>
                                                <div className="menuItems">
                                                    <Box sx={{display: 'flex', flexDirection:'column'}}>
                                                         <Box sx={{fontSize:"12px"}}>To:</Box>
                                                         <Box>
                                                              <TextField
                                                                    type="date"
                                                                    variant="standard"
                                                                    value={endInterval}
                                                                    sx={{ width: 150 }}
                                                                    InputLabelProps={{
                                                                      shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                      min: startInterval,
                                                                      max: currentYear + "-" + currentMonth + "-" + currentDate
                                                                    }}
                                                                    onChange={(event)=> handleEndIntervalChange(event)}
                                                              />
                                                         </Box>
                                                    </Box>
                                                </div>
                                                <div style={{display:"flex",justifyContent: 'center',padding:'0px 8px',width:'auto' }}>
                                                   <Button disabled={!showCircularCheckIcon} sx={{marginTop:"8px"}} onClick={() => {handleClickCircularCheckIcon()}}>
                                                       <Tooltip title="Apply Interval" placement="top" arrow>
                                                            <CheckCircle sx={{color: intervalChangeConfirmation ? '#109618': ""}}/>
                                                       </Tooltip>
                                                   </Button>
                                                </div>
                                             </div>
                                     </Paper>
                                   </Grow>
                               )}
                           </Popper>
                           {
                              Dimensions.width <= 1024 ?
                               <Tooltip title="Alerts" placement='top' arrow>
                                  <NotificationImportantOutlined onClick={()=> setShowAlertsPage(true)} sx={{fontSize:"22px", cursor:"pointer", margin: '18px 10px 10px 4px'}} />
                               </Tooltip>
                           :
                             null
                           }
                        </Box>
                     </Box>
                     <Divider />
                    <Box sx={{width:"100%",overflowY:"scroll",overflowX:"hidden",height:"calc(100vh - 140px)"}}>
                        <Box sx={{height: '220px',marginLeft:"30px",marginTop:"15px",position:'relative'}}>
                            {
                              !dataRendered ?
                                <CircularProgress thickness="4" sx={{height:'26px !important',width: '26px !important',color: 'rgb(20,19,139) !important', position: "absolute", top:"35%", left:"50%", zIndex: "100"}}/>
                              :
                                null
                            }
                            <CanvasJSChart id="cpuGraph" options={cpuUsageOptions} ref={cpuChartRef}/>
                            <Box sx={{position:'relative',left:'10px', top:"4px",color:'rgb(255,165,0)',display:"flex"}}>
                                <Box sx={{width:'16px',margin:'7px 8px 0px 0px',height:'3px',background:'rgb(255,165,0)'}}>&nbsp;</Box>
                                <Box>CPU Usage</Box>
                            </Box>
                        </Box>
                        <Box sx={{height: '220px',marginLeft:"30px",marginTop:"15px",position:'relative'}}>
                            {
                              !dataRendered ?
                                <CircularProgress thickness="4" sx={{height:'26px !important',width: '26px !important',color: 'rgb(20,19,139) !important', position: "absolute", top:"35%", left:"50%", zIndex: "100"}}/>
                              :
                                null
                            }
                            <CanvasJSChart options={memoryOptions} ref={memoryChartRef}/>
                            <Box sx={{position:'relative',left:'10px', top:"4px",color:'rgb(255,165,0)',display:"flex"}}>
                                <Box sx={{width:'16px',margin:'7px 8px 0px 0px',height:'3px',background:'rgb(255,165,0)'}}>&nbsp;</Box>
                                <Box>Used Memory</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{flex: Dimensions.width <= 1024 ? "100":"30",display:showAlertsPage ? Dimensions.width <= 1024 ? 'block':'none':  Dimensions.width > 1024 ? 'block':'none',height:"fit-content"}}>
                    <Box sx={{background:primaryThemeColor,height:"98px",background:"rgb(65, 144, 199)"}}>
                        <Box sx={{height:"auto", display:"flex", flexDirection:"column",margin:"1px 2px 2px 2px",background:primaryThemeColor}}>
                           <Box sx={{display:"flex", flexDirection:"row"}}>
                               {
                                  Dimensions.width <= 1024 ?
                                   <Box sx={{display:"flex",alignItems:"start-center",height:"52px", fontSize:"24px", margin:"4px 10px 4px 10px"}}>
                                      <Box sx={{display:"flex",alignItems:"center"}}>
                                         <ArrowBack onClick={()=> setShowAlertsPage(false)} />
                                      </Box>
                                   </Box>
                              :
                                null
                              }
                              <Box sx={{display:"flex",alignItems:"start-center",height:"52px", fontSize:"24px", margin:"4px 0px 4px 6px"}}>
                                 <Box sx={{display:"flex",alignItems:"center"}}>
                                     System Alerts ({alertsCount})
                                 </Box>
                              </Box>
                            <Box>
                            </Box>
                            </Box>
                            <Box className={`${styles.autocompleteDiv} ${'autocompleteblock'}`} sx={{marginLeft:"4px"}}>
                                <Autocomplete
                                freeSolo
                                options={searchOptions.map((option) => option)}
                                renderInput={(params) => <TextField {...params} placeholder="Search ..." sx={{height:'100%'}} />}
                                sx={{height:'100%'}}
                                id="activityFeedSearchField"
                                onChange={(event,value)=> handleActivityFeedSearch(value)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                          height: 'calc(100dvh - 170px)',
                            //   Dimensions.width >= 600
                            //       ? `${Dimensions.height - 98}px`
                            //       : `${Dimensions.height - 170}px`,
                          overflowY: 'auto',
                          width:'100%',
                          display: 'flex',
                          justifyContent:'center'
                        }}

                    >
                      <Box className="listRepeater" sx={{alignItems:'center',justifyContent:'center',display:'flex',height: '98%', borderLeft: "1px solid #808080"}}>
                        {
                            !isLoading ?
                              allActivityLogDates.length > 0 ?
                                    <List
                                          sx={{
                                            width: '100%',
                                            height: '100%',
                                            bgcolor: 'background.paper',
                                            position: 'relative',
                                            overflow: 'auto',
                                            '& ul': { padding: 0 },
                                          }}
                                          subheader={<li />}
                                        >
                                          {
                                            Object.keys(logs).length > 0 ?
                                              Object.keys(logs).map((item,ind) => (
                                                <li key={`section-${ind}`}>
                                                  <ul>
                                                    <ListSubheader>
                                                      <Moment format="ddd MMMM Do, YYYY" >
                                                        {item} 
                                                      </Moment>
                                                    </ListSubheader>
                                                    <AutoSizer disableHeight disableWidth>
                                                        {({width, height}) => (
                                                          <VirtualList
                                                            className="List"                                                      
                                                            rowHeight={Dimensions.width < 400 ? 104 : Dimensions.width < 1080 ? 110 : 90}                                                      
                                                            rowRenderer={({key, index , style, parent })=>{
                                                              const val = logs[item][index];
                                                              return (
                                                                <Box style={style} className="listSubDiv" sx={{display:'flex',flexDirection: 'row',height: Dimensions.width < 400 ? "104px" : Dimensions.width < 1080 ? "110px !important"  : '90px !important'}}>
                                                                  <Box className="subDiv" sx={{height: Dimensions.width < 400 ? "104px" : Dimensions.width < 1080 ? "110px" : '90px',width: val.name === "Gateway Error" ? "40%" : "65%",display:'flex',flexDirection:'column'}}>
                                                                      <Box sx={{height:'50%',color: val.color ,padding:'8px 0px 0px 10px'}}>
                                                                        {moment(val.time).format("h:mm:ss A")}
                                                                      </Box>
                                                                      <Box sx={{height:'50%',color: val.color,padding:'8px 0px 0px 10px'}}>
                                                                        {val.name}
                                                                      </Box>
                                                                  </Box>
                                                                  <Box className="listSubDiv" sx={{height: Dimensions.width < 400 ? "104px" : Dimensions.width < 1080 ? "110px !important": '90px !important',textAlign:"center",width: val.name === "Gateway Error" ? "60%" :'35%',color: val.color, background: '#fff',display: "flex",alignItems:"center",justifyContent:"center",whiteSpace: val.value ? val.value.toString().indexOf("->") !== -1 ? 'pre-line' : null : null}}>
                                                                      {
                                                                        typeof val.value === 'string' && val.value.match("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$") &&  ((Dimensions.width < 1550 && Dimensions.width > 530) || Dimensions.width <= 530) ? 
                                                                            <>
                                                                              {
                                                                                  val.value1 ?
                                                                                    <Tooltip title={val.value1} placement='top' arrow>
                                                                                      <div>
                                                                                        {val.value1}
                                                                                      </div>
                                                                                    </Tooltip>
                                                                                :
                                                                                  <Tooltip title={val.value} placement='top' arrow>
                                                                                      <div>
                                                                                        {val.value.substr(0, 10) + "..."}
                                                                                      </div>
                                                                                  </Tooltip>
                                                                              }
                                                                            </>
                                                                        :
                                                                          typeof val.value === 'string' ?
                                                                            <>
                                                                              {val.value1 ? val.value1 : val.value.replace(" to ", " -> ") }
                                                                            </>
                                                                          :
                                                                            <>
                                                                              {val.value1 ? val.value1 : val.value} 
                                                                            </>
                                                                      }
                                                                  </Box>
                                                                </Box>
                                                              );
                                                            }}
                                                            rowCount={logs[item].length}                                                     
                                                            width={1}
                                                            height={Object.keys(logs).length > 1 ? (Dimensions.width < 1080 && Dimensions.width >= 400 ? logs[item].length * 110 < 336 ? logs[item].length * 110 : 336  : logs[item].length * 90 < 336 ? logs[item].length * 90 : 336) : (Dimensions.width < 1080 && Dimensions.width >= 400 ? logs[item].length * 110  : logs[item].length * 90)}
                                                            containerStyle={{
                                                              width: "100%",  
                                                              maxWidth: "100%"
                                                            }}
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          />  
                                                        )}
                                                    </AutoSizer>        
                                                </ul>
                                              </li>
                                            ))
                                          :
                                            null
                                        }
                                        </List>
                                    :
                                        <Box sx={{display:"flex",flexDirection:"column"}}>
                                            <Box sx={{display:"flex",justifyContent:"center"}}>
                                                <NotificationsActive sx={{color:"rgba(0,0,0,0.27)",fontSize:"48px"}} />
                                            </Box>
                                            <Box>
                                                No System Alerts
                                            </Box>
                                        </Box>
                                :
                                    <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                                        <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                    </Box>
                            }
                      </Box>
                    </Box>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>
    )
}
