import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from '@mui/material';
import AuthClient from 'clients/AuthClient.js';
import IotServiceClient from 'clients/IotServiceClient.js';
import { prominent } from 'color.js';
import MqttEventHandler from "components/Mqtt/MqttEventHandler";
import Permissions from 'components/portalusers/Permissions';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import PaperComponent from 'helper-components/PaperComponent.js';
import _ from "lodash";
import { ColorPicker } from 'material-ui-color';
import { useSnackbar } from 'notistack';
import PubSubChannels from 'pubsub/PubSubChannels';
import * as React from 'react';
import { validate } from 'react-email-validator';
import UserDetailService from 'services/UserDetailService.js';
import "stylesheet/common.css";


const handleSwitchModeLogic = () => {
}

export default function CreateNewCustomerDialog(props) {
    const fileRef = React.useRef(null);
    const favIconRef = React.useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [tenantLabel, setTenantLabel] = React.useState("");
    const [errorForTenantLabel, setErrorForTenantLabel] = React.useState(false);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [superAdminEmail, setSuperAdminEmail] = React.useState("");
    const [themeColor, setThemeColor] = React.useState(props.dialog === "updateCustomerDialog" ? checkDataExistsOrNot(props.data.primaryThemeColor) ? [props.data.primaryThemeColor] : ["#4190c7"] : ["#4190c7"]);
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(props.dialog === "updateCustomerDialog" ? checkDataExistsOrNot(props.data.secondaryThemeColor) ? [props.data.secondaryThemeColor]  : ["#B0BF5D"] : ["#B0BF5D"])
    const [filePath, setFilePath] = React.useState("");
    const [customerId, setCustomerId] = React.useState("");
    const [errorForCustomerId, setErrorForCustomerId] = React.useState(false);
    const [errorForFirstName, setErrorForFirstName] = React.useState(false);
    const [errorForLastName, setErrorForLastName] = React.useState(false);
    const [errorForSuperAdminEmail, setErrorForSuperAdminEmail] = React.useState(false);
    const [isChanged, setIsChanged] = React.useState(false);
    const [isValidToSubmit, setIsValidToSubmit] = React.useState(false);
    const [logoFile, setLogoFile] = React.useState(null);
    const [favIconFile, setFavIconFile] = React.useState(null);
    const [selectedPrimaryThemeColor, setSelectedPrimaryThemeColor] = React.useState(null);
    const [selectedSecondaryThemeColor, setSelectedSecoondaryThemeColor] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [showSnackBarMessage, setShowSnackBarMessage] = React.useState("");
    const [isLoading , setIsLoading] = React.useState(false);
    const [allExistingEmailData, setAllExistingEmailData] = React.useState([]);
    const allTenantSuperAdminPermissions = ["IOT_DEVICE_READ","IOT_DEVICE_WRITE","IOT_DEVICE_BULK_WRITE","PORTAL_USER_READ","PORTAL_USER_WRITE","PORTAL_USER_DELETE","IOT_USER_READ","IOT_USER_WRITE","ALERTS_READ","ALERTS_WRITE","SCOPE_READ","SCOPE_WRITE","SCOPE_UPDATE","SCOPE_DELETE","FIRMWARE_READ","FIRMWARE_DOWNLOAD","CAN_BE_SCOPE_OWNER","GATEWAY_INSTRUCTIONS_READ","GATEWAY_INSTRUCTIONS_WRITE","GATEWAY_READ","GATEWAY_WRITE","GENERATE_GATEWAY_TOKEN","APP_DETAILS","MQTT_AUTH","PORTAL_USER_DETAILS","PORTAL_USER_PASSWORD_CHANGE","HIERARCHY_READ"];
    const [isPrimaryChecked, setIsPrimaryChecked] = React.useState(checkDataExistsOrNot(props.data) ? checkDataExistsOrNot(props.data.primaryThemeColor) : false);
    const [isSecondaryChecked, setIsSecondaryChecked] = React.useState(checkDataExistsOrNot(props.data) ? checkDataExistsOrNot(props.data.secondaryThemeColor) : false);

    const checkAnyValueUpdatedOrNot = () => {       
        if (window.createTenant.firstName !== props.data.firstName || window.createTenant.lastName !== props.data.lastName || window.createTenant.tenantLabel !== props.data.name || window.createTenant.customerId !== props.data.customerId ||
             window.createTenant.logoFile !== props.data.logoUrl || window.createTenant.favIconFile !== props.data.favIconUrl || checkDataExistsOrNot(props.data.primaryThemeColor) ? !window.isPrimaryColorSelected || (window.createTenant.selectedPrimaryThemeColor !== props.data.primaryThemeColor && window.isPrimaryColorSelected) : window.isPrimaryColorSelected || checkDataExistsOrNot(props.data.secondaryThemeColor) ? !window.isSecondaryColorSelected || (window.createTenant.selectedSecondaryThemeColor !== props.data.secondaryThemeColor && window.isSecondaryColorSelected) : window.isSecondaryColorSelected){
            return true;
        }else {
            return false;
        }
    }
    const checkIsValidToSubmitOrNot = () => {
      if (props.dialog === "createCustomerDialog"){
        if (checkDataExistsOrNot(window.createTenant.firstName) && checkDataExistsOrNot(window.createTenant.lastName) && checkDataExistsOrNot(window.createTenant.tenantLabel) && checkDataExistsOrNot(window.createTenant.superAdminEmail) && validate(window.createTenant.superAdminEmail) && checkDataExistsOrNot(window.createTenant.customerId)){
                setIsValidToSubmit(true);
        }else {
            setIsValidToSubmit(false);
        }
      }else {
        if (checkDataExistsOrNot(window.createTenant.firstName) && checkDataExistsOrNot(window.createTenant.lastName) && checkDataExistsOrNot(window.createTenant.tenantLabel) && checkDataExistsOrNot(window.createTenant.customerId)){
          if (checkDataExistsOrNot(window.createTenant.selectedPrimaryThemeColor) || checkDataExistsOrNot(window.createTenant.selectedSecondaryThemeColor)){
            if (props.data.firstName === window.createTenant.firstName && props.data.lastName === window.createTenant.lastName && props.data.name === window.createTenant.tenantLabel &&
             props.data.customerId === window.createTenant.customerId && props.data.primaryThemeColor === window.createTenant.selectedPrimaryThemeColor && props.data.secondaryThemeColor === window.createTenant.selectedSecondaryThemeColor){
                if(checkDataExistsOrNot(window.createTenant.logoFile) || checkDataExistsOrNot(window.createTenant.favIconFile)){
                  setIsValidToSubmit(true);
                }else {
                  setIsValidToSubmit(false);
                }
            }else {
                setIsValidToSubmit(true);
            }
          }else {
            if(props.data.firstName === window.createTenant.firstName && props.data.lastName === window.createTenant.lastName && props.data.name === window.createTenant.tenantLabel &&
             props.data.customerId === window.createTenant.customerId){
                if(checkDataExistsOrNot(window.createTenant.logoFile) || checkDataExistsOrNot(window.createTenant.favIconFile)){
                  setIsValidToSubmit(true);
                }else {
                  setIsValidToSubmit(false);
                }
            }else {
                setIsValidToSubmit(true);
            }
          }
        }else {
           setIsValidToSubmit(false);
        }
      }
    }

    const checkForValidity = (modifiedField, errorForModifiedField) => {
        //TODO:// Need to send props.nuttonName
        if ('create' === 'create') {
          let submitCondition = (modifiedField === "label" ? !errorForModifiedField : (checkDataExistsOrNot(tenantLabel) ? !errorForTenantLabel : false)) && 
          (modifiedField === "firstName" ? !errorForModifiedField : (checkDataExistsOrNot(firstName) ?  !errorForFirstName : false)) && (modifiedField === "lastName" ? !errorForModifiedField : (checkDataExistsOrNot(lastName) ? !errorForLastName : false)) &&
           (modifiedField === "email" ? !errorForModifiedField : (checkDataExistsOrNot(superAdminEmail) ? !errorForSuperAdminEmail: false));
          setIsValidToSubmit(submitCondition)
        } else {
          setIsChanged(true);
          setIsValidToSubmit(!errorForSuperAdminEmail);
        }
    }

    const checkSpace = (name, setField) => {
        if(name === " " || name.indexOf(" ") === 0){
            if(name.length <= 1){
                setField("");
            }else{
                setField( name ? name.trim() : "");
            }
        }
    }

    const checkOnFirstName = (name) => {
        name = checkDataExistsOrNot(name) ? name.trimStart() : name;
        setFirstName(name);
        if (checkDataExistsOrNot(name)) {
          setErrorForFirstName(false);
          checkForValidity("firstName", false);
        } else {
          setErrorForFirstName(true);
          checkForValidity("firstName", true);
        }
        window.createTenant.firstName = name;    
        checkSpace(name, setFirstName);
    };

    const checkOnLastName = (name) => {
        name = checkDataExistsOrNot(name) ? name.trimStart() : name;
        setLastName(name);
        if (checkDataExistsOrNot(name)) {
          setErrorForLastName(false);
          checkForValidity("lastName", false);
        } else {
            setErrorForLastName(true);
          checkForValidity("lastName", true);
        }    
        window.createTenant.lastName = name;
        checkSpace(name, setLastName);
    }

    const handleCustomerLogo = (event) => {
        const customerFile = fileRef.current.files[0];        
        if (customerFile){
            const customerIconReader = new FileReader();
            customerIconReader.addEventListener('load', function () {
                prominent(this.result, {
                    format: 'hex',
                    amount: 4,
                }).then((colors) => {
                    colors = colors.filter(color => color !== '#000000' && color !== '#ffffff')
                    setThemeColor(colors);
                });
                setLogoFile(this.result);
                window.createTenant.logoFile = this.result;
                window.createTenant.logoFileData = event.target.files[0];
                checkIsValidToSubmitOrNot();
            });
            customerIconReader.readAsDataURL(customerFile);
        }else {
            setLogoFile(null);
            window.createTenant.logoFile = null;
            checkIsValidToSubmitOrNot();
        }        
    }

    const handleFavIconImage = (event) => {
        const faviFile = favIconRef.current.files[0];
        if (faviFile){
            const favIconreader = new FileReader();
            favIconreader.addEventListener('load', function () {
                prominent(this.result, {
                    format: 'hex',
                    amount: 4,
                }).then((colors) => {
                    colors = colors.filter(color => color !== '#000000' && color !== '#ffffff')
                    setSecondaryThemeColor(colors);
                });
                setFavIconFile(this.result);   
                window.createTenant.favIconFile = this.result;             
                window.createTenant.favIconFileData = event.target.files[0];
                checkIsValidToSubmitOrNot();
            });
            favIconreader.readAsDataURL(faviFile);
        }else {
            setFavIconFile(null);
            window.createTenant.favIconFile = null;
            checkIsValidToSubmitOrNot();
        }
    }

    const checkOnTenantLabel  = (value) => {
        value = checkDataExistsOrNot(value) ? value.trimStart() : value;
        if (!checkDataExistsOrNot(value)){
            setErrorForTenantLabel(true);
        }else {
            setErrorForTenantLabel(false);
        }
        setTenantLabel(value);
        window.createTenant.tenantLabel = value;
        checkSpace(value, setTenantLabel);
    }

    const checkOnCustomerId = (value) => {
        value = checkDataExistsOrNot(value) ? value.trimStart() : value;
        if (!checkDataExistsOrNot(value)){
            setErrorForCustomerId(true);
        }else {
            setErrorForCustomerId(false);
        }
        setCustomerId(value);
        window.createTenant.customerId = value;
        checkSpace(value, setCustomerId);
    }

    const checkOnSuperAdminEmail = (value) => {
        if (!checkDataExistsOrNot(value) || !validate(value)){
            setErrorForSuperAdminEmail(true);
        }else {
            setErrorForSuperAdminEmail(false);
        }
        setSuperAdminEmail(value);
        window.createTenant.superAdminEmail = value;
        checkSpace(value, setSuperAdminEmail);
    }

    const getEmailDomain = (email) => {
        const domainRegex = /@([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}$/;
        const matchedValue = email.match(domainRegex);
        if (matchedValue && matchedValue.length > 1){
            return matchedValue[1]
        }else {
            return null;
        }
    }

    const createOrUpdateCustomer = () => {
        // TODO :// Need to implement submit and update logic
        let details =  {
            "name" : window.createTenant.tenantLabel,
            "customerId": window.createTenant.customerId,
            "emailDomain": getEmailDomain(window.createTenant.superAdminEmail),
            "parent": JSON.parse(sessionStorage.userDetails).user.scopeId
        }

        if (window.isPrimaryChecked){
            details["primaryThemeColor"] = window.createTenant.selectedPrimaryThemeColor;
        }

        if (window.isSecondaryChecked){
            details["secondaryThemeColor"] = window.createTenant.selectedSecondaryThemeColor;
        }

        // TODO: Need to implement for creating a new user
        //             "fistName" : firstName,
        // "lastName" : lastName,
        // "superAdminEmail" : superAdminEmail,
        if (props.dialog === "updateCustomerDialog"){
            details["id"] = props.data.id;
            AuthClient.updateTenant(details).then((response) => {
                if (response.hasOwnProperty("id")){
                    let payload = {};
                    payload.firstName = window.createTenant.firstName;
                    payload.lastName = window.createTenant.lastName;
                    payload.email = window.createTenant.superAdminEmail;
                    payload.scopeId = response.id;
                    payload.permissions = Object.values(Permissions.userPermissions.superAdminPermissions);
                    payload.owner = "true";
                    if (window.createTenant.logoFileData || window.createTenant.favIconFileData){
                        const formData = new FormData();
                        if(window.createTenant.logoFileData){
                            formData.append('logoFile', window.createTenant.logoFileData);
                        }
                        if(window.createTenant.favIconFileData){
                            formData.append('favIconFile', window.createTenant.favIconFileData);
                        }
                        formData.append("scopeId", response.id);
                        if (window.createTenant.logoFileData && !window.createTenant.favIconFileData){
                            formData.append("includedFiles","logoFile")
                        }else if (!window.createTenant.logoFileData && window.createTenant.favIconFileData){
                            formData.append("includedFiles","favIconFile")
                        }else if (window.createTenant.logoFileData && window.createTenant.favIconFileData){
                            formData.append("includedFiles","both")
                        }                        
                        let url = window.location.origin +"/v1/auth-service/upload-images-files";
                        AuthClient.uploadImageFiles(url, formData, UserDetailService.getAuthToken).then((response)=>{
                            if (response.status >= 200 && response.status < 299){
                                setTimeout(() => {
                                   MqttEventHandler.publish(PubSubChannels.pubsub_channels.SCOPES.replace("scopeId",JSON.parse(sessionStorage.userDetails).user.scopeId),"created",{qos:2, retain:false},function(){})
                                },1000)
                               enqueueSnackbar("Successfully update customer instruction");
                            }else {
                                enqueueSnackbar("Failed to  update customer instruction");
                            }
                        })
                    }else {
                        enqueueSnackbar("Successfully Update customer instruction");
                    }
                    AuthClient.updatePortalUser(payload).then((res) => {
                        setIsLoading(false);
                        setTimeout(() => {
                          setShowSnackBar(false);
                          setIsLoading(false);
                        }, 2001);
                    });
                }else {
                    enqueueSnackbar("Failed to  create customer instruction");
                }
                props.closeDialog(false);
            })
        }else {
            AuthClient.createTenant(details).then((response) => {
                if (response.hasOwnProperty("id")){
                    let portalUser = {};
                    portalUser.firstName = window.createTenant.firstName;
                    portalUser.lastName = window.createTenant.lastName;
                    portalUser.email = checkDataExistsOrNot(window.createTenant.superAdminEmail) ? window.createTenant.superAdminEmail.trim().toLowerCase() : "";
                    portalUser.permissions = Object.values(Permissions.userPermissions.superAdminPermissions);
                    portalUser.scopeId = response.id;
                    portalUser.owner = "true";
                    if (window.createTenant.logoFileData ||  window.createTenant.favIconFileData){
                        const formData = new FormData();
                        if(window.createTenant.logoFileData){
                            formData.append('logoFile', window.createTenant.logoFileData);
                        }
                        if(window.createTenant.favIconFileData){
                            formData.append('favIconFile', window.createTenant.favIconFileData);
                        }
                        formData.append("scopeId", response.id);
                        if (window.createTenant.logoFileData && !window.createTenant.favIconFileData){
                            formData.append("includedFiles","logoFile")
                        }else if (!window.createTenant.logoFileData && window.createTenant.favIconFileData){
                            formData.append("includedFiles","favIconFile")
                        }else if (window.createTenant.logoFileData && window.createTenant.favIconFileData){
                            formData.append("includedFiles","both")
                        }
                        let url = window.location.origin +"/v1/auth-service/upload-images-files";
                        AuthClient.uploadImageFiles(url, formData, UserDetailService.getAuthToken).then((response)=>{
                            if (response.status >= 200 && response.status < 299){
                                enqueueSnackbar("Successfully created customer instruction");
                                props.closeDialog(false)
                            }else {
                                enqueueSnackbar("Failed to  create customer instruction");
                            }
                        })
                    }else {
                        enqueueSnackbar("Successfully created customer instruction");
                        props.closeDialog(false)
                    }

                    //Creating a config data entry for this scope.
                    IotServiceClient.setAgentConfigData(response.id);

                    AuthClient.createPortalUser(portalUser).then((createRes) => {
                        setIsLoading(false);
                        setTimeout(() => {
                          setShowSnackBar(false);
                          setIsLoading(false);
                        }, 2001);
                    })
                }else {
                    enqueueSnackbar("Failed to  create customer instruction");
                }
                props.closeDialog(false);
            })
        }
    }

    const handleManualEditOfColor = (updatedColor, oldColor) => {
        if (updatedColor === oldColor || !checkDataExistsOrNot(updatedColor)){
            //TODO show error
            if (updatedColor === oldColor){
                setSelectedPrimaryThemeColor(updatedColor.hex);
                window.createTenant.selectedPrimaryThemeColor = updatedColor.hex
            }else {
                setSelectedPrimaryThemeColor(null);
                window.createTenant.selectedPrimaryThemeColor = null
            }
        }else {
           let updatedColors =  [];
           _.forEach(themeColor , (value) => {
                if (value === oldColor) {
                    value = "#"+updatedColor.hex;
                    setSelectedPrimaryThemeColor(updatedColor.hex);
                    window.createTenant.selectedPrimaryThemeColor = updatedColor.hex;
                }                
                updatedColors.push(value);
           })
           setThemeColor(updatedColors);
        }
    }

    const handleSecondaryManualEditOfColor = (updatedColor, oldColor) => {
        if (updatedColor === oldColor || !checkDataExistsOrNot(updatedColor)){
            //TODO show error
            if (updatedColor === oldColor){
                setSelectedSecoondaryThemeColor(updatedColor.hex);
                window.createTenant.selectedSecondaryThemeColor = updatedColor.hex;
            }else {
                setSelectedSecoondaryThemeColor(null);
                window.createTenant.selectedSecondaryThemeColor = null
            }
        }else {
           let updatedColors =  [];
           _.forEach(secondaryThemeColor , (value) => {
                if (value === oldColor) {
                    value = "#"+updatedColor.hex;
                    setSelectedSecoondaryThemeColor(updatedColor.hex);
                    window.createTenant.selectedSecondaryThemeColor = updatedColor.hex;
                }
                updatedColors.push(value);
           })
           setSecondaryThemeColor(updatedColors);
        }
    }

    const getAllTenantUsersData = (tenant) => {
        AuthClient.getPortalUsers(tenant.id, "createorUpdateTenantPage").then((portalUserData) => {
            if (!portalUserData.hasOwnProperty("status")){
                _.forEach(portalUserData, (user)=>{
                    if (user.scopeId === props.data.id && user.owner === true){
                        props.data.firstName = user.firstName;
                        props.data.lastName = user.lastName;
                        setFirstName(user.firstName);
                        setLastName(user.lastName);                        
                        setSuperAdminEmail(user.email);
                        setTenantLabel(props.data.name);
                        setCustomerId(props.data.customerId);
                        setSelectedPrimaryThemeColor(props.data.primaryThemeColor);
                        setSelectedSecoondaryThemeColor(props.data.secondaryThemeColor);
                        window.createTenant = {
                            firstName : user.firstName,
                            lastName : user.lastName,
                            tenantLabel : props.data.name,
                            superAdminEmail : user.email,
                            customerId : props.data.customerId,
                            logoFile : null,
                            favIconFile : null,
                            selectedPrimaryThemeColor : props.data.primaryThemeColor,
                            selectedSecondaryThemeColor : props.data.secondaryThemeColor
                        }

                    }
                })
                setAllExistingEmailData(portalUserData);
            }
        });
    }

    const setPrimaryAndSecondaryIfExist = (props) => {
            window.isPrimaryChecked = checkDataExistsOrNot(props.data) ? checkDataExistsOrNot(props.data.primaryThemeColor) : false ;
            window.isSecondaryChecked = checkDataExistsOrNot(props.data) ? checkDataExistsOrNot(props.data.secondaryThemeColor) : false ;
    }

    const handleSelectPrimary = (value, element) => {
        setIsPrimaryChecked(true);
        window.isPrimaryChecked = true;
        window.isPrimaryColorSelected = true;
        handleManualEditOfColor(value, element);
        checkIsValidToSubmitOrNot()
    }

    const handleSelectSecondary = (value, element) => {
        setIsSecondaryChecked(true);
        window.isSecondarySelected = true;
        window.isSecondaryColorSelected = true;
        handleSecondaryManualEditOfColor(value, element);
        checkIsValidToSubmitOrNot()
    }
    

    React.useEffect(() => {
        window.createTenant = {
            firstName : null,
            lastName : null,
            tenantLabel : null,
            superAdminEmail : null,
            customerId : null,
            logoFile : null,
            favIconFile : null,
            selectedPrimaryThemeColor : null,
            selectedSecondaryThemeColor : null
        }
        setPrimaryAndSecondaryIfExist(props);
        
        if (props.dialog === "updateCustomerDialog") {
            window.isPrimaaryColorSelected = false;
            window.isSecondaryColorSelected = false;            
            getAllTenantUsersData(props.data)
        }

        return () => {
            delete window.createTenant;
            delete window.isPrimaryChecked;
            delete window.isSecondaryChecked;
        }
    },[])

    const handleCloseCreateCustomerDialog =() => {
        props.closeDialog(false);
    }

    return (
      <>
       {props.dialog === 'createCustomerDialog' || 'updateCustomerDialog' ? (
         <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={props.dialog === 'createCustomerDialog' ? props.show_create_customer_dialog : props.show_update_dialog}
            onClose={handleCloseCreateCustomerDialog}
            PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle style={{
                background: '#4190c7',
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
            }}>
                <Box sx={{flex: '95'}}>{props.dialog === 'createCustomerDialog' ? 'Create New Customer' : 'Update Customer'}</Box>
                <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={()=>{handleCloseCreateCustomerDialog()}}/>
                </Box>
            </DialogTitle>
            <DialogContent sx={{fontSize:"18px !important",paddingTop:"20px !important"}}>
                <Box className="paddingBottom" sx={{position:"relative",marginBottom:'24px'}}>
                    <TextField
                        disabled={props.buttonName === 'update'}
                        inputProps={{ maxLength: 20 }}
                        label="Customer Label *"
                        variant="standard"
                        sx={{width: '100%'}}
                        value={tenantLabel}
                        error={errorForTenantLabel}
                        helperText={errorForTenantLabel? 'Customer Label is required': ''}
                        onChange={(event) => {
                            checkOnTenantLabel(event.target.value);
                            checkIsValidToSubmitOrNot();
                        }}
                        onBlur={() => checkIsValidToSubmitOrNot()}
                    />
                    <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: checkDataExistsOrNot(tenantLabel) ? "#000000DE" : "red"}}>
                    {checkDataExistsOrNot(tenantLabel) ? tenantLabel.length + "/20" : "0/20"}
                    </Box>
                </Box>
                <Box className="paddingBottom" sx={{position:"relative",marginBottom:'24px'}}>
                    <TextField
                        inputProps={{ maxLength: 20 }}
                        label="Customer ID *"
                        variant="standard"
                        value={customerId}
                        sx={{width: '100%'}}
                        error={errorForCustomerId}
                        helperText={errorForCustomerId? 'Customer ID is required': ''}
                        onChange={(event) => {
                            checkOnCustomerId(event.target.value);
                            checkIsValidToSubmitOrNot();                                        
                        }}
                        onBlur={() => checkIsValidToSubmitOrNot()}
                    />
                    <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: checkDataExistsOrNot(customerId) ? "#000000DE" : "red"}}>
                        {checkDataExistsOrNot(customerId) ? customerId.length + "/20" : "0/20"}
                    </Box>
                </Box>
                <Box className="nameTextField " style={{marginBottom:'24px',marginTop:"-10px"}}>
                    <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                        <TextField
                            inputProps={{ maxLength: 20 }}
                            label="First Name *"
                            variant="standard"
                            sx={{width: '100%'}}
                            value={firstName}
                            error={errorForFirstName}
                            helperText={
                                errorForFirstName
                                ? 'First name is required'
                                : ''
                            }
                            onChange={(event) => {
                                checkOnFirstName(event.target.value);   
                                checkIsValidToSubmitOrNot();                                      
                            }}
                            onBlur={() => checkIsValidToSubmitOrNot()}
                        />
                        <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: checkDataExistsOrNot(firstName) ? "#000000DE" : "red"}}>
                            {checkDataExistsOrNot(firstName) ? firstName.length + "/20" : "0/20"}
                        </Box>
                    </Box>
                    <Box className="firstName paddingBottom" sx={{position:'relative',marginRight:"0px"}}>
                        <TextField
                            inputProps={{ maxLength: 20 }}
                            label="Last Name *"
                            variant="standard"
                            sx={{width: '100%'}}
                            value={lastName}
                            error={errorForLastName}
                            helperText={
                                errorForLastName
                                ? 'Last name is required'
                                : ''
                            }
                            onChange={(event) => {
                                checkOnLastName(event.target.value);
                                checkIsValidToSubmitOrNot();                                          
                            }}
                            onBlur={() => checkIsValidToSubmitOrNot()}
                        />
                        <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: checkDataExistsOrNot(lastName) ? "#000000DE" : "red"}}>
                            {checkDataExistsOrNot(lastName) ? lastName.length + "/20" : "0/20"}
                        </Box>
                    </Box>                    
                </Box>
                <Box className="paddingBottom" sx={{position:"relative",marginBottom:'24px'}}>
                    <TextField
                        disabled={props.dialog === 'updateCustomerDialog' && checkDataExistsOrNot(superAdminEmail) }
                        inputProps={{ maxLength: 40 }}
                        label="Super Admin Email *"
                        variant="standard"
                        sx={{width: '100%'}}
                        value={superAdminEmail}
                        error={errorForSuperAdminEmail}
                        helperText={errorForSuperAdminEmail? 'Super Admin is required': ''}
                        onChange={(event) => {
                            checkOnSuperAdminEmail(event.target.value);
                            checkIsValidToSubmitOrNot();                                        
                        }}
                        onBlur={() => checkIsValidToSubmitOrNot()}
                    />
                    <Box sx={{position:"absolute",top:'54px',right:"0px",fontSize:"12px",color: checkDataExistsOrNot(superAdminEmail) ? "#000000DE" : "red"}}>
                        {checkDataExistsOrNot(superAdminEmail) ? superAdminEmail.length + "/40" : "0/40"}
                    </Box>
                </Box> 
                <Box className="nameTextField " style={{marginBottom:'24px',marginTop:"30px"}}>
                    <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                        <Button
                            component="label"
                            variant="outlined"
                            sx={{
                                '&.MuiButtonBase-root:hover': {
                                    background: '#4190c7',
                                },
                                width: '100%',color: "#fff",background: "#4190c7"
                            }}
                        >
                            Upload Logo
                            <input type="file" accept="image/*" ref={fileRef} hidden onChange={handleCustomerLogo} onBlur={() => checkIsValidToSubmitOrNot()} />
                        </Button>
                    </Box>
                    <Box className="firstName paddingBottom" sx={{position:'relative',marginRight:"0px"}}>
                        <Button
                            component="label"
                            variant="outlined"
                            sx={{
                                '&.MuiButtonBase-root:hover': {
                                    background: '#4190c7',
                                },
                                width: '100%',color: "#fff",background: "#4190c7"
                            }}
                        >
                            Upload Favicon
                            <input type="file" accept="image/*" ref={favIconRef} hidden onChange={handleFavIconImage} onBlur={() => checkIsValidToSubmitOrNot()} />
                        </Button>
                    </Box>                    
                </Box>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                {
                    themeColor && themeColor.length > 0 ?
                        <FormControl className="nameTextField " style={{marginBottom:'24px',marginTop:"10px"}}>
                            <FormLabel id="demo-radio-buttons-group-labels">Select Primary Theme Color</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-labels"
                                defaultValue={themeColor[0]}
                                name="radio-buttons-group"
                                sx={{marginTop:"12px", width:"30%"}}
                                value={selectedPrimaryThemeColor}
                                onChange={(event)=> {
                                    setSelectedPrimaryThemeColor(event.target.value);
                                    window.createTenant.selectedPrimaryThemeColor = event.target.value;
                                    window.isPrimaryColorSelected = true;
                                    window.isPrimaryChecked = true;
                                    checkIsValidToSubmitOrNot();
                                }}
                                onBlur={() => checkIsValidToSubmitOrNot()}
                            >
                                {
                                    themeColor.map((element,index) => {
                                        return (                                
                                            <FormControlLabel value={element} control={<Radio />} label={<ColorPicker value={element} disableTextfield={true} onChange={(value) => handleSelectPrimary(value, element) }/>} />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </FormControl>                        
                    :
                        null
                }
                {
                    secondaryThemeColor && secondaryThemeColor.length > 0 ?
                        <FormControl className="nameTextField " style={{marginBottom:'24px',marginTop:"10px"}}>
                            <FormLabel id="demo-radio-buttons-group-label">Select Secondary Theme Color</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedSecondaryThemeColor}
                                name="radio-buttons-group"
                                sx={{marginTop:"12px", width:"30%"}}
                                onChange={(event)=> {
                                    setSelectedSecoondaryThemeColor(event.target.value);
                                    window.createTenant.selectedSecondaryThemeColor = event.target.value;
                                    window.isSecondaryColorSelected = true;
                                    window.isSecondaryChecked = true;
                                    checkIsValidToSubmitOrNot();
                                }}
                                onBlur={() => checkIsValidToSubmitOrNot()}
                            >
                                {
                                    secondaryThemeColor.map((element,index) => {
                                        return (                                
                                            <FormControlLabel value={element} control={<Radio />} label={<ColorPicker value={element} disableTextfield={true} onChange={(value) => { handleSelectSecondary(value, element) } }/>} />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </FormControl>                        
                    :
                        null
                }
                </Box>
            </DialogContent>         
            <DialogActions>
                <>
                    <Button onClick={() => {handleCloseCreateCustomerDialog()}}>
                        cancel
                    </Button>                                  
                    <Button disabled={!isValidToSubmit} onClick={() => createOrUpdateCustomer()}>
                        {props.dialog === 'createCustomerDialog' ? "create" : "Update"}
                    </Button>
                </>
            </DialogActions>
        </Dialog>
       ) : null }
      </>
    )
}
