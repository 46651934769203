import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import IotServiceClient from 'clients/IotServiceClient';
import GenericChannel from 'helper-components/GenericChannel.js';
import PaperComponent from 'helper-components/PaperComponent';
import utils from 'helper-components/utils.js';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import "stylesheet/common.css";


export default function RebootDevice(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const { enqueueSnackbar } = useSnackbar();
    let {publish, deleteInstruction} = GenericChannel();

    const rebootDevice = (deviceData) => {
        publish(deviceData, "reboot", null, utils.getUniqueKey());
        let portalEventsPayload = {
            "MAC" : deviceData.MAC,
            "TI": new Date().getTime(),
            "SE" : [{
                "nme": "Reboot Initiated",
                "val": JSON.parse(sessionStorage.userDetails).user.email
            }]    
        }
        IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
        props.closeDialog();
    }

    React.useEffect(() => {
        return () => {
        }

    },[])

    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.openDialog}
                onClose={() => props.closeDialog()}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    background: '#4190c7',
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'white',
                }}>
                    <Box sx={{flex: '95'}}>Rebooting Device</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{padding:"5px !important", display:"flex", justifyContent:"center", marginTop:"15px"}}>
                    <Box>
                        Are you sure you want to reboot the device.
                    </Box>
                </DialogContent>
                <DialogActions >
                    <>
                        <Button color="error" onClick={() => props.closeDialog()}>Cancel</Button>
                        <Button onClick={() => rebootDevice(props.rebootDeviceData) }>Ok</Button>
                    </>
                </DialogActions>
            </Dialog>
        </>
    )
}
