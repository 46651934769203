
import { Box, CircularProgress, Grid, Paper, Snackbar, Typography } from '@mui/material';
import IotServiceClient from "clients/IotServiceClient";
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import LocationMap from 'helper-components/LocationMap';
import _ from 'lodash';
import { useSnackbar } from "notistack";
import PubSubChannels from 'pubsub/PubSubChannels';
import * as React from 'react';
import Chart from 'react-google-charts';
import 'stylesheet/Dashboard.css';



export default function Dashboard(props) {
    const [tenantName, setTenantName] = React.useState(null);
    const [iotDevices, setIotDevices] = React.useState([]);
    const [iotGateways, setIotGateways] = React.useState([]);
    const [clickEventCount, setClickEventCount] = React.useState(0);
    const [dashboardGatewaysEventHandlerChannel, setDashboardGatewaysEventHandlerChannel] = React.useState(null);
    const [lockStatusEventHandlerChannel, setLockStatusEventHandlerChannel] = React.useState(null);
    const [onlineCount, setOnlineCount] = React.useState(0);
    const [offlineCount, setOfflineCount] = React.useState(0);
    const [lteCount, setLteCount] = React.useState(0);
    const [ethernetCount, setEthernetCount] = React.useState(0);
    const [ wifiCount, setWifiCount] = React.useState(0);
    const [registeredCount, setRegisteredCount] = React.useState(0);
    const [unregisteredCount, setUnregisteredCount] = React.useState(0);
    const [firmwareData, setFirmwareData] = React.useState([["Version","FM"],["",0]]);
    const [osVersionData, setOsVersionData] = React.useState(["Version","OS"],["", 0]);
    const [scopeId,setScopeId] = React.useState(sessionStorage.tenantId);
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    window.dashboardIotGateways = [];
    const [defaultCenter, setDefaultCenter] = React.useState({lat: -34.397,lng: 150.644})
    const [LCTNs, setLCTNs]= React.useState([])
    const [deviceLCTNs, setDeviceLCTNs] = React.useState([]);
    const mapStyles = {
        height: '240px',
        width: '100%',
    };
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const { enqueueSnackbar } = useSnackbar();

    const [showMap , setShowMap] = React.useState(false);
 
     const options = {
         legend: {
            position: 'top',
            maxLines: 3,
            textStyle: {fontSize: 9},
         },
         chart: {
           title: '',
           subtitle: '',
         },
     };

     const getDevicesDataByVersions = (devices) => {
           _.forEach(devices,(device) => {
               if(device.FW !== null && device.FW !== undefined) {
                   let firmwareVersion = device.FW;
                   device.versionNum = firmwareVersion.substring(0, 10);
               }
           });
           var data = _.groupBy(devices, function(device) {
               return device.versionNum;
           });
           return data;
     };
 
    const getDevicesDataByOSVersions = (devices) => {
         var data = _.groupBy(devices, function(device) {
             if(device.OSversion !== null && device.OSversion !== undefined) {
               return device.OSversion;
             }
         });
         return data;
    }
 
     const fetchData = () => { 
        let allLockTypeExist = [];
        let devicesLCTN = [];
        let allOSVersions = {};
        let allFirmwares = {};
        let filteredFirmwares = [["Version","FM"]];
        let filteredOsVersions = [["Version","OS"]]
        let LCTNData = [];

        IotServiceClient.getReachableIotDevices(scopeId, "dashboardPage").then((data) => {
          let [onlineCounter, offlineCounter,lteCounter, ethernetCounter, wifiCounter, registerCount, unRegisterCount] = [0, 0, 0, 0, 0, 0,0];
          if (!data.hasOwnProperty("message")){                               
            _.forEach(data,function(iotDevice, index){
              if (iotDevice.type){
                  allLockTypeExist.push(iotDevice.type);
              }

              if (checkDataExistsOrNot(iotDevice.LCTN) ? checkDataExistsOrNot(iotDevice.LCTN.LAT) && checkDataExistsOrNot(iotDevice.LCTN.LNG) : false){
                  if(devicesLCTN.hasOwnProperty(iotDevice.LCTN.LAT)){
                    devicesLCTN[iotDevice.LCTN.LAT].MAC += ", " + iotDevice.MAC;
                    devicesLCTN[iotDevice.LCTN.LAT].count += 1;
                  }else{
                    devicesLCTN[iotDevice.LCTN.LAT] = {
                      count: 1,
                      NAME: iotDevice.NAME,
                      LAT: iotDevice.LCTN.LAT,
                      LNG: iotDevice.LCTN.LNG,
                      IPADDR: iotDevice.LCTN.IPADDR,
                      MAC: iotDevice.MAC,
                      CS: iotDevice.CS
                    };
                  }
              }

              if (iotDevice.CS === 1) {
                onlineCounter += 1;
              } else {
                offlineCounter += 1;
              }           
              if (iotDevice.REG === 1){
                registerCount++;
              }else{
                unRegisterCount++;
              }
              if(checkDataExistsOrNot(iotDevice.NW)){
                if(iotDevice.NW === 0){
                    lteCounter+=1;
                }else if(iotDevice.NW === 2){
                  ethernetCounter+=1;
                }else{
                  wifiCounter+=1;
                }
              }
              if (checkDataExistsOrNot(iotDevice.FW)){
                if (_.has(allFirmwares, iotDevice.FW)){
                  allFirmwares[iotDevice.FW]+=1;
                }else {
                  allFirmwares[iotDevice.FW] = 1;
                }
              }

              if (checkDataExistsOrNot(iotDevice.OS)){
                if (_.has(allOSVersions, iotDevice.OS)){
                  allOSVersions[iotDevice.OS]+=1;
                }else {
                  allOSVersions[iotDevice.OS] = 1;
                }
              }
            });
              
            if (_.size(allFirmwares) > 0){
              _.forOwn(allFirmwares, (value, key) => {
                filteredFirmwares.push([key, value]);
              }); 
            }else {
              filteredFirmwares.push(["",0]);
            }
            
            if (_.size(allOSVersions) > 0){
              _.forOwn(allOSVersions, (value, key) => {
                filteredOsVersions.push([key, value]);
              }); 
            }else {
              filteredOsVersions.push(["",0]);
            }
            Object.keys(devicesLCTN).map((key) => {
               if(devicesLCTN[key].hasOwnProperty('LAT') && devicesLCTN[key].hasOwnProperty('LNG')){
                LCTNData.push({"lat":devicesLCTN[key].LAT,"lng":devicesLCTN[key].LNG,"macId":devicesLCTN[key].MAC,"connStatus":devicesLCTN[key].CS,"count":devicesLCTN[key].count})
              }
            })

            setRegisteredCount(registerCount);
            setUnregisteredCount(unRegisterCount);
            setDeviceLCTNs(LCTNData);
            setOnlineCount(onlineCounter);
            setOfflineCount(offlineCounter);
            setLteCount(lteCounter);
            setEthernetCount(ethernetCounter);
            setWifiCount(wifiCounter);
            setFirmwareData(filteredFirmwares);
            setOsVersionData(filteredOsVersions);
        }else {
          setRegisteredCount(registerCount);
          setUnregisteredCount(unRegisterCount);
          setDeviceLCTNs(LCTNData);
          setOnlineCount(onlineCounter);
          setOfflineCount(offlineCounter);
          setLteCount(lteCounter);
          setEthernetCount(ethernetCounter);
          setWifiCount(wifiCounter);
          setFirmwareData(filteredFirmwares);
          setOsVersionData(filteredOsVersions);
        }
      });
    }
 
    const handleGatewayEventReceived = () => {
        enqueueSnackbar("Refreshing dashboard page...");
        //fetchData();
    }
 
    const handleLockEventReceived = () => {
          setShowSnackBar(true);
          setSnackBarMessage("Refreshing Gateways Status ...");
 
            setTimeout(() => {
                    setShowSnackBar(false);
                    setSnackBarMessage("");
            }, 3000);
         //fetchData();
    }
 
     const handleOnlineStatusEventReceived = () => {
           setShowSnackBar(true);
           setSnackBarMessage("Refreshing Online Status ...");
 
           setTimeout(() => {
                     setShowSnackBar(false);
                     setSnackBarMessage("");
           }, 3000);
           //fetchData();
     }
 
    const subscribeChannels = () => {
         const fetchOnlineStatus = () => {
             handleOnlineStatusEventReceived();
         }
         window.dashboardGatewaysEventHandlerChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DEVICES.replace("scopeId", sessionStorage.tenantId), "dashboardController", handleGatewayEventReceived);
    }
 
    React.useEffect(()=>{
      //fetchData();
          subscribeChannels();
          setTimeout(() => {
            setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
            setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
          }, 1000);
         return () => {
                 if (window.dashboardGatewaysEventHandlerChannel){
                     window.dashboardGatewaysEventHandlerChannel.unsubscribe();
                 }
                 if (window.lockStatusEventHandlerChannel){
                     window.lockStatusEventHandlerChannel.unsubscribe();
                 }
                 if (window.lockOnlineStatusEventHandlerChannel){
                     window.lockOnlineStatusEventHandlerChannel.unsubscribe();
                 }
         }
    },[]);
 
   return (
 
    <Box sx={{flexGrow: 1, margin: "-3px 0px 0px 6px", padding: '8px',width:"100%", height:"calc(100vh - 188px)", width:"calc(100% - 10px)"}}>
      <iframe src="https://generic-iot.cariboutech.com:9010/grafana/d/fdy696szjuwowe/co2-concentration?orgId=1&from=1723295751546&to=1723437857932" width="100%" height="100%" style={{borderWidth:"0px",marginLeft:"-10px"}}></iframe>
    </Box>
   );
}

