import appLogo from 'caribou_logo.png';
import React from 'react';
import 'stylesheet/header.css';

export default function LogoHeader() {  
  return (
    <nav style={{width: null, color: '#0000008A'}}>
      <div className="div-header" sx={{marginLeft:"20px"}}>
        <img
          className="imgSize logo-img"
          style={{
            padding: '4px',
            marginLeft: '4px',
            marginRight:"auto",
            cursor:"auto"
          }}
          src={appLogo}
          alt="logo"            
        />          
      </div>
    </nav>
  );
};