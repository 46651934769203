import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import AuthClient from 'clients/AuthClient';
import PaperComponent from 'helper-components/PaperComponent';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import UserDetailService from 'services/UserDetailService';

const ApplyOsPatchDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = React.useState(false);
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [customers, setCustomers] = React.useState([]);
    const [customersNames, setCustomersNames] = React.useState("");
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [versions, setVersions] = React.useState("");
    const [performedApply, setPerformedApply] = React.useState(false);
    const [performedUpload, setPerformedUpload] = React.useState(false);
    const [performedDelete, setPerformedDelete] = React.useState(false);

    React.useEffect(() => {
      let selectedCustomerNames = "";
      let selectedOsVersions = "";

      if (props.dialog === "applyOsVersionDialog") {
        if (props.selectedCustomers) {
          _.forEach(props.selectedCustomers, (customer) => {
            selectedCustomerNames = selectedCustomerNames === "" ? customer : selectedCustomerNames + ", " + customer;
          });
          selectedCustomerNames = props.selectedCustomers.length > 1 ? selectedCustomerNames + " Customers" : selectedCustomerNames + " Customer";
          setCustomersNames(selectedCustomerNames);
        }
      }
      if (props.dialog === "deleteOsPatchDialog") {
        if (props.selectedVersion) {
          _.forEach(props.selectedVersion, (version) => {
            selectedOsVersions = selectedOsVersions === "" ? version : selectedOsVersions + ", " + version;
          });
          setVersions(selectedOsVersions);
        }
      }

      setCustomers(props.allCustomers);
      setSelectedCustomer(props.selectedCustomers);
    },[]);

    const handleCloseApplyDialog = () => {
      props.closeDialog();
    }

    const applyOsVersion = () => {
      validateAppliedVersionsOnCustomers(...props.selectedVersion,"osVersion","osVersionId",props.allVersions);
    }

    const validateAppliedVersionsOnCustomers = (selectedVersion, appliedName, appliedVersionId, allVersionsData) => {
      if (!performedApply) {
        let selectedCustomerHasNewVersion = false;
        let selectedCustomersId = [];
        let selectedFileStoreId = "";
        _.forEach(allVersionsData,(version) => {
          if(version.version === selectedVersion) {
            selectedFileStoreId = version.id;
          }
        })
        _.forEach(customers,(customer) => {
          _.forEach(selectedCustomer,(selectedCustomr) => {
            if (customer.name === selectedCustomr) {
              selectedCustomersId.push(customer.id);
              _.forEach(customer.versions,(version) => {
                if (selectedVersion <= version.version) {
                  selectedCustomerHasNewVersion = true;
                }
              })
            }
          })
        })
        if (selectedCustomerHasNewVersion) {
          setPerformedApply(true);
          props.closeDialog();
          enqueueSnackbar("You cannot downgrade or apply the same OS version as the latest.");
        }else {
          setPerformedApply(true);
          let osVersionAppliedCount = 0;
          let networkCallsResponseReceived = 0;
          let osFailedCount = 0;
          let selectedCustmers = Object.assign([],selectedCustomer);
          let selectedCustomerIndex = 0;
          _.forEach(selectedCustomersId,(id)=>{
            setTimeout(() => {
              let json = {
                "scopes": [{
                  [id] : []
                }],
                "fileStoreId": selectedFileStoreId,
                "type": "Partial",
              }
              AuthClient.updateOs(json,selectedFileStoreId,id).then((response) => {
                props.closeDialog();
                networkCallsResponseReceived+=1;
                if (response.status === 200){
                  osVersionAppliedCount+=1;
                }else {
                  osFailedCount +=1;
                }
              }).catch((error)=>{
                networkCallsResponseReceived+=1;
                osFailedCount+=1;
              })
            }, 1000 + selectedCustomerIndex);
            selectedCustomerIndex += 500;
            let toastMessageInterval = setInterval(() => {
              if (networkCallsResponseReceived === selectedCustmers.length && selectedCustmers.length > 1){
                if (osFailedCount === 0 && osVersionAppliedCount > 0){
                  let tenantorTenants = osVersionAppliedCount > 0 ? " tenants" : " tenant" ;
                  enqueueSnackbar("OS Patch applied for "+osVersionAppliedCount + tenantorTenants);
                  clearInterval(toastMessageInterval);
                }else {
                  let tenantorTenants = osVersionAppliedCount > 0 ? " tenants" : " tenant" ;
                  let tenantsOrTenants = osFailedCount > 0 ? " tenants" : " tenant" ;
                  enqueueSnackbar("OS Patch applied for "+osVersionAppliedCount + tenantorTenants + " and failed to apply for "+ osFailedCount + tenantsOrTenants);
                  clearInterval(toastMessageInterval);
                }
                window.uploadOsResponseSuccess = true;
                props.osUpdateSuccess(true);
              }else if (networkCallsResponseReceived === selectedCustmers.length && selectedCustmers.length  === 1){
                if (osFailedCount === 0 && osVersionAppliedCount > 0){
                  let tenantorTenants = osVersionAppliedCount > 0 ? " tenants" : " tenant" ;
                  enqueueSnackbar("Successfully applied OS Patch for "+ selectedCustmers[0]);
                  clearInterval(toastMessageInterval);
                }else {
                  let tenantorTenants = osVersionAppliedCount > 0 ? " tenants" : " tenant" ;
                  let tenantsOrTenants = osFailedCount > 0 ? " tenants" : " tenant" ;
                  enqueueSnackbar("Failed to apply OS Patch for "+ selectedCustmers[0]);
                  clearInterval(toastMessageInterval);
                }
                window.uploadOsResponseSuccess = true;
                props.osUpdateSuccess(true);
              }
            }, 1000);
          })
        }
      }
    }

    const uploadOsPatch = () => {
      if (!performedUpload) {
        let type = "Partial";
        let name = "GenericOSPatch";
        let version = props.version;
        let uploadedFileName = props.file.name;
        let alreadyHasVersion = false;

        _.forEach(customers,(customer) => {
          _.forEach(selectedCustomer,(customerName) => {
            if (customer.name === customerName) {
              _.forEach(customer.versions,(versionn) => {
                if (version <= versionn.version) {
                  alreadyHasVersion = true;
                }
              });
            }
          });
        });

        if (alreadyHasVersion) {
          props.closeDialog();
          enqueueSnackbar("You cannot downgrade or apply the same OS version as the latest");
          setPerformedUpload(true);
        } else {
          setPerformedUpload(true);
          let scopeIds = [];
          _.forEach(selectedCustomer,(customerName) => {
            for (let index =0; index < customers.length ; index++) {
              if (customers[index].name === customerName) {
                let customerScopeId = customers[index].id;
                scopeIds.push({[customerScopeId] : []});
              }
            }
          })
          scopeIds = _.uniq(scopeIds);

          let info = {
            "name": name,
            "fileName": uploadedFileName,
            "type": type,
            "version": version,
            "scopes": scopeIds,
            "description": props.description
          }
          const formData = new FormData();
          formData.append('upload', props.file);
          formData.append('info', JSON.stringify(info));
          let url = window.location.origin +"/v1/auth-service/partial-os-file";
          AuthClient.uploadFirmware(url, formData, UserDetailService.getAuthToken).then((response) => {
            props.closeDialog();
            if (!response.hasOwnProperty("status")) {
              props.osUpdateSuccess(true);
              if (scopeIds.length > 0) {
                let responseScopes = response.scopes ? response.scopes : [];
                let successfullyUpdatedScopes = 0;
                _.forEach(scopeIds,(id)=>{
                  let scopeFound = responseScopes.indexOf(id);
                  if (scopeFound > -1){
                    successfullyUpdatedScopes +=1;
                  }
                })

                if (scopeIds.length === 1){
                  enqueueSnackbar("OS Patch updated successfully for "+ selectedCustomer[0]);
                }else if (scopeIds > 1) {
                  enqueueSnackbar("OS Patch updated successfully for "+ successfullyUpdatedScopes + " customers");
                }
              } else {
                enqueueSnackbar("OS Patch Uploaded Successfully");
              }
            } else {
              props.osUpdateSuccess(false);
              scopeIds = scopeIds ? scopeIds : [];
              if (scopeIds.length > 0) {
                if (scopeIds.length === 1) {
                  enqueueSnackbar("Failed to apply OS Patch for " + selectedCustomer[0]);
                }else if (scopeIds.length > 1) {
                  enqueueSnackbar("Failed to apply OS Patch for " + scopeIds.length + " customers");
                }
              } else {
                enqueueSnackbar("Failed to Upload OS Patch");
              }
            }
          })
        }
      }
    }

    const deleteOsPatch = () => {
      if (!performedDelete) {
        setPerformedDelete(true);
        let json ={};
        json.version = props.selectedVersion;

        AuthClient.deleteOSVersions(json).then((response) => {
          props.closeDialog();
          if (response.status >= 200 && response.status < 300) {
            props.osUpdateSuccess(true);
            if (props.selectedVersion.length === 1) {
              enqueueSnackbar("Successfully Deleted OS Patch");
            } else {
              enqueueSnackbar("Successfully Deleted selected OS Patches");
            }
          } else {
            props.osUpdateSuccess(false);
            if (props.selectedVersion.length === 1) {
              enqueueSnackbar("Failed to Delete OS Patch");
            } else {
              enqueueSnackbar("Failed to Delete OS Patch versions");
            }
          }
        });
      }
    }

    return (
        <>
            {
              showSnackBar ?
                <Snackbar
                  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                  open={showSnackBar}
                  message={snackBarMessage}
                />
              :
                null
            }
            <div>
              {props.dialog === "applyOsVersionDialog" || props.dialog === 'uploadOsDialog' || props.dialog === "deleteOsPatchDialog" ? (
                <Dialog
                  fullWidth={true}
                  fullScreen={fullScreen}
                  open={props.dialog === 'applyOsVersionDialog' ? props.show_apply_os_dialog : props.dialog === 'uploadOsDialog' ? props.show_upload_dialog : props.show_delete_dialog }
                  onClose={() => handleCloseApplyDialog()}
                  PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle style={{color: 'white', display:"flex", flexDirection:"row", background:"#4190c7"}}>
                    <Box sx={{flex:"95"}}>
                      {props.title}
                    </Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                      <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
                    </Box>
                  </DialogTitle>
                  <DialogContent style={{padding:"20px"}}>
                    {
                      isLoading ?
                        <Box sx={{height:"40px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                          <CircularProgress thickness="3.8" size="30px" > </CircularProgress>
                        </Box>
                      :
                        props.dialog === "applyOsVersionDialog" ?
                          <DialogContentText style={{color: 'black'}}>
                            Are you sure, you want to Apply <b>{props.selectedVersion}</b> to {customersNames} ?
                          </DialogContentText>
                        :
                          props.dialog === "uploadOsDialog" ?
                            <DialogContentText style={{color: 'black'}}>
                              Are you sure, you want to Upload <b>{props.version}</b> ?
                            </DialogContentText>
                          :
                            <DialogContentText style={{color: 'black'}}>
                              You will not be able to undo this operation. Do you still want to delete <b>{versions}</b> ?
                            </DialogContentText>
                    }
                  </DialogContent>
                  <DialogActions>
                    {
                      !isLoading ?
                        <>
                          <Button style={{color: 'red'}} onClick={() => props.closeDialog()}>
                            Cancel
                          </Button>
                          { props.dialog === "applyOsVersionDialog" ?
                             <Button onClick={() => {applyOsVersion()}}>Yes</Button>
                           :
                             props.dialog === "uploadOsDialog" ?
                               <Button onClick={() => {uploadOsPatch()}}>Yes</Button>
                             :
                               <Button onClick={() => {deleteOsPatch()}}>Yes</Button>
                          }
                        </>
                      :
                        null
                    }
                  </DialogActions>
                </Dialog>
              ) : null}
            </div>
        </>
    );
};

export default ApplyOsPatchDialog;
