import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import IotServiceClient from 'clients/IotServiceClient';
import MqttEventHandler from "components/Mqtt/MqttEventHandler";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import GenericChannel from "helper-components/GenericChannel";
import PaperComponent from "helper-components/PaperComponent";
import utils from 'helper-components/utils';
import { useSnackbar } from 'notistack';
import PubSubChannels from "pubsub/PubSubChannels";
import * as React from 'react';
import "stylesheet/common.css";
import AuthClient from 'clients/AuthClient';


export default function DiagnosticInfo(props) {
    const theme = useTheme();
    let uniqueKey = null;
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = React.useState(true);
    const [deviceData, setDeviceData] = React.useState({});
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const [showErrorText, setShowErrorText] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    let {publish, updateStatusToFailed, deleteInstruction} = GenericChannel();
    let diagnosticInfoRequestTimer = null;

    const subScribeMqttChannel= (device) => {
        uniqueKey = utils.getUniqueKey();
        window.showDiagnosticInfoData = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DIAGNOSTIC_RESPONSE.replace("macId",device.MAC) + "/" + uniqueKey, "device", handleDiagnosticResponse);
        let portalEventsPayload = {
            "MAC" : device.MAC,
            "TI": new Date().getTime(),            
            "SE" : [{
                "nme": "Diagnostic Data Accessed",
                "val": JSON.parse(sessionStorage.userDetails).user.email
            }]    
        }
        IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
        publish(device, "diagnostic", null, uniqueKey);
        startTimer(15*1000);
    }

    const handleDiagnosticResponse = (message) => {
        let payload = {
          "email": JSON.parse(sessionStorage.userDetails).user.email,
          "diagnosticViewed": {"time": [new Date().getTime()]}
        }
        AuthClient.saveUserActivities(payload,"diagnosticViewed").then((response) => {});
        message = JSON.parse(message);
        window.diagnosticResponseCheck = false;
        if(diagnosticInfoRequestTimer) {
            clearTimeout(diagnosticInfoRequestTimer);
        }
        let response = message.hasOwnProperty("value") ? JSON.parse(message.value) : "";
        if( response === "" || response === "ERR_SENDING_FILE" || response === "GATEWAY_REBOOTED"){
            setErrorText(response === "GATEWAY_REBOOTED" ? "Gateway went to reboot." : "Error occurred while sending zip file.")
            enqueueSnackbar("Error occurred at gateway...")
            setIsLoading(false);
            setShowErrorText(true);
            return;
        }
        let data = {};
        Object.keys(response).forEach((res)=>{
            if (res === "nwType"){
                data["Network Info"] = response[res];
            }else if(res === "firmware Version"){
                data["Gateway Firmware"] = response[res];
            }else if(res === "memory_info"){
                data["Memory Info"] = response[res];
            }else if(res === "OS Version"){
                data["Gateway OS"] = response[res];
            }
        })
        setDeviceData(data);
        enqueueSnackbar("Gateway responded");
        setTimeout(() => {
            setIsLoading(false);
        },1000)
        if(window.showDiagnosticInfoData){
            window.showDiagnosticInfoData.unsubscribe()
        }
    }

    const startTimer = (value) => {
        diagnosticInfoRequestTimer = setTimeout (() => {
              setErrorText("Request timed out...")
              enqueueSnackbar("Request timed out...")
              setIsLoading(false);
              if(uniqueKey !== null){
                  window.diagnosticResponseCheck = false;
                  updateStatusToFailed(props.diagnosticInfoData, "Diagnostic Data failed", uniqueKey)
              }
              if(window.showDiagnosticInfoData){
                  window.showDiagnosticInfoData.unsubscribe()
              }
              setShowErrorText(true);
              //props.closeDialog();
        }, value)
    }


    React.useEffect(() => {
        window.diagnosticResponseCheck = true;
        setTimeout(() => {
          setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
          setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
        }, 1000);
        setTimeout(() => {
            subScribeMqttChannel(props.diagnosticInfoData);
        },500)
        return () => {
            if(window.showDiagnosticInfoData){
                window.showDiagnosticInfoData.unsubscribe()
            }
            if(diagnosticInfoRequestTimer) {
                clearTimeout(diagnosticInfoRequestTimer);
            }
            if(window.diagnosticResponseCheck && uniqueKey !== null){
                deleteInstruction(uniqueKey);
            }
        }

    },[])

    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.openDialog}
                onClose={() => props.closeDialog()}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    background: primaryThemeColor,
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'white',
                }}>
                    <Box sx={{flex: '95'}}>Diagnostic Info</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{padding:"5px !important", display:"flex", justifyContent:"center", marginTop:"15px"}}>
                    {
                        showErrorText ?
                            <Box sx={{display: "flex", justifyContent: "center", width:"100%", margin:"10px 0px"}}>
                                <Box> <span sx={{color: "red"}}>{errorText}</span> &nbsp;Try again...</Box>
                            </Box>
                        :
                            null
                    }
                    {
                        !showErrorText ?
                            isLoading ?
                                <Box sx={{height:"40px", display:"flex", alignItems:"center"}}>
                                    <CircularProgress thickness="3.8" size="25px" > </CircularProgress>
                                </Box>
                            :
                                <Box sx={{display: "flex", flexDirection:"row",width:"100%"}}>
                                    <Box sx={{width: "50%"}}>
                                        {Object.keys(deviceData).map((key, index) => (
                                            checkDataExistsOrNot(deviceData[key]) ? (
                                                <Box sx={{padding:"4px",display: "flex", flexDirection:"row",justifyContent:"center",alignItems:"center",borderLeft:"1px solid grey", borderBottom:"1px solid grey",borderTop: index === 0 ? "1px solid grey" : "0px solid grey" }}>
                                                    {key}
                                                </Box>
                                            )
                                            :
                                                null
                                        ))}
                                    </Box>
                                    <Box sx={{width: "50%"}}>
                                        {Object.keys(deviceData).map((key, index) => (
                                            checkDataExistsOrNot(deviceData[key]) ? (
                                                <Box sx={{padding: "4px",display: "flex", flexDirection:"row",justifyContent:"center",alignItems:"center", borderRight:"1px solid grey", borderBottom:"1px solid grey",borderTop: index === 0 ? "1px solid grey" : "0px solid grey" }}>
                                                    {deviceData[key]}
                                                </Box>
                                            )
                                            :
                                                null
                                        ))}
                                    </Box>
                                </Box>
                        :
                            null
                    }
                </DialogContent>
                <DialogActions >
                    <>
                        <Button sx={{color:primaryThemeColor}} onClick={() => props.closeDialog()}>Cancel</Button>
                    </>
                </DialogActions>
            </Dialog>
        </>
    )
}
